/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';
import { BytePipe } from '../../../shared/pipes/byte.pipe';

@Pipe({ name: 'storagePoolSpaceRemaining' })
export class StoragePoolSpaceRemainingPipe implements PipeTransform {
  transform(value: number): string {
    // value of -1 means the space is exandable
    if (value === -1) {
      return 'Extendable';
    }
    // otherwise value is in KB, so convert to bytes then use the BytePipe to transform
    value *= 1024;
    return (new BytePipe()).transform(BigInt(value));
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IACValidators } from '../../../shared/forms/validators';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';

@Component({
  selector: 'app-create-space',
  templateUrl: 'create-space.html'
})
export class CreateSpaceComponent implements OnInit {

  @Input() server: InformixServer = null;
  @Input() hasStoragePoolEntries = false;

  formGroup: UntypedFormGroup = null;

  pageSizeOptions: string[] = ['Default', '4K', '8K', '12K', '16K'];
  typeOptions: any[] = [
    { label: 'Dbspace', type: 'dbspace' },
    { label: 'Temporary Dbspace', type: 'tempdbspace' },
    { label: 'Blobspace', type: 'blobspace' },
    { label: 'Sbspace', type: 'sbspace' }
  ];

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  ngOnInit() {
    const nameControl: UntypedFormControl = new UntypedFormControl(null, [Validators.required, IACValidators.informixIdentifierValidator]);
    const typeControl: UntypedFormControl = new UntypedFormControl('dbspace', [Validators.required]);
    const sizeControl: UntypedFormControl = new UntypedFormControl(10 * 1024, [Validators.required]);
    const pageSizeControl: UntypedFormControl = new UntypedFormControl('Default', [Validators.required]);
    const offsetControl: UntypedFormControl = new UntypedFormControl(0, [Validators.required]);
    const locationOptionControl: UntypedFormControl = new UntypedFormControl('path', [Validators.required]);
    const pathControl: UntypedFormControl = new UntypedFormControl(null, [Validators.required]);
    const expandableControl: UntypedFormControl = new UntypedFormControl('true', [Validators.required]);
    const createSizeControl: UntypedFormControl = new UntypedFormControl(10, [Validators.required]);
    const extendSizeControl: UntypedFormControl = new UntypedFormControl(10000, [Validators.required]);

    this.formGroup = new UntypedFormGroup({
      spaceName: nameControl,
      type: typeControl,
      size: sizeControl,
      pageSize: pageSizeControl,
      offset: offsetControl,
      locationOption: locationOptionControl,
      path: pathControl,
      expandable: expandableControl,
      createSize: createSizeControl,
      extendSize: extendSizeControl
    });

    typeControl.valueChanges.subscribe(value => {
      if (value === 'sbspace') {
        pageSizeControl.disable();
        extendSizeControl.disable();
      } else if (value === 'blobspace') {
        pageSizeControl.enable();
        extendSizeControl.disable();
      } else {
        pageSizeControl.enable();
        if (this.formGroup.value['expandable']) {
          extendSizeControl.enable();
        }
      }
    });

    locationOptionControl.valueChanges.subscribe(value => {
      if (value === 'path') {
        pathControl.enable();
        offsetControl.enable();
      } else {
        pathControl.disable();
        offsetControl.setValue(0);
        offsetControl.disable();
      }
    });

    expandableControl.valueChanges.subscribe(value => {
      if (value) {
        createSizeControl.enable();
        extendSizeControl.enable();
      } else {
        createSizeControl.disable();
        extendSizeControl.disable();
      }
    });
  }

  public doCreateSpace() {
    const values = this.formGroup.value;
    const type = values['type'];

    const expandable: boolean = values['expandable'];
    let createSize = values['createSize']?.toString();
    let extendSize = values['extendSize']?.toString();
    if (!expandable) {
      createSize = 0;
      extendSize = 0;
    } else if (type === 'sbspace' || type === 'blobspace') {
      // Extend size is not supported on sbspaces and blobspaces, must be set to zero
      extendSize = 0;
    }

    const createFromPool = values['locationOption'] === 'pool';
    const sizeString: string = values['size'].toString();
    let offsetString: string;
    if (createFromPool) {
      offsetString = '0';
    } else {
      offsetString = values['offset'].toString();
    }
    let pageSizeString = values['pageSize'];
    if (pageSizeString === 'Default') {
      pageSizeString = '';
    }

    this.informixServerStorageService.createSpace(this.server.id, values['spaceName'], type, values['path'], createFromPool,
      sizeString, pageSizeString, offsetString, createSize, extendSize)
      .then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        console.log(err);
        this.error.emit(err);
      });
    this.doClose();
  }

  public doClose() {
    this.resetForm();
    this.close.emit();
  }

  public resetForm() {
    const controls = this.formGroup.controls;
    controls['spaceName'].setValue(null);
    controls['path'].setValue(null);
  }

}

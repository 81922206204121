<div *ngIf="selectedConstraints.length > 0" class="d-flex justify-content-end align-items-center">
  <span class="font-weight-bold color-light-gray-70 pr-2">{{selectedConstraints.length}} of
    {{tableDetails.constraints.length}} constraint(s) selected</span>
  <app-check-permissions [permissions]="server.permissions.admin">
    <button class="btn btn-sm btn-icon" (click)="removeMultipleConstraints()" tooltip="Drop" appPermissionDisable><i
        class="icon icon-trash"></i></button>
  </app-check-permissions>
</div>

<app-data-table [data]="tableDetails.constraints" sortBy="name"
  [searchLabel]="'schemaManager.tableInfo.table.searchLabel.constraints' | translate" [enableSelect]="true"
  (updateSelectedRows)="getSelectedConstraints($event)">
  <app-data-table-column [title]="'common.name' | translate" property="name" [sortable]="true" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'common.type' | translate" property="displayName" [sortable]="true"
    [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'table.columns' | translate" property="columnNames" [searchable]="true">
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.locale' | translate" property="collation"
    [sortable]="true" [searchable]="true"></app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.enabled' | translate" property="enabled">
    <ng-template appTableCell let-enabled="item">
      <app-status-icon [status]="enabled"></app-status-icon>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.filtering' | translate"
    property="filtering">
    <ng-template appTableCell let-filtering="item">
      <app-status-icon [status]="filtering"></app-status-icon>
    </ng-template>
  </app-data-table-column>
  <app-data-table-column [title]="'schemaManager.tableInfo.table.columnTitle.withErrors' | translate"
    property="filterErrorsEnabled">
    <ng-template appTableCell let-filterErrorsEnabled="item">
      <app-status-icon [status]="filterErrorsEnabled"></app-status-icon>
    </ng-template>
  </app-data-table-column>
</app-data-table>

/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { InformixTable, InformixTableDetails } from '../informix-table';
import { SchemaService } from '../schema.service';
import { HttpErrorResponse } from '@angular/common/http';
import { InformixSQLSession } from '../informix-sql-session';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-table-info-page',
  templateUrl: './table-info-page.component.html',
  styleUrls: ['../schema.scss', './table-info-page.component.scss']
})
export class TableInfoPageComponent implements OnChanges, OnInit {

  @Input() table: InformixTable;
  @Input() session: InformixSQLSession;
  @Output() openCreateIndex = new EventEmitter<any>();
  @Input() server: InformixSQLSession;

  tableDetails: InformixTableDetails = null;
  tableDetailsLoadError: string = null;
  tabList: string[];
  selectedTab: string;
  tabAuthList = [];

  constructor(
    private schemaService: SchemaService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.translate.stream('schemaManager.tableInfo.labels').subscribe((messages) => {
      this.tabList = Object.values(messages);
    });
    this.selectedTab = this.tabList[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.table && this.table) {
      this.loadTableDetails();
    }
  }

  private loadTableDetails() {
    this.tableDetails = null;
    this.tabAuthList = [];
    this.tableDetailsLoadError = null;
    this.schemaService.getTableDetails(this.table).subscribe(tableDetails => {
      this.tableDetails = tableDetails;
      if (this.tableDetails.privileges) {
        for (let i = 0; i < (this.tableDetails.privileges).length; i++) {
          this.tableDetails.privileges[i].tabauthlist = (this.tableDetails.privileges[i].tabauth
            .replace(/-/g, '').toUpperCase()).split('');
          const index = this.tableDetails.privileges[i].tabauthlist.indexOf('*');
          if (this.tableDetails.privileges[i].tabauthlist.indexOf('*') !== -1) {
            this.tableDetails.privileges[i].tabauthlist[index] = 'C';
          }
        }
      }
    }, err => {
      let unknownError: string;
      this.translate.get('schemaManager.tableInfo.errorLoadingTableDetails')
        .subscribe((text: string) => {
          this.tableDetailsLoadError = text;
        });
      this.translate.get('schemaManager.tableInfo.unknownError')
        .subscribe((text: string) => {
          unknownError = text;
        });
      if (err instanceof HttpErrorResponse && err.error.err) {
        this.tableDetailsLoadError += err.error.err;
      } else {
        this.tableDetailsLoadError += unknownError;
      }
    });
  }
}

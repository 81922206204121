/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { alertOperandComponents } from './operations/alert-operand-types';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';
import { AlertConfigComponent } from './alert-config.component';
import { AlertOperandComponent } from './alert-operand.component';
import { AlertOperationComponent } from './alert-operation.component';
import { AlertingProfileComponent } from './alerting-profile.component';
import { AlertingService } from './alerting.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        TooltipModule,
        NgSelectModule
    ],
    providers: [
        AlertingService
    ],
    declarations: [
        AlertingProfileComponent,
        AlertConfigComponent,
        AlertOperationComponent,
        AlertOperandComponent,
        ...alertOperandComponents
    ],
    exports: [
        AlertingProfileComponent
    ]
})
export class AlertingModule { }

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import { Subscription } from 'rxjs';

import { ChartJSUtils } from '../../../shared/chartjs.utils';
import { BytePipe } from '../../../shared/pipes/byte.pipe';
import { AbstractDashboardComponent } from '../../monitoring/abstract-dashboard-component';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-storage-dashboard-summary-usage',
  templateUrl: 'storage-dashboard-summary-usage.html'
})
export class StorageDashboardSummaryUsageComponent extends AbstractDashboardComponent implements OnInit, OnDestroy {

  @Input() server: InformixServer = null;

  private storageSummaryInfo: any;

  private storageSummarySub: Subscription;

  dataLoading = true;
  dataLoadErrorMessage: string = null;

  private chartUsedSpaceData: number[];
  private chartFreeSpaceData: number[];
  private chartTotalSpaceData: number[];
  private chart: Chart;

  @ViewChild('storageSummaryBarChart') storageSummaryBarChartElement: ElementRef;

  constructor(private storageService: InformixServerStorageService) {
    super();
  }

  ngOnInit() {
    if (this.server.hasMonitorPassword) {
      this.refreshData();
    }
  }

  ngOnDestroy() {
    if (this.storageSummarySub) {
      this.storageSummarySub.unsubscribe();
    }
  }

  private refreshData() {
    this.getStorageSummaryInfo();
  }

  private getStorageSummaryInfo() {
    this.storageSummarySub = this.storageService.getStorageSummaryInfo(this.server.id).subscribe(summaryInfo => {
      this.dataLoading = false;
      this.dataLoadErrorMessage = null;
      this.storageSummaryInfo = summaryInfo;
      this.chartUsedSpaceData = [
        this.storageSummaryInfo.dbspace_used,
        this.storageSummaryInfo.blobspace_used,
        this.storageSummaryInfo.tempspace_used
      ];
      this.chartFreeSpaceData = [
        this.storageSummaryInfo.dbspace_free,
        this.storageSummaryInfo.blobspace_free,
        this.storageSummaryInfo.tempspace_free
      ];
      this.chartTotalSpaceData = [this.storageSummaryInfo.dbspace_used + this.storageSummaryInfo.dbspace_free,
      this.storageSummaryInfo.blobspace_used + this.storageSummaryInfo.blobspace_free,
      this.storageSummaryInfo.tempspace_used + this.storageSummaryInfo.tempspace_free];
      this.updateStorageSummaryBarChart();
    }, (err: HttpErrorResponse) => {
      this.dataLoading = false;
      console.error('Could not get server storage summary info', err);
      this.dataLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  private updateStorageSummaryBarChart() {
    if (this.chart) {
      this.chart.update();
      return;
    }

    const bytePipe = new BytePipe();

    this.chart = new Chart(this.storageSummaryBarChartElement.nativeElement, {
      type: 'horizontalBar',
      data: {
        labels: ['Dbspaces', 'Blob spaces', 'Temp spaces'],
        datasets: [
          {
            label: 'Used',
            data: this.chartUsedSpaceData,
            backgroundColor: ChartJSUtils.getDefaultColor(0)
          },
          {
            label: 'Free',
            data: this.chartFreeSpaceData,
            backgroundColor: '#F6F6F6'
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              beforeBuildTicks: ChartJSUtils.byteAxisBeforeBuildTicks,
              stacked: true,
              ticks: {
                maxRotation: 0,
                callback: (value: any) => bytePipe.transform(value),
                padding: 10
              }
            }
          ],
          yAxes: [
            {
              barThickness: 35,
              gridLines: { display: true, drawOnChartArea: false, drawTicks: false },
              stacked: true,
              ticks: {
                padding: 10
              }
            }
          ]
        },
        legend: {
          display: false,
          position: 'top'
        },
        tooltips: {
          mode: 'nearest',
          callbacks: {
            title: (tooltips: any, data: any) => {
              const tooltip = tooltips[0];
              return data.labels[tooltip.index] + ' ' + data.datasets[tooltip.datasetIndex].label;
            },
            label: (tooltip: any, data: any) => {
              const value: number = data.datasets[tooltip.datasetIndex].data[tooltip.index];
              return bytePipe.transform(BigInt(value)) + ' (' + ((value / this.chartTotalSpaceData[tooltip.index]) * 100).toFixed(2) + '%)';
            }
          }
        }
      }
    });
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { BreadcrumbElement } from '../../../shared/breadcrumb.component';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { InformixServer } from '../informixServer';
import { ServerBreadcrumb } from '../serverBreadcrumb';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerChunk } from './serverChunk';
import { ServerSpace } from './serverSpace';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { HDRPermission } from '../../../shared/hdr-permission/hdr-permission';
import { HDRPermissionService } from '../../../shared/hdr-permission/hdr-permission.service';

@Component({
  selector: 'app-spaces-list',
  templateUrl: 'spaces-list.html',
  styleUrls: ['spaces-list.scss']
})
export class SpacesListComponent implements OnInit, OnDestroy {
  @Input() maximized = true;

  @ViewChild('storageModal') storageModal: ModalDirective;

  dataLoading = false;
  dataLoadErrorMessage: string = null;
  spacesLoadErrorMessage: string = null;
  chunksLoadErrorMessage: string = null;

  server: InformixServer = null;
  spaces: ServerSpace[] = null;
  chunks: ServerChunk[] = null;
  viewAs = 'spaces';

  action = '';
  title = '';
  selectedSpace: ServerSpace = null;
  selectedChunk: ServerChunk = null;
  hasStoragePoolEntries = false;

  storageBreadCrumb: BreadcrumbElement = { name: 'Storage', link: 'storage' };
  spacesBreadCrumb: BreadcrumbElement = { name: 'Spaces', link: () => this.closeAction() };

  breadcrumb: BreadcrumbElement[] = null;

  private spacesRefreshSub: Subscription;
  private chunksRefreshSub: Subscription;
  private storagePoolRequestSub: Subscription;

  // TODO: Make this configurable
  SPACE_USAGE_RED_THRESHOLD = 90;
  spacesHDR: HDRPermission;
  constructor(
    private storageService: InformixServerStorageService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private hdrPermissionService: HDRPermissionService) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.loadServer(data.server);
    });
    this.spacesHDR = this.hdrPermissionService.getByPermissionId('p1');
  }

  ngOnDestroy() {
    if (this.spacesRefreshSub) {
      this.spacesRefreshSub.unsubscribe();
    }

    if (this.chunksRefreshSub) {
      this.chunksRefreshSub.unsubscribe();
    }
    if (this.storagePoolRequestSub) {
      this.storagePoolRequestSub.unsubscribe();
    }
  }

  private loadServer(server: InformixServer) {
    this.server = server;
    this.setBreadcrumb();
    if (this.server.hasMonitorPassword) {
      this.dataLoading = true;
      this.getStoragePoolInfo();
    }
  }

  private setBreadcrumb(action?: string) {
    if (this.maximized) {
      const breadcrumbs: BreadcrumbElement[] = [this.storageBreadCrumb, this.spacesBreadCrumb];
      if (action) {
        const actionBreadCrumb: BreadcrumbElement = { name: action, link: 'storage/spaces' };
        breadcrumbs.push(actionBreadCrumb);
      }
      this.breadcrumb = ServerBreadcrumb.build(this.server, breadcrumbs);
    }
  }

  private getStoragePoolInfo() {
    this.storagePoolRequestSub = this.storageService.getStoragePoolInfo(this.server.id, 'active').subscribe(entries => {
      this.hasStoragePoolEntries = (entries.length > 0);
      this.refreshData();
    }, (err: HttpErrorResponse) => {
      this.dataLoading = false;
      if (err.status === 403) {
        console.log('You do not have permission to see storage pool information', err);
        this.hasStoragePoolEntries = false;
        this.refreshData();
      } else {
        console.error('Error getting server storage info', err);
        this.spacesLoadErrorMessage = err.error ? err.error.err : err;
      }
    });
  }

  refreshData() {
    this.chunksLoadErrorMessage = this.spacesLoadErrorMessage = null;
    if (this.viewAs === 'spaces') {
      this.getSpaces();
    }else {
      this.getChunks();
    }
  }

  getSpaces(): void {
    if (this.spacesRefreshSub) {
      this.spacesRefreshSub.unsubscribe();
    }
    this.dataLoading = true;
    this.spaces = null;
    this.spacesRefreshSub = this.storageService.getServerSpaces(this.server.id).subscribe(spaces => {
      this.spacesLoadErrorMessage = null;
      this.spaces = spaces;
      this.dataLoading = false;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      this.dataLoading = false;
      this.spacesLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  getChunks(): void {
    if (this.chunksRefreshSub) {
      this.chunksRefreshSub.unsubscribe();
    }
    this.dataLoading = true;
    this.chunks = null;
    this.chunksRefreshSub = this.storageService.getServerChunks(this.server.id).subscribe(chunks => {
      this.chunksLoadErrorMessage = null;
      this.chunks = chunks;
      this.dataLoading = false;
    }, (err: HttpErrorResponse) => {
      console.error(err);
      this.dataLoading = false;
      this.chunksLoadErrorMessage = err.error ? err.error.err : err;
    });
  }

  isChunkExtendable(chunk: ServerChunk) {
    // blobspaces, sbspaces, and mirrored spaces cannot be extended
    return !(chunk.type === 'mirrored dbspaces' ||
      chunk.type === 'blobspace' ||
      chunk.type === 'mirrored blobspace' ||
      chunk.type === 'sbspace' ||
      chunk.type === 'mirrored sbspace' ||
      chunk.type === 'temporary sbspace');
  }

  public getBreadCrumbLink(relativePath: string = '') {
    return '/dashboard/servers/' + this.server.id + relativePath;
  }

  public setViewAs(value: string) {
    this.viewAs = value;
    this.refreshData();
  }

  createSpaceClicked() {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.action = 'createSpace';
    this.setBreadcrumb('Create Space');
  }

  expandSpaceClicked(space: ServerSpace) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'expandSpace';
    this.title = 'Expand Space';
    this.selectedSpace = space;
    this.setBreadcrumb('Expand Space');
  }

  modifySpaceClicked(space: ServerSpace) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'modifySpace';
    this.title = 'Modify Space';
    this.selectedSpace = space;
    this.setBreadcrumb('Modify Space');
  }

  dropSpaceClicked(space: ServerSpace) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'dropSpace';
    this.title = 'Drop Space';
    this.selectedSpace = space;
    this.setBreadcrumb('Drop Space');
  }

  addChunkClicked(space: ServerSpace) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    if (space) {
      this.storageModal.show();
    }
    this.action = 'addChunk';
    this.title = 'Add Chunk';
    this.selectedSpace = space;
    this.setBreadcrumb('Add Chunk');
  }

  extendChunkClicked(chunk: ServerChunk) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'extendChunk';
    this.title = 'Extend Chunk';
    this.selectedChunk = chunk;
    this.setBreadcrumb('Extend Chunk');
  }

  modifyChunkClicked(chunk: ServerChunk) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'modifyChunk';
    this.title = 'Modify Chunk';
    this.selectedChunk = chunk;
    this.setBreadcrumb('Modify Chunk');
  }

  dropChunkClicked(chunk: ServerChunk) {
    if (this.spacesHDR && !this.spacesHDR.isAllow()) {
      return;
    }
    this.storageModal.show();
    this.action = 'dropChunk';
    this.title = 'Drop Chunk';
    this.selectedChunk = chunk;
    this.setBreadcrumb('Drop Chunk');
  }

  handleActionSuccess(message: string) {
    this.notificationsService.pushSuccessNotification(message);
    this.refreshData();
  }

  handleActionError(err: any) {
    let message = err;
    if (typeof err !== 'string') {
      message = err.error ? err.error.err : err;
    }
    this.notificationsService.pushErrorNotification(message);
  }

  closeAction() {
    if (this.storageModal.show) {
      this.storageModal.hide();
    }
    this.action = '';
    this.selectedChunk = null;
    this.selectedSpace = null;
    this.setBreadcrumb();
  }

  pathCopied(): void {
    this.notificationsService.pushSuccessNotification('Chunk path copied successfully!');
  }
}

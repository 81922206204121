/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, HostListener, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { ConfirmationDialogService } from '../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServer } from '../servers/informixServer';
import { InformixServerService } from '../servers/informixServer.service';
import { GroupCardComponent } from './group-card.component';
import { GroupBreadcrumb } from './groupBreadcrumb';
import { InformixServerGroup } from './informixServerGroup';
import { InformixServerGroupService } from './informixServerGroup.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-group-component',
  templateUrl: './group.html',
  styleUrls: ['group.scss']
})
export class GroupComponent implements OnInit, OnDestroy {

  serverType = environment.serverType;
  group: InformixServerGroup = null;
  newGroup: InformixServerGroup = null;
  breadcrumb: BreadcrumbElement[] = null;

  private pollTimeoutHandle: number;
  private pollInterval = 10000;

  selectedGroups = new Map<number, InformixServerGroup>();
  lastSelectedGroup: InformixServerGroup = null;

  selectedServers = new Map<number, InformixServer>();
  lastSelectedServer: InformixServer = null;

  itemsBeingMoved: InformixTreeItem[] = null;
  isGroupExpanded = true;
  isServerExpanded = true;
  searchGroupControl: UntypedFormControl;
  searchedGroupData: any[] = [];
  searchServerControl: UntypedFormControl;
  searchedServerData: any[] = [];
  windowScrolled: boolean;
  naServerFound: Boolean | InformixServer = false;
  serversRefreshing: number[] = [];
  @ViewChildren(GroupCardComponent) groupCards: QueryList<GroupCardComponent>;

  constructor(
    private groupService: InformixServerGroupService,
    private serverService: InformixServerService,
    private notificationsService: NotificationsService,
    private confirmationDialogService: ConfirmationDialogService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.searchGroupControl = new UntypedFormControl('');
    this.searchGroupControl.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.isGroupExpanded = true;
      this.applySearch();
    });
    this.searchServerControl = new UntypedFormControl('');
    this.searchServerControl.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.isServerExpanded = true;
      this.applyServerSearch();
    });

    this.route.data.subscribe(data => {
      if (this.isAnythingSelected()) {
        this.clearSelection();
      }
      this.newGroup = null;
      this.loadGroup(data.group);
    });
  }

  private applySearch() {
    let searchString = (this.searchGroupControl.value as string).trim();
    if (searchString.length < 1) {
      this.searchedGroupData = this.group.groups.filter(item => item.permissions.read);
    } else {
      searchString = searchString.toLowerCase();
      this.searchedGroupData = this.searchedGroupData.filter(row => {
        const value = row.name;
        if (typeof value === 'string' && value.toLowerCase().indexOf(searchString) > -1) {
          return true;
        }
        return false;
      });
    }
  }

  private applyServerSearch() {
    let searchString = (this.searchServerControl.value as string).trim();
    if (searchString.length < 1) {
      this.searchedServerData = this.group.servers.filter(item => item.permissions.read);
    } else {
      searchString = searchString.toLowerCase();
      this.searchedServerData = this.searchedServerData.filter(row => {
        const value = row.alias;
        if (typeof value === 'string' && value.toLowerCase().indexOf(searchString) > -1) {
          return true;
        }
        return false;
      });
    }
  }

  private loadGroup(group: InformixServerGroup) {
    this.group = group;
    this.breadcrumb = GroupBreadcrumb.build(this.group);
    this.sortGroups();
    this.sortServers();

    this.searchedGroupData = this.group.groups.filter(item => item.permissions.read);
    this.searchedServerData = this.group.servers.filter(item => item.permissions.read);

    // To show note only when atleast one server is with N/A type.
    this.naServerFound = this.group.servers.find(server => !server.serverType);

    if (this.searchGroupControl.value.length > 0) {
      this.applySearch();
    }

    if (this.searchServerControl.value.length > 0) {
      this.applyServerSearch();
    }

    if (this.selectedGroups.size > 0) {
      const oldMap = this.selectedGroups;
      this.selectedGroups = new Map<number, InformixServerGroup>();
      this.group.groups.forEach(childGroup => {
        if (oldMap.has(childGroup.id)) {
          this.setGroupSelected(childGroup, true);
        }
      });
    }

    if (this.selectedServers.size > 0) {
      const oldMap = this.selectedServers;
      this.selectedServers = new Map<number, InformixServer>();
      this.group.servers.forEach(server => {
        if (oldMap.has(server.id)) {
          this.setServerSelected(server, true);
        }
      });
    }

    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }

    this.pollTimeoutHandle = window.setTimeout(() => this.getGroupInfo(), this.pollInterval);
  }

  getGroupInfo() {
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }

    this.groupService.getGroup(this.group.id).then(group => {
      this.loadGroup(group);
    }).catch(err => {
      console.error('Could not get server group', err);
    });
  }

  ngOnDestroy() {
    if (this.pollTimeoutHandle) {
      window.clearTimeout(this.pollTimeoutHandle);
    }
  }

  groupListTrackBy(index: number, group: InformixServerGroup) {
    return group.id;
  }

  serverListTrackBy(index: number, server: InformixServer) {
    return server.id;
  }

  private sortGroups() {
    if (!this.group || !this.group.groups) {
      return;
    }
    this.group.groups.sort(this.sortGroupTreeItem);
  }

  private sortServers() {
    if (!this.group || !this.group.servers) {
      return;
    }
    this.group.servers.sort(this.sortServerTreeItem);
  }

  private sortGroupTreeItem(a: InformixServerGroup, b: InformixServerGroup) {
    if (a.aggregate.unreadIncidents === b.aggregate.unreadIncidents) {
      if (a.permissions.read === b.permissions.read) {
        return a.name.localeCompare(b.name);
      } else {
        return a.permissions.read ? -1 : 1;
      }
    } else {
      return b.aggregate.unreadIncidents - a.aggregate.unreadIncidents;
    }
  }

  private sortServerTreeItem(a: InformixServer, b: InformixServer) {
    if (a.permissions.read !== b.permissions.read) {
      return a.permissions.read ? -1 : 1;
    }

    if (a.unreadIncidents !== b.unreadIncidents) {
      return b.unreadIncidents - a.unreadIncidents;
    }

    return a.name.localeCompare(b.name);
  }

  public navigateToGroup(group: InformixServerGroup) {
    if (group.permissions.read) {
      this.router.navigate(['/dashboard/groups/', group.id]);
    }
  }

  public navigateToServer(server: InformixServer) {
    if (server.permissions.read) {
      this.router.navigate(['/dashboard/servers/', server.id]);
    }
  }

  isGroupSelected(group: InformixServerGroup) {
    return this.selectedGroups.has(group.id);
  }

  setGroupSelected(group: InformixServerGroup, selected: boolean) {
    if (!group.permissions.read) {
      return;
    }

    if (selected) {
      this.selectedGroups.set(group.id, group);
    } else {
      this.selectedGroups.delete(group.id);
    }
  }

  isServerSelected(server: InformixServer) {
    return this.selectedServers.has(server.id);
  }

  setServerSelected(server: InformixServer, selected: boolean) {
    if (!server.permissions.read) {
      return;
    }

    if (selected) {
      this.selectedServers.set(server.id, server);
    } else {
      this.selectedServers.delete(server.id);
    }
  }

  addNewGroup() {
    this.isGroupExpanded = true;
    if (!this.newGroup) {
      this.newGroup = new InformixServerGroup(this.group, 'New Group');
      this.onEditGroup(this.newGroup);
    }
  }

  onNewGroupSaved() {
    this.group.groups.push(this.newGroup);
    this.sortGroups();
    this.newGroup = null;
  }

  onNewGroupDelete() {
    this.newGroup = null;
  }

  addNewServer() {
    this.router.navigate(['add-server'], { relativeTo: this.route });
  }

  onEditGroup(group: InformixServerGroup) {
    this.groupCards.forEach(card => {
      if (card.group !== group && card.isEditing) {
        card.closeEdit();
      }
    });
  }

  onSelectGroup(isMulti: boolean, group: InformixServerGroup, index: number) {
    if (isMulti && this.selectedGroups.size > 0 && group.id !== this.lastSelectedGroup.id && this.isGroupSelected(this.lastSelectedGroup)) {
      const prevIndex = this.group.groups.findIndex(childGroup => childGroup.id === this.lastSelectedGroup.id);
      if (prevIndex > -1) {
        const direction = prevIndex < index ? 1 : -1;
        for (let i = prevIndex; i !== index + direction; i += direction) {
          this.setGroupSelected(this.group.groups[i], true);
        }
      }
    } else {
      this.lastSelectedGroup = group;
      this.setGroupSelected(group, !this.isGroupSelected(group));
    }
  }

  onDeleteGroup(group: InformixServerGroup) {
    const index = this.group.groups.indexOf(group);
    if (index > -1) {
      this.group.groups.splice(index, 1);
    }
  }

  onMoveGroup(group: InformixServerGroup) {
    this.openMoveToWindow([group]);
  }

  onGroupSaved() {
    this.sortGroups();
  }

  editServer(server: InformixServer) {
    this.router.navigate(['dashboard', 'servers', server.id, 'setup']);
  }

  deleteServer(server: InformixServer) {
    this.confirmationDialogService.show('delete the server ' + server.alias + '?', () => this.onDeleteServerConfirmed(server));
  }

  onDeleteAll() {
    const items = this.getAllSelectedItems();
    if (items.length > 0) {
      this.confirmationDialogService.show(
        'delete ' + items.length + ' ' + (items.length > 1 ? 'items' : 'item'), () => this.onDeleteAllConfirmed(items));
    }
  }

  onDeleteServerConfirmed(server: InformixServer) {
    return this.serverService.deleteServer(server.id).then(() => {
      const index = this.group.servers.indexOf(server);
      if (index > -1) {
        this.group.servers.splice(index, 1);
      }
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
      console.error('Unable to delete server', err);
    });
  }

  onDeleteGroupConfirmed(groupToDelete: InformixServerGroup) {
    return this.groupService.deleteGroup(groupToDelete.id).then(() => {
      const index = this.group.groups.indexOf(groupToDelete);
      if (index > -1) {
        this.group.groups.splice(index, 1);
      }
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
      console.error('Cannot delete group', err);
    });
  }

  onDeleteAllConfirmed(items: InformixTreeItem[]) {
    const deleteActions: any[] = [];
    for (let i = 0; i < items.length; i++) {
      const obj = items[i];
      if (obj instanceof InformixServer) {
        deleteActions.push(() => this.onDeleteServerConfirmed(obj as InformixServer));
      } else if (obj instanceof InformixServerGroup) {
        deleteActions.push(() => this.onDeleteGroupConfirmed(obj as InformixServerGroup));
      }
    }
    deleteActions.reduce((prevPromise, currentAction) => prevPromise.then(currentAction), Promise.resolve());
  }

  onMoveServer(server: InformixServer) {
    this.openMoveToWindow([server]);
  }

  onServerSelect(isMulti: boolean, server: InformixServer, index: number) {
    if (isMulti && this.selectedServers.size > 0 && server.id !== this.lastSelectedServer.id
      && this.isServerSelected(this.lastSelectedServer)) {
      const prevIndex = this.group.servers.findIndex(childServer => childServer.id === this.lastSelectedServer.id);
      if (prevIndex > -1) {
        const direction = prevIndex < index ? 1 : -1;
        for (let i = prevIndex; i !== index + direction; i += direction) {
          this.setServerSelected(this.group.servers[i], true);
        }
      }
    } else {
      this.lastSelectedServer = server;
      this.setServerSelected(server, !this.isServerSelected(server));
    }
  }

  isAnythingSelected() {
    return this.selectedGroups.size + this.selectedServers.size > 0;
  }

  clearSelection() {
    this.selectedGroups.clear();
    this.selectedServers.clear();
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.isAnythingSelected() && !this.itemsBeingMoved && event.target instanceof Element
      && !event.target.closest('.card') && !event.target.closest('.bottom-fixed-bar.active')) {
      this.clearSelection();
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop > 1000 || document.body.scrollTop > 1000) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  scrollToTheTop() {
    (function smoothscroll() {
      const currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - (currentScroll / 8));
      }
    })();
  }

  private getAllSelectedItems(): InformixTreeItem[] {
    return (Array.from(this.selectedGroups.values()) as InformixTreeItem[]).concat(Array.from(this.selectedServers.values()));
  }

  onMoveAll() {
    const items = this.getAllSelectedItems();
    if (items.length > 0) {
      this.openMoveToWindow(items);
    }
  }

  openMoveToWindow(items: InformixTreeItem[]) {
    this.itemsBeingMoved = items;
  }

  onMoveToClosed() {
    this.itemsBeingMoved = null;
  }

  onMoveToDone(groupId: number) {
    if (this.group.id === groupId) {
      this.getGroupInfo();
    }
  }

  // Refresh all selected servers
  refreshAll() {
    const items = this.getAllSelectedItems();
    if (items.length) {
      this.doRefresh(items.map(item => item.id));
      this.clearSelection();
    }
  }

  // Refresh the server from the server card
  refreshServer(server: InformixServer) {
    this.doRefresh([server.id]);
  }


  /**
   * Refreshes the specified servers. Removes the servers that are already refreshing.
   * Removes the servers from serversRefreshing array once the refresh is completed.
   * Shows error notification if any error occurs while refreshing the server.
   *
   * @param servers - An array of server IDs to be refreshed.
   */
  doRefresh(servers: number[]) {
    const serversToBeRefreshed = this.filterAlreadyRefreshingServers(servers);

    // If all servers are already refreshing, return
    if(serversToBeRefreshed.length === 0) {
      return;
    }

    this.serversRefreshing = [...this.serversRefreshing, ...serversToBeRefreshed];
    this.serverService.refreshServers(servers).then((serversResponse) => {
      if(serversResponse && serversResponse.length > 0) {
        serversResponse.forEach(server => {
          const index = this.serversRefreshing.indexOf(server.id);
          if(index > -1) {
            this.serversRefreshing.splice(index, 1);
          }

          // Replace ServerType object from searchedServerData array with the updated server object
          const serverIndex = this.searchedServerData.findIndex(_server => _server.id === server.id);
          if(serverIndex > -1) {
            this.searchedServerData[serverIndex] = {...this.searchedServerData[serverIndex], ...server};

            if(server.error) {
              this.notificationsService.pushErrorNotification('Error refreshing ' + this.searchedServerData[serverIndex]?.alias
              + ' server : ' + server.error);
            }
          }

        });
      }
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err);
    });
  }

  // Filter the servers that are already refreshing
  filterAlreadyRefreshingServers(servers: number[]) {
    return servers.filter(server => this.serversRefreshing.indexOf(server) === -1);
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerSpace } from './serverSpace';

@Component({
  selector: 'app-add-chunk',
  templateUrl: 'add-chunk.html'
})
export class AddChunkComponent implements OnInit {

  @Input() server: InformixServer = null;
  @Input() hasStoragePoolEntries = false;

  _spacesList: ServerSpace[] = null;
  _selectedSpace: ServerSpace = null;

  formGroup: UntypedFormGroup;

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      spaceSelect: new UntypedFormControl(null, [Validators.required]),
      locationOption: new UntypedFormControl('path', [Validators.required]),
      path: new UntypedFormControl(null, [Validators.required]),
      size: new UntypedFormControl(10 * 1024, [Validators.required]),
      offset: new UntypedFormControl(0, [Validators.required])
    });

    this.formGroup.controls['locationOption'].valueChanges.subscribe(value => {
      if (value === 'path') {
        this.formGroup.controls['path'].enable();
        this.formGroup.controls['offset'].enable();
      } else {
        this.formGroup.controls['path'].disable();
        this.formGroup.controls['offset'].setValue(0);
        this.formGroup.controls['offset'].disable();
      }
    });
  }

  @Input()
  set selectedSpace(selectedSpace: ServerSpace) {
    this._selectedSpace = selectedSpace;
    if (this.formGroup) {
      this.formGroup.controls['spaceSelect'].setValue(selectedSpace);
      this.resetForm();
    }
  }

  @Input()
  set spacesList(spaces: ServerSpace[]) {
    this._spacesList = spaces;
    this.resetForm();
  }

  public resetForm(): void {
    if (this._spacesList !== null) {
      this._spacesList = this._spacesList.sort((a, b) =>
        (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 :
          (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : 0);
    } else {
      this._spacesList = null;
    }
    if (this.formGroup) {
      if (this._selectedSpace == null && this._spacesList !== null && this._spacesList.length > 0) {
        this.formGroup.controls['spaceSelect'].setValue(this._spacesList[0]);
      }
      this.formGroup.controls['path'].setValue('');
    }
  }

  public doAddChunk() {
    const values = this.formGroup.value;
    this.informixServerStorageService.createChunk(this.server.id,
      values['spaceSelect'].name, values['locationOption'] === 'pool', values['path'],
      values['size']?.toString(), values['offset']?.toString()).then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  public doClose() {
    this.close.emit();
    this.resetForm();
  }

}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { ServerTypes } from '../../shared/hdr-permission/hdr-permission.model';
import { CacheableResource } from '../../shared/resourceCache';
import { InformixTreeItem } from '../informixTreeItem';
import { InformixServerAgent } from './informixServerAgent';


export enum InformixServerStatusCode {
  ONLINE = 5, SINGLE_USER = 7, RECOVERY = 2, BACKUP = 3,
  UNKNOWN = -999, OFFLINE = -1, ABORT = 6, SHUTDOWN = 4,
  INITIALIZATION = 0, QUIESCENT = 1
}

/*
 * Mapping Informix server status description to status code as API only returns status code
 */
export const informixServerStatusListMap = new Map([
  [InformixServerStatusCode.ONLINE, 'success'],
  [InformixServerStatusCode.SINGLE_USER, 'warning'],
  [InformixServerStatusCode.RECOVERY, 'danger'],
  [InformixServerStatusCode.BACKUP, 'warning'],
  [InformixServerStatusCode.OFFLINE, 'danger'],
  [InformixServerStatusCode.INITIALIZATION, 'warning'],
  [InformixServerStatusCode.QUIESCENT, 'warning'],
  [InformixServerStatusCode.ABORT, 'danger'],
  [InformixServerStatusCode.UNKNOWN, 'warning'],
  [InformixServerStatusCode.SHUTDOWN, 'danger']
]);


/*
 * Mapping Informix server status description to status code as API only returns status code
 */
export const InformixServerStatusMap = new Map([
    [InformixServerStatusCode.ONLINE, 'Online'],
    [InformixServerStatusCode.SINGLE_USER, 'Single User'],
    [InformixServerStatusCode.RECOVERY, 'Recovery'],
    [InformixServerStatusCode.BACKUP, 'Backup'],
    [InformixServerStatusCode.OFFLINE, 'Offline'],
    [InformixServerStatusCode.INITIALIZATION, 'Initialization'],
    [InformixServerStatusCode.QUIESCENT, 'Quiescent'],
    [InformixServerStatusCode.ABORT, 'Abort'],
    [InformixServerStatusCode.UNKNOWN, 'Unknown'],
    [InformixServerStatusCode.SHUTDOWN, 'Shutdown']
  ]);

export enum InformixServerStateCode {
  BLOCK_FOR_CKPT, BLOCK_LONGTX, BLOCK_ARCHIVE, BLOCK_RESTORE_POINT, BLOCK_MEDIA_FAILURE, BLOCK_HANG_SYSTEM, BLOCK_DBS_DROP,
  BLOCK_DDR, BLOCK_LBU_PRESERVE, BLOCK_LOG_FOR_ER, BLOCK_LOG, BLOCK_LOG_DROP, BLOCK_FREELOG, SYS_WAIT4OVRIDE, BLOCK_ARC_EBR,
  BLOCK_CHG_PLOG, BLOCK_DR_SWITCH, BLOCK_HA_SWITCH, UNKNOWN = -999
}


/*
 * Mapping Informix server state description to state code as API only returns state code
 */
export const InformixServerStateMap = new Map([
  [InformixServerStateCode.BLOCK_FOR_CKPT, 'CKPT'],
	[InformixServerStateCode.BLOCK_LONGTX, 'LONGTX'],
	[InformixServerStateCode.BLOCK_ARCHIVE, 'ARCHIVE'],
	[InformixServerStateCode.BLOCK_RESTORE_POINT, 'RESTORE POINT'],
	[InformixServerStateCode.BLOCK_MEDIA_FAILURE, 'MEDIA_FALURE'],
	[InformixServerStateCode.BLOCK_HANG_SYSTEM, 'HANG_SYSTEM'],
	[InformixServerStateCode.BLOCK_DBS_DROP, 'DBS_DROP'],
	[InformixServerStateCode.BLOCK_DDR, 'DDR'],
	[InformixServerStateCode.BLOCK_LBU_PRESERVE, 'LAST_LOG_RESERVED4BACKUP'],
	[InformixServerStateCode.BLOCK_LOG_FOR_ER, 'DYNAMIC_LOG_FOR_ER'],
	[InformixServerStateCode.BLOCK_LOG, 'DYNAMIC_LOG'],
	[InformixServerStateCode.BLOCK_LOG_DROP, 'LOG_DROP'],
	[InformixServerStateCode.BLOCK_FREELOG, 'FREE_LOG'],
	[InformixServerStateCode.SYS_WAIT4OVRIDE, 'OVERRIDE_DOWN_SPACE'],
	[InformixServerStateCode.BLOCK_ARC_EBR, 'ARCHIVE_EBR'],
	[InformixServerStateCode.BLOCK_CHG_PLOG, 'CHG_PLOG'],
	[InformixServerStateCode.BLOCK_DR_SWITCH, 'HA_CONV_STD'],
	[InformixServerStateCode.BLOCK_HA_SWITCH, 'HA_FAILOVER'],
	[InformixServerStateCode.UNKNOWN, 'UNKNOWN'],
]);

export interface InformixServerStatus {
  code: number;
  description?: string;
  color?: string;
  state?: InformixServerState;
  serverStats?: InformixServerStat;
}

export interface InformixServerState {
  code: number;
  description?: string;
}

export interface InformixServerType {
  serverType: ServerTypes;
  isReadOnly?: boolean;
  allowOnReadOnly?: boolean;
}

export interface InformixVersionType {
  major: number;
  minor: number;
  os?: string;
  level?: string;
  infra?: number;
}

export interface InformixServerStat {
  version: string;
  uptime: number;
  max_connections: number;
  num_users: number;
  online?: boolean;
}

export class InformixServer extends InformixTreeItem implements CacheableResource {
  alias: string;
  hostname: string;
  serviceName: string;
  port: number;
  monitorUser: string;
  hasMonitorPassword: boolean;
  adminUser: string;
  hasAdminPassword: boolean;
  agent: InformixServerAgent = null;
  status: InformixServerStatus;
  unreadIncidents: number;
  connectionProperties: { [key: string]: string };
  serverType: InformixServerType;
  isWorkOffline: boolean;
  version: InformixVersionType;
  serverStat: InformixServerStat;
  state: InformixServerState;

  constructor(json: any) {
    super(json.id, json.parentGroupId, json.alias, json.permissions);
    this.alias = json.alias;
    this.hostname = json.hostname;
    this.serviceName = json.serviceName;
    this.port = json.port;
    this.monitorUser = json.monitorUser;
    this.hasMonitorPassword = json.hasMonitorPassword;
    this.adminUser = json.adminUser;
    this.hasAdminPassword = json.hasAdminPassword;
    this.unreadIncidents = json.unreadIncidents;
    this.connectionProperties = json.connectionProperties || {};
    this.serverType = json.serverType;
    this.isWorkOffline = json.isWorkOffline;
    this.version = json.version;
    this.serverStat = json.serverStat;

    if (json.agent) {
      this.agent = new InformixServerAgent(json.agent);
    }

    this.setStatus(json.status);
    this.setState(json.state);
  }

  public getId(): string {
    return this.id.toString();
  }

  getUrl(): string {
    return 'informix/' + this.id;
  }

  getDatabasesUrl(): string {
    return this.getUrl() + '/databases';
  }

  getERUrl(): string {
    return this.getUrl() + '/er';
  }

  /**
   * Set Informix status by its returned code.
   *
   * @param status
   */
  public setStatus(status: InformixServerStatus): void {
    if(status !== undefined && status.code !== undefined) {
      this.status = this.getStatusByCode(status.code);
    }else {
      this.status = null;
    }
  }

  /**
   * get Informix status by its code.
   *
   * @param code
   */
  public getStatusByCode(code: number): InformixServerStatus {
    if(code === undefined) {
      return null;
    }
    return { code, description: InformixServerStatusMap.get(code), color: informixServerStatusListMap.get(code) };
  }

  public getStatusColorByCode(code: number): string {
    return informixServerStatusListMap.get(code);
  }

  /**
   * Set Informix state by its code.
   *
   * @param state
   */
  public setState(state: InformixServerState): void {
    if(state && state.code !== undefined ) {
      this.state = this.getStateByCode(state.code);
    }else {
      this.state = null;
    }
  }

  /**
   * get Informix state by its code.
   *
   * @param code
   */
  public getStateByCode(code: number): InformixServerState {
    if(code === undefined) {
      return null;
    }
    return { code, description: InformixServerStateMap.get(code) };
  }
}

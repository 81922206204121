/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerChunk } from './serverChunk';

@Component({
  selector: 'app-extend-chunk',
  templateUrl: 'extend-chunk.html'
})
export class ExtendChunkComponent implements OnInit {

  @Input() server: InformixServer = null;
  @Input() selectedChunk: ServerChunk = null;

  formGroup: UntypedFormGroup;
  unitOptions: string[] = ['%', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      extendSize: new UntypedFormControl(10, [this.validateExtendSize.bind(this)]),
      extendSizeUnit: new UntypedFormControl('%', [Validators.required])
    });

    this.formGroup.controls['extendSizeUnit'].valueChanges.subscribe(value => {
      this.formGroup.controls['extendSize'].updateValueAndValidity();
    });
  }

  validateExtendSize(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    }
    if (this.formGroup && this.formGroup.controls['extendSizeUnit'].value === '%') {
      if (c.value >= 0 && c.value <= 100) {
        return null;
      } else {
        return { customError: 'Percentage values must be between 0 and 100' };
      }
    }
    return null;
  }

  public doExtendChunk() {
    let extendSizeString: string = this.formGroup.value['extendSize'].toString();
    if (this.formGroup.value['extendSizeUnit'] !== '%') {
      extendSizeString += this.formGroup.value['extendSizeUnit'];
    }
    this.informixServerStorageService.extendChunk(this.server.id, this.selectedChunk.chknum,
      extendSizeString).then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  public doClose() {
    this.close.emit();
  }

}

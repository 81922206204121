/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { MonitoringService } from '../../monitoring/monitoring.service';
import { AbstractAlertOperand } from './abstract-alert-operand';
import { InformixServerStorageService } from '../../servers/storage/informixServerStorage.service';
import { TranslateService } from '@ngx-translate/core';
import { ServerSpace } from '../../servers/storage/serverSpace';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { ActivatedRoute } from '@angular/router';
import { InformixServer } from '../../servers/informixServer';


interface SensorOption {
  id: string;
  name: string;
  metrics: MetricOption[];
}

interface MetricOption {
  id: string;
  name: string;
  dataType: string;
  unit: string;
}

@Component({
  selector: 'app-latest-metric-operand',
  template: `
  <form [formGroup]="formGroup" *ngIf="sensorOptionsFiltered" class="form-group form-inline">
  <div class="d-flex justify-content-between">
  <div>
    <ng-container *ngIf="sensorOptionsFiltered.length > 0; else noValidSensors">
      <select class="form-control form-control-sm" formControlName="sensor">
        <option *ngIf="!sensorControl.value" [ngValue]="null">Select a sensor...</option>
        <option *ngFor="let option of sensorOptionsFiltered" [ngValue]="option">{{option.name}}</option>
      </select>
      <select class="form-control form-control-sm ml-2" *ngIf="metricOptions" formControlName="metric">
        <option *ngFor="let option of metricOptions" [ngValue]="option">{{option.name}}</option>
      </select>
    </ng-container>
    <ng-template #noValidSensors>
      <div class="alert alert-warning d-inline-block">
        No valid sensors available.
      </div>
    </ng-template>
    </div>

    <div *ngIf="serverData && !isLoadingSpaces">
     <ng-container *ngIf="formGroup.controls.sensor.value?.name === 'DBSpace Usage'" >
        <div class="d-flex justify-content-between" style="width: 400px">
         <label class="ml-2" for="primaryKeySelect" *ngIf="!allPrimaryKeys">DBSpace:&nbsp;</label>
         <ng-select *ngIf="!allPrimaryKeys" [multiple]="true" (change)="changeSelectedPrimaryKey($event)"
         formControlName="selectedPrimaryKeys" [searchable]="false" [items]="primaryKeys" bindLabel="value"
         bindValue="value" [placeholder]="'Select Space(s)'">
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
              <div class="ng-value" *ngFor="let item of items | slice:0:1">
                <span class="ng-value-label">{{item}}</span>
                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
              </div>
              <div class="ng-value" *ngIf="items.length > 1">
                <span class="ng-value-label">{{items.length - 1}} more...</span>
              </div>
            </ng-template>
          </ng-select>
          <div class="float-right ml-2">
            <app-checkbox class="ml-0" [checked]="allPrimaryKeys" (change)="onAllPrimaryKeysCheckBoxChange($event)">
              All DBSpace(s)
            </app-checkbox>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  </form>
  `
})
export class LatestMetricOperandComponent extends AbstractAlertOperand implements OnInit {
  private sensorOptions: SensorOption[] = null;
  metricOptions: MetricOption[] = null;
  sensorOptionsFiltered: SensorOption[] = null;

  formGroup: UntypedFormGroup;
  sensorControl: UntypedFormControl;
  primaryKeys: string[] = [];
  allPrimaryKeys: Boolean = true;
  isLoadingSpaces: Boolean = false;
  serverData: InformixServer;

  constructor(
    private monitoringService: MonitoringService,
    private storageService: InformixServerStorageService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute

  ) {
    super();
  }

  ngOnInit() {
    this.serverData = this.route.snapshot.data.server;
    if (this.serverData) {
       // dbspace sensor alert controller
      if (this.externalCondition) {
        if (this.externalCondition.dbspaces && this.externalCondition.dbspaces.length === 0) {
          this.allPrimaryKeys = true;
        } else {
          this.allPrimaryKeys = false;
          this.primaryKeys = this.externalCondition.dbspaces;
        }
      }
    }

    this.monitoringService.getEffectiveMonitoringProfile(this.owner).then(profile => {
      this.sensorControl = new UntypedFormControl(null, Validators.required);

      this.formGroup = new UntypedFormGroup({
        sensor: this.sensorControl,
        metric: new UntypedFormControl(null, Validators.required),
        selectedPrimaryKeys: new UntypedFormControl([])
      });

      this.sensorOptions = [];
      profile.sensors.forEach(sensor => {
        const option: SensorOption = {
          id: sensor.type.id,
          name: sensor.name,
          metrics: []
        };

        for (const key in sensor.type.meta.metrics) {
          if (sensor.type.meta.metrics.hasOwnProperty(key)) {
            const metricMeta = sensor.type.meta.metrics[key];
            const metric = {
              id: key,
              name: metricMeta.name,
              dataType: metricMeta.dataType || 'number',
              unit: metricMeta.unit
            };

            option.metrics.push(metric);
          }
        }

        this.sensorOptions.push(option);
      });
      this.sensorOptions.sort((a, b) => a.name.localeCompare(b.name));

      this.filterSensorOptions();

      this.formGroup.valueChanges.pipe(delay(0)).subscribe(value => {
        this.emitUpdate();
      });

      if (this.operand && this.operand.sensor) {
        const sensorOption = this.sensorOptions.find(v => v.id === this.operand.sensor);
        let metricOption: MetricOption = null;
        if(sensorOption.name === 'DBSpace Usage' && this.serverData){
          this.getSpaces();
        }

        if (sensorOption) {
          this.metricOptions = sensorOption.metrics;
          metricOption = this.metricOptions.find(v => v.id === this.operand.metric) || this.metricOptions[0];
        }

        this.formGroup.setValue({
          sensor: sensorOption,
          metric: metricOption,
          selectedPrimaryKeys: this.primaryKeys
        });
      } else {
        this.emitUpdate();
      }

      this.sensorControl.valueChanges.subscribe(value => {
        this.metricOptions = value.metrics;
        this.formGroup.controls.metric.setValue(this.metricOptions[0]);
        if(value.name === 'DBSpace Usage' && !this.primaryKeys.length && this.serverData) {
          this.getSpaces();
        } else {
          // empty dbspaces if sensor changed from 'DBSpace Usage' to any other sensor
          this.setExternalCondition({dbspaces:[]});
        }
      });
    }).catch(err => {
      console.error(err);
    });
  }

  private filterSensorOptions() {
    if (!this.sensorOptions) {
      return;
    }

    if (this.dataTypeFilter) {
      this.sensorOptionsFiltered = [];
      this.sensorOptions.forEach(sensor => {
        const metricOptions = sensor.metrics.filter(metric => metric.dataType === this.dataTypeFilter);
        if (metricOptions.length > 0) {
          this.sensorOptionsFiltered.push({
            id: sensor.id,
            name: sensor.name,
            metrics: metricOptions
          });
        }
      });
    } else {
      this.sensorOptionsFiltered = this.sensorOptions;
    }
  }

  public isValid(): boolean {
    return this.formGroup.valid;
  }

  public getValue(): any {
    const v = this.formGroup.value;
    return {
      sensor: v.sensor ? v.sensor.id : null,
      metric: v.metric ? v.metric.id : null
    };
  }

  public setDataTypeFilter(dataType: string) {
    super.setDataTypeFilter(dataType);
    this.filterSensorOptions();
  }

  protected getOutputType() {
    const metric: MetricOption = this.formGroup.value.metric;
    return metric ? metric.dataType : null;
  }

  protected getOutputUnit(): string {
    const metric = this.formGroup.value.metric as MetricOption;
    return metric ? metric.unit : null;
  }

  // API to get dbspaces
  getSpaces() {
    this.isLoadingSpaces = true;
    this.storageService.getServerSpaces(this.owner.id).subscribe((data: ServerSpace[])=>{
      this.primaryKeys = data.filter((value: ServerSpace) => value.name && value.type === 'dbspace')
      .map((value: ServerSpace) => value.name);
      this.isLoadingSpaces = false;
    }, err => {
      this.isLoadingSpaces = false;
      this.translate.get('schemaManager.createDatabase.errorMsg.getDbspacesError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          this.notificationsService.pushErrorNotification(text);
        });
    });
  }

  public onAllPrimaryKeysCheckBoxChange(value: boolean) {
    this.allPrimaryKeys = value;
    if(value) {
      this.formGroup.controls.selectedPrimaryKeys.setValue([]);
      this.setExternalCondition({dbspaces: []});
    } else {
      this.setExternalCondition({dbspaces: this.formGroup.controls.selectedPrimaryKeys.value});
    }
  }

  public changeSelectedPrimaryKey(selectedSpaces: any) {
    this.formGroup.controls.selectedPrimaryKeys.setValue(selectedSpaces);
    this.setExternalCondition({dbspaces: selectedSpaces});
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/

import { Component, forwardRef, Input, OnInit, SimpleChange } from '@angular/core';
import { ControlValueAccessor, UntypedFormArray, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { informixServerPermissions } from '../informixTreeItem';

@Component({
  selector: 'app-permissions-control',
  template: `
  <div [formGroup]="formGroup">
    <div formArrayName="permissions">
      <div class="mb-1" *ngFor="let perm of permissions; let i = index">
        <app-checkbox [formControlName]="i" [tooltip]="isPermissionDisabled(perm.value) ? 'Cannot revoke inherited permissions' : ''">
          {{perm.name}}
        </app-checkbox>
      </div>
    </div>
  </div>
  `,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PermissionsControlComponent), multi: true }
  ]
})
export class PermissionsControlComponent implements OnInit, ControlValueAccessor {

  @Input() parentPermissions: number = null;

  private onChangeFn: Function;

  formGroup: UntypedFormGroup;
  formArray: UntypedFormArray;
  permissions = informixServerPermissions;
  value: number;

  ngOnInit(): void {
    this.parentPermissions = this.parentPermissions || 0;

    const controls: UntypedFormControl[] = this.permissions.map((perm, index) => {
      const control = new UntypedFormControl(this.isPermissionDisabled(perm.value) ? { value: true, disabled: true } : false);
      control.valueChanges.subscribe((checked: SimpleChange) => {
        let newValue = this.value;
        if (checked) {
          newValue |= perm.value;
        } else {
          if ((this.value & perm.value) === perm.value) {
            newValue ^= perm.value;
          }
        }
        if (checked) {
          perm.inherited.forEach((inheritedPerm, i) => {
            newValue |= inheritedPerm;
            if (i < index) {
              this.formArray.controls[i].setValue(true, { emitEvent: false });
              this.formArray.controls[i].disable({ emitEvent: true });
            }
          });
        }else {
          this.formArray.controls[index - 1]?.enable({ emitEvent: false });
        }
        this.value = newValue;
      });
      return control;
    });
    this.formArray = new UntypedFormArray(controls);

    this.formGroup = new UntypedFormGroup({
      permissions: this.formArray
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.onChangeFn) {
        this.onChangeFn(this.value);
      }
    });
  }

  writeValue(value: any): void {
    this.value = value;
    if (typeof value === 'number') {
      this.permissions.forEach((perm, i) => {
        if (this.formArray.controls[i].enabled) {
          this.formArray.controls[i].setValue((value & perm.value) === perm.value);
        }
      });
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  isPermissionDisabled(perm: number) {
    return (this.parentPermissions & perm) === perm;
  }
}

<div class="pt-2 pb-2 tablist" role="tablist">
  <span class="font-weight-bold mr-1"> {{'schemaManager.tableInfo.pageTitle' | translate}}</span>
  <button type="button" class="btn btn-secondary btn-tab mr-2" [class.active]="selectedTab===tab"
    *ngFor="let tab of tabList; let first = first;" (click)="selectedTab = tab">
    {{tab}}
  </button>
</div>
<div class="content-container pl-3 border-gray-20">
  <p class="mt-3 mb-2">
    <a [routerLink]="[]" [queryParams]="{ database: session.database.name }">
      {{session.database.name}}
    </a>
    <span class="mx-1">&gt;</span>
    {{table.name}}
  </p>
  <h5 class="font-weight-bold">{{selectedTab}}</h5>
  <div class="tab-content pt-2 pb-3 pr-3">
    <div class="text-muted pb-2 pt-2" *ngIf="selectedTab === tabList[2]">
      <i class="icon icon-info-circle mr-2 text-primary"></i> <span>Note: Grayed out indexes are associated with table constraints and cannot be deleted from the list. Deleting such indexes would require dropping the associated constraint.</span>
    </div>
    <ng-container *ngIf="tableDetails || tableDetailsLoadError; else detailsLoadingTemplate">
      <ng-container *ngIf="!tableDetailsLoadError">
        <div *ngIf="selectedTab === tabList[0]" role="tabpanel" class="tab-pane active pl-3">
          <app-table-info [tableDetails]="tableDetails" [table]="table"></app-table-info>
        </div>
        <div *ngIf="selectedTab === tabList[1]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.columns && tableDetails.columns.length > 0; else noDataTemplate">
            <app-table-columns-info [tableDetails]="tableDetails"></app-table-columns-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[2]" role="tabpanel" class="tab-pane active">
          <app-table-indexes-info [tableDetails]="tableDetails" [table]="table"
            (openCreateIndex)="openCreateIndex.emit(table)" (refreshIndexes)="loadTableDetails()">
          </app-table-indexes-info>
        </div>
        <div *ngIf="selectedTab === tabList[3]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.references && tableDetails.references.length > 0; else noDataTemplate">
            <app-table-references-info [tableDetails]="tableDetails"></app-table-references-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[4]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.privileges && tableDetails.privileges.length > 0; else noDataTemplate">
            <app-table-privileges-info [tableDetails]="tableDetails"></app-table-privileges-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[5]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.statistics; else noDataTemplate">
            <app-table-statistics-info [tableDetails]="tableDetails" [table]="table" (refreshData)="loadTableDetails()">
            </app-table-statistics-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[6]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.constraints && tableDetails.constraints.length > 0; else noDataTemplate">
            <app-table-constraints-info [tableDetails]="tableDetails" [session]="session" [server]="server" [table]="table" [server]="server" (refreshData)="loadTableDetails()"></app-table-constraints-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[7]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.fragments && tableDetails.fragments.length > 0; else noDataTemplate">
            <app-table-fragments-info [tableDetails]="tableDetails"></app-table-fragments-info>
          </ng-container>
        </div>
        <div *ngIf="selectedTab === tabList[8]" role="tabpanel" class="tab-pane active">
          <ng-container *ngIf="tableDetails.triggers && tableDetails.triggers.length > 0; else noDataTemplate">
            <app-table-triggers-info [tableDetails]="tableDetails"></app-table-triggers-info>
          </ng-container>
        </div>
      </ng-container>
      <div class="alert alert-danger mt-2" *ngIf="tableDetailsLoadError">
        {{tableDetailsLoadError}}
      </div>
    </ng-container>
    <ng-template #noDataTemplate>
      <div class="alert alert-info mb-0">
        {{table.name}} has no {{selectedTab.toLowerCase()}}.
      </div>
    </ng-template>
    <ng-template #detailsLoadingTemplate>
      <div class="mt-2 text-center">
        <i class="icon icon-circle-notch icon-spin icon-2x"></i>
      </div>
    </ng-template>
  </div>
</div>

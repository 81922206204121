/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { SystemConfigService } from '../system-config.service';
import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import {encode} from 'html-entities';

@Component({
  selector: 'app-system-alerts',
  templateUrl: 'systemAlerts.html'
})
export class SystemAlertsComponent implements OnInit {

  configs: any = null;

  constructor(private configService: SystemConfigService,
    private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.configService.getConfigs().then(configs => {
      // Encoding the HTML if it is present in the configs before rendering it
      this.configs = this.encodeObjectProperties(configs);
    }).catch(err => {
      console.error('Unable to get system config', err);
    });
  }

  saveConfig(type: string, config: any) {
    // Encoding the HTML if it is present in the configs before sending it to the server
    this.encodeObjectProperties(config);
    this.configService.saveConfig(type, config).then(() => {
      this.configs[type] = config;
    }).catch(err => {
      this.notificationsService.pushErrorNotification(err.error ? err.error.err : err);
      console.error('Unable to save system config', err);
    });
  }

  testConfig(type: string, config: any) {
    this.configService.testAlertConfig(type, config).then(() => {
      this.notificationsService.pushSuccessNotification('Test alert sent');
    }).catch(err => {
      console.error('Test alert failed', err);
      this.notificationsService.pushErrorNotification(err);
    });
  }

  /**
   * this function encodes HTML of all properties of an object
   *
   * @param obj The object to encode
   */
  encodeObjectProperties(obj: any): any {
    // Loop through all properties of the object
    for (const prop in obj) {
      // Check if the property is an object
      if (typeof obj[prop] === 'object') {
        // If the property is an object, call the function recursively
        this.encodeObjectProperties(obj[prop]);
      } else {
        // If the property is not an object, encode the property
        obj[prop] = encode(obj[prop]);
      }
    }
    return obj;
  }
}

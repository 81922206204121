/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Pipe, PipeTransform } from '@angular/core';

const units: string[] = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

@Pipe({ name: 'byte' })
export class BytePipe implements PipeTransform {
  transform(value: bigint, precision: number = 2): string {
    // Removing decimal part and converting to BigInt
    const bytes = BigInt(Math.abs(Number(value)));
    let limit = BigInt(1);
    let i = 0;
    for (; i < units.length - 1; i++) {
      if (bytes < limit * BigInt(1024)) {
        break;
      }
      limit *= BigInt(1024);
    }

    let convertedValue = Number(bytes) / Number(limit);
    convertedValue = Math.round(convertedValue * Math.pow(10, precision)) / Math.pow(10, precision);
    return convertedValue + ' ' + units[i];
  }
}

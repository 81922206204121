/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { HDRPermission } from '../../shared/hdr-permission/hdr-permission';

import { InformixServer, InformixServerStatusCode } from '../servers/informixServer';


@Component({
  selector: 'app-server-card',
  templateUrl: 'server-card.html',
  styleUrls: ['group.scss']
})
export class ServerCardComponent implements OnInit, OnChanges {

  @Input() server: InformixServer;
  @Input() isSelected = false;
  @Input() isServerRefreshing;

  ignoreCardClick = false;

  @Output() eventEdit = new EventEmitter<any>();
  @Output() eventDelete = new EventEmitter<any>();
  @Output() eventSelect = new EventEmitter<boolean>();
  @Output() eventEnter = new EventEmitter<any>();
  @Output() eventMove = new EventEmitter<any>();
  @Output() eventRefresh = new EventEmitter<any>();

  hdrPermission: HDRPermission;
  unreadIncidentsAbr: string;
  serverStatusCode = InformixServerStatusCode;
  constructor() { }

  ngOnInit() {
  }

  onEdit() {
    this.eventEdit.emit(null);
  }

  onDelete() {
    this.eventDelete.emit(null);
  }

  onMouseDown(event: MouseEvent) {
    if (event.shiftKey) {
      event.preventDefault();
    }
  }

  onClick(event: MouseEvent) {
    if (this.ignoreCardClick) {
      this.ignoreCardClick = false;
      return;
    }

    if (event.shiftKey || event.ctrlKey) {
      this.eventSelect.emit(event.shiftKey);
    } else {
      this.eventEnter.emit(null);
    }
  }

  onMove() {
    this.eventMove.emit(null);
  }

  // Emit event to refresh the server
  onRefresh(event) {
    event.stopPropagation();
    this.eventRefresh.emit(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.server) {
      this.hdrPermission = new HDRPermission(null, this.server.serverType);
    }
  }
}

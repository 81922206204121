/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { UserSettingsService } from './user-settings.service';
import { UserService } from '../../shared/user/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: 'changePassword.html'
})
export class ChangePasswordComponent {

  passwordControl = new UntypedFormControl(null, Validators.required);

  constructor(
    private userSettingsService: UserSettingsService,
    private notifications: NotificationsService,
    private userService: UserService
  ) { }

  isSaving = false;

  savePassword() {
    this.isSaving = true;
    this.userSettingsService.changePassword(this.passwordControl.value.old, this.passwordControl.value.new).then(() => {
      this.notifications.pushSuccessNotification('Password updated');
      setTimeout(() => {
        this.userService.logOut();
      }, 1000);
      this.passwordControl.reset();
    }).catch((err: HttpErrorResponse) => {
      if (err.status === 403) {
        this.notifications.pushErrorNotification('The old password you entered was incorrect');
      } else {
        let msg = 'There was a problem updating your password';
        if (err.status === 400 && err.error.err) {
          msg = err.error.err;
        }
        this.notifications.pushErrorNotification(msg);
        console.error(err);
      }
    }).then(() => {
      this.isSaving = false;
    });
  }

}

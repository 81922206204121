/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BreadcrumbElement } from '../../shared/breadcrumb.component';
import { CanComponentDeactivate } from '../../shared/canDeactivateGuard.service';
import { AlertingProfileComponent } from '../alerting/alerting-profile.component';
import { GroupBreadcrumb } from '../groups/groupBreadcrumb';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { InformixServer } from './informixServer';
import { ServerBreadcrumb } from './serverBreadcrumb';

@Component({
  selector: 'app-server-alerting',
  template: `
  <app-hdr-banner-component [serverType]="server.serverType"></app-hdr-banner-component>
  <app-breadcrumb-component [elements]="breadcrumb"></app-breadcrumb-component>
  <div class="container-fluid page-container" *ngIf="server">
    <app-alerting-profile [owner]="server"> </app-alerting-profile>
  </div>
  `
})
export class ServerAlertingComponent implements OnInit, CanComponentDeactivate {

  server: InformixServer;
  breadcrumb: BreadcrumbElement[] = null;

  @ViewChild(AlertingProfileComponent) alertingProfileComponent: AlertingProfileComponent;

  constructor(
    private route: ActivatedRoute,
    private groupService: InformixServerGroupService
  ) { }

  canDeactivate() {
    return this.alertingProfileComponent ? this.alertingProfileComponent.canDeactivate() : true;
  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.server = data.server;

      if (this.server) {
        this.groupService.getGroup(this.server.parentGroupId, null, false, false).then(group => {
          this.breadcrumb = GroupBreadcrumb.build(group, ServerBreadcrumb.build(this.server, [{ name: 'Alerting' }]));
        }).catch(err => console.error(err));
      }
    });
  }
}

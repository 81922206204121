/*******************************************************************************
* Licensed Materials - Property of IBM and/or HCL
*
* Copyright IBM Corporation. 2015, 2017.
* Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
*******************************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObservablePoller } from '../../shared/observable-poller';
import { ResourceCache } from '../../shared/resourceCache';
import { InformixServerGroupService } from '../groups/informixServerGroup.service';
import { InformixServer, InformixServerStat, InformixServerStatus } from './informixServer';
import { InformixServerAgent, InformixServerAgentConfig } from './informixServerAgent';
import { ServerThread } from './performance/threads/serverThread';
import { ServerBufferPool } from './serverBufferPool';
import { ServerSpace } from './storage/serverSpace';
import { GROUP_ROOT_ID } from '../groups/informixServerGroup';
import { ClusterInfo } from './high-availability/high-availability.model';
import { HDRPermissionService } from '../../shared/hdr-permission/hdr-permission.service';

@Injectable()
export class InformixServerService {

  private serverCache = new ResourceCache<InformixServer>();

  private incidentsPollers = new Map<number, ObservablePoller<any>>();

  onConnectionStatus = new BehaviorSubject<Boolean>(null);
  onConnStatus$ = this.onConnectionStatus.asObservable();
  _serverInfoFormSource = new BehaviorSubject<any>(null);

  serverInforForm$ = this._serverInfoFormSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private groupService: InformixServerGroupService,
    private hdrPermissionService: HDRPermissionService
  ) { }

  public getServer(id: string): Promise<InformixServer> {
    const serverPromise = this.serverCache.get(id);
    if (serverPromise) {
      return serverPromise;
    }

    const promise = this.httpClient.get<InformixServer>('informix/' + id)
      .toPromise<InformixServer>()
      .then(json => {
        const informixServerInstance = new InformixServer(json);
        this.hdrPermissionService.initPermissions(informixServerInstance.serverType);
        return informixServerInstance;
      });

    this.serverCache.putPromise(id, promise);
    return promise;
  }

  public getServers(ids: number[]): Promise<InformixServer[]> {
    if (ids.length < 1) {
      return Promise.resolve([]);
    } else if (ids.length === 1) {
      return this.getServer(ids[0].toString()).then(server => [server]);
    }

    return this.groupService.getGroup(GROUP_ROOT_ID, -1, true, true).then(rootGroup => ids.map(id => rootGroup.getServer(id)));
  }

  public getServerStat(serverId: number): Observable<InformixServerStat> {
    return this.httpClient.get<InformixServerStat>('informix/' + serverId + '/serverStat');
  }

  public getServerStatus(serverId: number): Observable<InformixServerStatus> {
    return this.httpClient.get<InformixServerStatus>('informix/' + serverId + '/status');
  }

  public testConnection(info: any): Observable<any> {
    return this.httpClient.post('informix/status', info);
  }

  public getServerHighAvailabilityStatus(serverId: number, includeDetailedInfo: boolean): Observable<ClusterInfo> {
    return this.httpClient.get<ClusterInfo>('informix/' + serverId + '/ha?includeDetailedInfo=' + includeDetailedInfo)
      .pipe(map(response => new ClusterInfo(response)));
  }

  public getConnectionManagers(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/cmsm');
  }

  public getServerAutoUpdateStatsInfo(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/aus');
  }

  public getDashboardServerInfo(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/dashboard/info');
  }

  public getDashboardStoragePerformance(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/dashboard/storage_perf');
  }

  public getServerSessionSummaryInfo(serverId: number): Observable<any> {
    return this.httpClient.get<any>('informix/' + serverId + '/sessions/summary');
  }

  public getServerSpaces(serverId: number): Observable<ServerSpace[]> {
    return this.httpClient.get<ServerSpace[]>('informix/' + serverId + '/storage/spaces');
  }

  public getServerMemory(serverId: number): Observable<ServerSpace[]> {
    return this.httpClient.get<ServerSpace[]>('informix/' + serverId + '/memory');
  }

  public getServerBufferPools(serverId: number): Observable<ServerBufferPool[]> {
    return this.httpClient.get<ServerBufferPool[]>('informix/' + serverId + '/memory/bufferpools');
  }

  public getServerThreads(serverId: number): Observable<ServerThread[]> {
    return this.httpClient.get<ServerThread[]>('informix/' + serverId + '/profile/threads');
  }

  public getServerVPs(serverId: number): Observable<any[]> {
    return this.httpClient.get<any[]>('informix/' + serverId + '/vps');
  }

  public getServerClassVPs(serverId: number, vpClass: string): Observable<any[]> {
    return this.httpClient.get<any[]>('informix/' + serverId + '/vps/' + vpClass);
  }

  public addServerVPs(serverId: number, vpClass: string, count: number): Promise<any> {
    return this.httpClient.post<any>('informix/' + serverId + '/vps/' + vpClass, { count }).toPromise<any>();
  }

  public dropServerVPs(serverId: number, vpClass: string, count: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + serverId + '/vps/' + vpClass + '?count=' + count).toPromise<any>();
  }

  public getServerThreadVPs(serverId: number, vpClass: string): Observable<any[]> {
    return this.httpClient.get<any[]>('informix/' + serverId + '/vps/' + vpClass);
  }

  public createServer(info: any): Promise<InformixServer> {
    return this.httpClient.post('informix', info).pipe(
      map(response => new InformixServer(response))).toPromise();
  }

  public editServer(serverId: number, info: any): Promise<InformixServer> {
    return this.httpClient.put('informix/' + serverId, info).pipe(
      map(response => new InformixServer(response))).toPromise();
  }

  deleteServer(serverId: number): Promise<any> {
    return this.httpClient.delete<any>('informix/' + serverId).toPromise<any>();
  }

  getIncidents(serverId: number, afterTimestamp?: number): Observable<any> {
    let url = 'informix/' + serverId + '/incidents';
    if (afterTimestamp > 0) {
      url += '?after=' + afterTimestamp;
    }

    return this.httpClient.get(url);
  }

  pollIncidents(serverId: number): Observable<any> {
    let poller = this.incidentsPollers.get(serverId);
    if (!poller) {
      poller = new ObservablePoller(5000, () => {
        const prev = poller.getPreviousValue() || [];
        let afterTimestamp: number = null;
        if (prev.length) {
          afterTimestamp = prev[0].when_created;
        }
        return this.getIncidents(serverId, afterTimestamp).pipe(
          map(newIncidents => newIncidents.length ? newIncidents.concat(prev) : prev)
          );
      }, () => {
        this.incidentsPollers.delete(serverId);
      });

      this.incidentsPollers.set(serverId, poller);
    }

    return poller.getObservable();
  }

  updateIncidents(serverId: number, updateData: any[]): Promise<any> {
    return this.httpClient.put('informix/' + serverId + '/incidents', { incidents: updateData }).toPromise();
  }

  deleteIncidents(serverId: number, ids: number[]): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders(), body: { incidents: ids }
    };
    return this.httpClient.delete('informix/' + serverId + '/incidents', httpOptions).toPromise().then(() => {
      const poller = this.incidentsPollers.get(serverId);
      if (poller) {
        poller.injectValue(this.getIncidents(serverId));
      }
    });
  }

  moveServers(servers: InformixServer[], destGroupId: number): Promise<any> {
    const body = servers.map(server => ({ id: server.id, groupId: destGroupId }));
    return this.httpClient.put<any>('informix/', body).toPromise<any>();
  }

  getAgent(server: InformixServer): Observable<InformixServerAgent> {
    return this.httpClient.get('informix/' + server.id + '/agent').pipe(
      map(response => new InformixServerAgent(response)));
  }

  updateAgentConfig(server: InformixServer, config: InformixServerAgentConfig): Observable<InformixServerAgent> {
    return this.httpClient.put('informix/' + server.id + '/agent', { config }).pipe(
      map(response => new InformixServerAgent(response)));
  }

  deployAgent(server: InformixServer, config: any): Observable<any> {
    return this.httpClient.post('informix/' + server.id + '/agent/deploy', config);
  }

  shutdownAgent(server: InformixServer): Observable<InformixServerAgent> {
    return this.httpClient.put('informix/' + server.id + '/agent', { online: false }).pipe(
      map(response => new InformixServerAgent(response)));
  }
  shutdownHQserver(): Observable<any> {
    const configHq = {};
    return this.httpClient.post('hq/shutdown', configHq);
  }
  shutdownAgents(reqBody: any): Observable<any> {
    return this.httpClient.post('informix/agent/shutdown', reqBody).pipe(
      map(response => response));
  }

  getWorkOffline(server, isWorkOffline: Boolean): Observable<any> {
    return this.httpClient.get('informix/' + server.id + '/work/offline?isWorkOffline=' + isWorkOffline);
  }

  setConnectionStatus(isWorkOffline) {
    this.onConnectionStatus.next(isWorkOffline);
  }

  refreshServers(servers) {
    return this.httpClient.put('informix/refresh', servers).toPromise<any>();;
  }
}

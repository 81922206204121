<div class="card h-100" (mousedown)="onMouseDown($event)" (click)="onClick($event)"
  [ngClass]="{ selected: isSelected, 'card-warning-border': server.unreadIncidents > 0, locked: !server.permissions.read }">
  <div class="card-header py-2 px-3 d-flex align-items-center">
    <div class="flex-1 overflow-hidden pl-1">
      <h5 class="font-weight-bold m-0 text-truncate">{{server.alias}}</h5>
    </div>
    <button class="close mr-2 pl-2" title="Refresh server version and server type information" (click)="onRefresh($event)">
      <i class="icon icon-refresh" [ngClass]="{'icon-spin cursor-progress': isServerRefreshing}"></i>
    </button>
    <div *ngIf="server.permissions.admin" dropdown container="body" placement="bottom right"
      (click)="ignoreCardClick = true">
      <button class="close" dropdownToggle>
        <i class="icon icon-menu-overflow-vertical"></i>
      </button>
      <div *dropdownMenu class="dropdown-menu dropdown-menu-right">
        <button class="dropdown-item" type="button" (click)="onEdit()">
          <i class="icon icon-edit mr-2"></i> Edit
        </button>
        <button class="dropdown-item" type="button" (click)="onMove()">
          <i class="icon icon-folder mr-2"></i> Move to...
        </button>
        <div class="dropdown-divider"></div>
        <button class="dropdown-item text-danger" type="button" (click)="onDelete()">
          <i class="icon icon-trash mr-2"></i> Delete
        </button>
      </div>
    </div>
  </div>
  <div class="card-body p-1" *ngIf="server.permissions.read; else serverLocked">
  <div class="item-stats">
      <div class="flex-1 item-stat hdr-type px-3">
        <span *ngIf="hdrPermission.isNAServer()" [ngStyle]="{'color': hdrPermission.getBackgroundColor()}" tooltip="Server type not avaiable with HQ. " placement="right">{{hdrPermission.getServerType() && hdrPermission.getServerType().toUpperCase()}}</span>
        <span *ngIf="!hdrPermission.isNAServer()" [ngStyle]="{'color': hdrPermission.getBackgroundColor()}" class="mr-1">{{hdrPermission.isSecondaryServer()? 'HDR' : ''}} {{hdrPermission.getServerType() && hdrPermission.getServerType().toUpperCase()}}</span>
        <span *ngIf="hdrPermission.fromSecondary()">
          <span *ngIf="hdrPermission.isReadOnly(); else updatableSpan" class="badge badge-dark py-1 px-2">READ-ONLY</span>
          <ng-template #updatableSpan>
              <span class="badge badge-dark py-1 px-2">UPDATABLE</span>
          </ng-template>
        </span>
      </div>
    </div>
    <div class="item-stats">
      <div class="flex-1 text-center item-stat border-right border-gray-20">
        <i class="icon icon-check-circle text-success" aria-hidden="true"
          *ngIf="server.status && (server.status.code === serverStatusCode.ONLINE || server.status.code === serverStatusCode.RECOVERY) "
          [tooltip]="server.state ? server.status.description + ' - Blocked:' + server?.state?.description : server.status.description"></i>
        <i class="icon icon-warning text-warning" aria-hidden="true"
          *ngIf="server.status && (server.status.code === serverStatusCode.INITIALIZATION || server.status.code === serverStatusCode.QUIESCENT ||  server.status.code === serverStatusCode.BACKUP || server.status.code === serverStatusCode.SINGLE_USER) "
          [tooltip]="server.state ? server.status.description + ' - Blocked:' + server?.state?.description  : server.status.description"></i>
        <i class="icon icon-times-circle text-danger" aria-hidden="true"
          *ngIf="server.status && ( server.status.code === serverStatusCode.OFFLINE || server.status.code === 255 ||  server.status.code === serverStatusCode.SHUTDOWN || server.status.code === serverStatusCode.ABORT) "
          [tooltip]="server.state ? server.status.description + ' - Blocked:' + server?.state?.description  : server.status.description"></i>
        <i class="icon icon-question" aria-hidden="true" *ngIf="!server.status || server.status.code === serverStatusCode.UNKNOWN"
          tooltip="Status unknown. Monitoring agent not connected."></i>
        <span class="ml-1">SERVER STATUS</span>
      </div>
      <div class="flex-1 text-center item-stat">
        <i class="icon icon-check-circle text-success" aria-hidden="true" *ngIf="server.agent && server.agent.online"
          tooltip="Online"></i>
        <i class="icon icon-times-circle text-danger" aria-hidden="true" *ngIf="!server.agent || !server.agent.online"
          tooltip="Offline"></i>
        <span class="ml-1">AGENT STATUS</span>
      </div>
    </div>
    <div class="item-stats">
      <div class="flex-1 text-center item-stat">
        <i *ngIf="server.unreadIncidents > 0" class="icon icon-warning text-warning mr-1"></i>
        <a class="color-black" [routerLink]="['/dashboard/servers/', server.id, 'incidents']" (click)="$event.stopPropagation()">
          <span class="item-stat-number">{{server.unreadIncidents}}</span>
          {{server.unreadIncidents === 1 ? 'INCIDENT' : 'INCIDENTS'}}
        </a>
      </div>
    </div>
  </div>
  <ng-template #serverLocked>
    <div class="card-body d-flex align-items-center color-light-gray-40 p-2">
      <div class="flex-1 text-center">
        <i class="icon icon-2x icon-lock"></i>
      </div>
    </div>
  </ng-template>
</div>

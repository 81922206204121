/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { InformixTreeItem } from '../informixTreeItem';
import { AlertExternalCondition, AlertOperation } from './alerting-profile';
import { OperandEvent } from './operations/abstract-alert-operand';
import { AlertOperator } from './operations/alert-operator';
import { alertOperatorTypes } from './operations/alert-operator-types';

@Component({
  selector: 'app-alert-operation',
  templateUrl: 'alert-operation.html'
})
export class AlertOperationComponent implements OnInit {
  @Input() operation: AlertOperation;
  @Input() owner: InformixTreeItem;
  @Input() externalCondtion: AlertExternalCondition;

  @Output() eventUpdate = new EventEmitter<any>();

  private operators: AlertOperator[] = null;

  operatorControl: UntypedFormControl = new UntypedFormControl();

  leftOperandEvent: OperandEvent;
  private rightOperandEvent: OperandEvent;
  private alertTriggerType: string = null;

  rightOperandDataTypeFilter: string = null;

  ngOnInit() {
    this.operatorControl.valueChanges.subscribe((value: AlertOperator) => {
      this.rightOperandDataTypeFilter = value.getInputType(1);
      this.onUpdate();
    });
  }

  updateLeftOperand(event: OperandEvent) {
    if (!this.leftOperandEvent || this.leftOperandEvent.dataType !== event.dataType) {
      this.operators = this.getOperatorsForDataType(event.dataType);
      if (this.operators) {
        let selectedOperator: AlertOperator = null;
        if (this.operation && this.operation.op) {
          selectedOperator = this.operators.find(op => op.type.value === this.operation.op);
        }

        this.operatorControl.setValue(selectedOperator || this.operators[0]);
      }
    }

    this.leftOperandEvent = event;
    if (!this.leftOperandEvent.triggers) {
      this.alertTriggerType = null;
    } else {
      this.alertTriggerType = this.leftOperandEvent.triggers.indexOf('event') > -1 ? 'event' : 'interval';
    }
    this.onUpdate();
  }

  updateRightOperand(event: OperandEvent) {
    this.rightOperandEvent = event;
    this.onUpdate();
  }

  onUpdate() {
    if (!this.leftOperandEvent || !this.rightOperandEvent) {
      return;
    }

    this.eventUpdate.emit({
      operation: {
        a: this.leftOperandEvent.operand,
        b: this.rightOperandEvent.operand,
        op: (this.operatorControl.value as AlertOperator).type.value
      },
      valid: this.leftOperandEvent.valid && this.rightOperandEvent.valid,
      trigger: this.alertTriggerType,
      externalCondtion: this.leftOperandEvent.externalCondition
    });
  }

  private getOperatorsForDataType(dataType: string): AlertOperator[] {
    if (!dataType) {
      return null;
    }

    const operators: AlertOperator[] = [];
    alertOperatorTypes.forEach(type => {
      const genericType = type.genericTypes ? type.genericTypes[0] : null;
      const operator = new AlertOperator(type);
      if (genericType) {
        operator.setGenericType(genericType, dataType);
      }

      if (operator.setTypeIfValid(dataType)) {
        operators.push(operator);
      }
    });

    return operators.length > 0 ? operators : null;
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';

interface SizeUnit {
  bytes: bigint;
  label: string;
}
const sizes: { [key: string]: SizeUnit } = {};
sizes.kb = { bytes: BigInt(1), label: 'KB' };
sizes.mb = { bytes: sizes.kb.bytes * BigInt(1024), label: 'MB'};
sizes.gb = { bytes: sizes.mb.bytes * BigInt(1024), label: 'GB'};
sizes.tb = { bytes: sizes.gb.bytes * BigInt(1024), label: 'TB'};
sizes.pb = { bytes: sizes.tb.bytes * BigInt(1024), label: 'PB'};
sizes.eb = { bytes: sizes.pb.bytes * BigInt(1024), label: 'EB'};

/* Note: the VALUE of this component is expressed in KB. */

const SIZE_UNITS: SizeUnit[] = [sizes.kb, sizes.mb, sizes.gb, sizes.tb, sizes.pb, sizes.eb];

@Component({
  selector: 'app-input-size',
  template: `
	<form class="form-inline" [formGroup]="formGroup">
		<input type="number" class="form-control" [ngClass]="formClass" min="0" formControlName="value">
		<select class="form-control ml-2" [ngClass]="formClass" formControlName="unit">
			<option *ngFor="let unit of sizeUnits" [ngValue]="unit">{{unit.label}}</option>
		</select>
	</form>
	`,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SizeInputComponent), multi: true },
    { provide: NG_VALIDATORS, useExisting: SizeInputComponent, multi: true }
  ]
})
export class SizeInputComponent implements OnInit, ControlValueAccessor, Validator {

  @Input() minUnit: number;
  @Input() maxUnit: number;
  @Input() formClass: string;

  private onChangeFunction: any;

  sizeUnits = SIZE_UNITS;
  formGroup: UntypedFormGroup;
  disabled = false;
  precision: number;

  constructor() {
    this.precision = 2;
  }

  ngOnInit() {
    this.sizeUnits = SIZE_UNITS;
    if (this.minUnit) {
      this.sizeUnits = this.sizeUnits.filter((entry) => entry.bytes >= this.minUnit);
    }
    if (this.maxUnit) {
      this.sizeUnits = this.sizeUnits.filter((entry) => entry.bytes <= this.maxUnit);
    }
    this.formGroup = new UntypedFormGroup({
      value: new UntypedFormControl('0', [Validators.required, Validators.pattern('[1-9][0-9]*')]),
      unit: new UntypedFormControl(this.sizeUnits[0], Validators.required)
    });

    this.formGroup.valueChanges.subscribe(() => {
      const controls = this.formGroup.controls;
      let value = controls['value'].value;
      let valueInKB: string;

      if (!isNaN(Number(value))) {
        valueInKB = this.convertToKb(value, controls['unit'].value.bytes);
      } else {
        valueInKB = null;
      }

      if (this.onChangeFunction) {
        this.onChangeFunction(valueInKB);
      }
    });
  }

  /**
   * Converts the given value to kilobytes based on the provided unit value.
   *
   * @param value - The value to be converted.
   * @param unitValue - The unit value used for conversion.
   * @returns The converted value in kilobytes.
   */
  private convertToKb(value: any, unitValue: bigint): string {
    let integerPart = Math.floor(Number(value));
    let fractionalPart = Number(value) - integerPart;

    let integerPartInKB = BigInt(integerPart) * BigInt(Math.round(Number(unitValue)));
    let fractionalPartInKB = fractionalPart * Number(unitValue);
    let totalValueInKB: bigint = BigInt(Math.round(Number(integerPartInKB))) + BigInt(Math.round(fractionalPartInKB));
    return totalValueInKB.toString();
  }

  writeValue(value: any) {
    if (typeof value === 'number') {
      if (value === 0) {
        this.formGroup.controls['value'].setValue(0);
        this.formGroup.controls['unit'].setValue(this.sizeUnits[0]);
      } else {
        let size: number = value; let unit = null;
        for (let i = this.sizeUnits.length - 1; i >= 0; i--) {
          if (value / Number(this.sizeUnits[i].bytes) >= 1) {
            size = value / Number(this.sizeUnits[i].bytes);
            unit = this.sizeUnits[i];
            break;
          }
        }
        this.formGroup.controls['value'].setValue(Math.round(size * Math.pow(10, this.precision)) / Math.pow(10, this.precision));
        this.formGroup.controls['unit'].setValue(unit);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeFunction = fn;
  }

  registerOnTouched(fn: any) {

  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.formGroup.disable();
    } else {
      this.formGroup.enable();
    }
  }

  validate(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    }
    return null;
  }
}

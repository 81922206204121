/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { InformixTreeItem } from '../informixTreeItem';
import { AlertExternalCondition, AlertOperand } from './alerting-profile';
import { AbstractAlertOperand } from './operations/abstract-alert-operand';
import { AlertOperandType, alertOperandTypes } from './operations/alert-operand-types';
import { ConstantEnumOperandComponent } from './operations/const/constant-enum-operand.component';
import { ConstantOperands } from './operations/const/constant-operands';

@Component({
  selector: 'app-alert-operand',
  templateUrl: 'alert-operand.html'
})
export class AlertOperandComponent implements OnChanges, AfterViewInit, OnDestroy {

  @Input() operand: AlertOperand;
  @Input() owner: InformixTreeItem;
  @Input() dataTypeFilter: string = null;
  @Input() unit: string = null;
  @Input() forceConstant = false;
  @Input() externalCondtion: AlertExternalCondition;

  @Output() eventUpdate = new EventEmitter<any>();

  operandType: AlertOperandType = null;

  private alertOperandTypes = alertOperandTypes;
  private alertOperandTypeMap: { [key: string]: AlertOperandType } = {};
  alertOperandTypesFiltered = this.alertOperandTypes;
  private constOperandTypeMap: { [key: string]: AlertOperandType } = {};

  @ViewChild('operandContainer', { read: ViewContainerRef }) operandContainer: ViewContainerRef;
  private operandComponentRef: ComponentRef<AbstractAlertOperand> = null;
  private operandUpdateSub: Subscription;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    this.alertOperandTypes.forEach(type => {
      this.alertOperandTypeMap[type.type] = type;
    });

    ConstantOperands.getTypes().forEach(type => {
      this.constOperandTypeMap[type.outputType] = type;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataTypeFilter) {
      if (this.dataTypeFilter) {
        this.alertOperandTypesFiltered = this.forceConstant ? [] : this.alertOperandTypes.filter(type =>
          (!type.outputType || type.outputType === this.dataTypeFilter));

        let constOperand: AlertOperandType = null;

        constOperand = this.getEnumOperandType(this.dataTypeFilter);
        if (!constOperand) {
          constOperand = this.constOperandTypeMap[this.dataTypeFilter];
        }

        if (constOperand) {
          this.alertOperandTypesFiltered.unshift(constOperand);
        }
      } else {
        this.alertOperandTypesFiltered = this.alertOperandTypes;
      }

      let operandType: AlertOperandType = null;
      if (this.operandType) {
        operandType = this.alertOperandTypesFiltered.find(type => type.type === this.operandType.type);
      }

      if (!operandType) {
        operandType = this.alertOperandTypesFiltered[0];
      }

      if (operandType === this.operandType && this.operandComponentRef) {
        this.operandComponentRef.instance.setDataTypeFilter(this.dataTypeFilter);
      } else {
        this.setOperandType(operandType);
      }
    }

    if (changes.operand && this.operand && this.alertOperandTypesFiltered) {
      let operandType = this.alertOperandTypesFiltered.find(type => type.type === this.operand.type);
      if (!operandType) {
        operandType = this.alertOperandTypesFiltered[0];
      }

      this.setOperandType(operandType);
    }

    if (changes.unit && this.operandComponentRef) {
      this.operandComponentRef.instance.setUnit(this.unit);
    }
  }

  ngAfterViewInit() {
    if (this.operandType) {
      window.setTimeout(() => {
        this.createOperandComponent();
      }, 0);
    }
  }

  ngOnDestroy() {
    if (this.operandUpdateSub) {
      this.operandUpdateSub.unsubscribe();
    }
  }

  private createOperandComponent() {
    if (!this.operandContainer) {
      return;
    }

    this.operandContainer.clear();
    if (this.operandType) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.operandType.component);
      this.operandComponentRef = this.operandContainer.createComponent(factory);
      const c = this.operandComponentRef.instance;
      c.owner = this.owner;
      c.setExternalCondition(this.externalCondtion);
      c.setType(this.operandType);
      c.setOperand(this.operand);
      c.setDataTypeFilter(this.dataTypeFilter);
      c.setUnit(this.unit);

      if (this.operandUpdateSub) {
        this.operandUpdateSub.unsubscribe();
      }
      this.operandUpdateSub = c.eventUpdate.subscribe((value: any) => {
        this.eventUpdate.emit(value);
      });
    }
  }

  private getEnumOperandType(type: string): AlertOperandType {
    const constEnum = ConstantOperands.getEnumForType(type);
    if (constEnum) {
      const opType: AlertOperandType = {
        type: 'const',
        component: ConstantEnumOperandComponent,
        description: constEnum.name,
        outputType: type,
        triggers: null
      };

      if (type.endsWith('[]')) {
        opType.description += ' list';
      }

      return opType;
    }

    return null;
  }

  setOperandType(type: AlertOperandType) {
    if (this.operandType !== type) {
      this.operandType = type;
      this.createOperandComponent();
    }
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InformixServer } from '../informixServer';
import { InformixServerStorageService } from './informixServerStorage.service';
import { ServerSpace } from './serverSpace';

@Component({
  selector: 'app-expand-space',
  templateUrl: 'expand-space.html'
})
export class ExpandSpaceComponent implements OnInit {

  @Input() server: InformixServer = null;

  _selectedSpace: ServerSpace;
  formGroup: UntypedFormGroup;
  unitOptions: string[] = ['%', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];

  @Output() success: EventEmitter<any> = new EventEmitter();
  @Output() error: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(private informixServerStorageService: InformixServerStorageService) {
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      expandSize: new UntypedFormControl(10, [this.validateExpandSize.bind(this)]),
      expandSizeUnit: new UntypedFormControl('%', [Validators.required])
    });

    this.formGroup.controls['expandSizeUnit'].valueChanges.subscribe(value => {
      this.formGroup.controls['expandSize'].updateValueAndValidity();
    });
  }

  @Input()
  set selectedSpace(selectedSpace: ServerSpace) {
    this._selectedSpace = selectedSpace;
    this.resetForm();
  }

  validateExpandSize(c: AbstractControl): { [key: string]: any } {
    if (c.value === null) {
      return { required: true };
    }
    if (this.formGroup && this.formGroup.controls['expandSizeUnit'].value === '%') {
      if (c.value >= 0 && c.value <= 100) {
        return null;
      } else {
        return { customError: 'Percentage values must be between 0 and 100' };
      }
    }
    return null;
  }

  public resetForm(): void {
    if (this._selectedSpace && this._selectedSpace.extend_size > 0) {
      this.formGroup.controls['expandSize'].setValue(this._selectedSpace.extend_size);
      if (this._selectedSpace.extend_size <= 100) {
        this.formGroup.controls['expandSizeUnit'].setValue('%');
      } else {
        this.formGroup.controls['expandSizeUnit'].setValue('KB');
      }
    }
  }

  public doExpandSpace() {
    let expandSizeString: string = this.formGroup.value['expandSize'];
    if (this.formGroup.value['expandSizeUnit'] !== '%') {
      expandSizeString += this.formGroup.value['expandSizeUnit'];
    }
    this.informixServerStorageService.expandSpace(this.server.id,
      this._selectedSpace.name, expandSizeString).then(result => {
        if (result.return_code === null) {
          this.error.emit('Error: ' + result);
        } else if (result.return_code === 0) {
          this.success.emit(result.result_message);
        } else {
          this.error.emit(result.result_message);
        }
      }).catch(err => {
        this.error.emit(err);
      });
    this.doClose();
  }

  public doClose() {
    this.close.emit();
    this.resetForm();
  }

}

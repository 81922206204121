/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppSettings } from '../app.settings';
import { UserService } from '../shared/user/user.service';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserSettingsService } from '../dashboard/user-settings/user-settings.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PasswordPolicy } from '../shared/user/passwordPolicy';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login-cmp',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  productName: string;
  copyright: string;
  formGroup: UntypedFormGroup;
  isLoggingIn = false;
  logInError: string = null;
  language = AppSettings.DEFAULT_LANGUAGE;
  passwordExpired = '';
  username: AbstractControl;
  password: AbstractControl;
  newPassword: AbstractControl;
  confirmPassword: AbstractControl;
  loginForm: UntypedFormGroup;
  showConfirmPassword: Boolean = false;
  showNewPassword: Boolean = false;
  showPassword: Boolean = false;
  isFirstSignIn: Boolean = false;
  isChangePassword: Boolean = false;
  passwordPolicy: PasswordPolicy = null;
  loginPage = environment.loginPage;
  logo = environment.logo + environment.logoExtention;

  constructor(
    private userService: UserService,
    private titleService: Title,
    private router: Router,
    private translate: TranslateService,
    private userSettingsService: UserSettingsService
  ) {
    this.productName = AppSettings.PRODUCT_NAME;
    this.copyright = AppSettings.COPYRIGHT;
    this.titleService.setTitle(this.productName);
    this.translate.setDefaultLang(this.language);
    this.translate.use(this.language);
  }

  get formControls() {
    return this.formGroup.controls;
  }

  ngOnInit() {
    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl(null, [Validators.required, Validators.pattern(/[\S]/)]),
      password: new UntypedFormControl(null, [Validators.required]),
      newPassword: new UntypedFormControl(null),
      confirmPassword: new UntypedFormControl(null)
    });
    this.username = this.formGroup.controls['username'];
    this.password = this.formGroup.controls['password'];
    this.newPassword = this.formGroup.controls['newPassword'];
    this.confirmPassword = this.formGroup.controls['confirmPassword'];

    if (this.userService.hasUserTimedOut()) {
      this.logInError = 'Your session timed out';
    }
  }

  validateNewPassword(): ValidatorFn {
    return (): { customError: string } | null => {
      if (this.password.value && this.newPassword) {
        if (this.password.value === this.newPassword.value) {
          const errors = { customError: 'New password must be different from the old one' };
          this.newPassword.setErrors(errors);
          return errors;
        }
        return null;
      }
    };
  }

  passwordMatchValidator(controlType: string): ValidatorFn {
    return (): { customError: string } | null => {
      if (this.newPassword.value === this.confirmPassword.value) {
        this.confirmPassword.setErrors(null);
        return null;
      } else {
        const errors = { customError: 'Passwords doesn\'t match' };
        if (controlType === 'newPassword') {
          this.confirmPassword.setErrors(errors);
          return null;
        } else {
          return errors;
        }
      }
    };
  }

  /**
   *
   * @param password - on first sign-in password will be default
   * @param newPassword
   */
  loginService(password?: string, newPassword?: string) {
    let passwordToLogin;
    if (password) {
      passwordToLogin = password;
    } else {
      passwordToLogin = (this.passwordExpired !== 'passwordExpired') ? this.password.value : this.newPassword.value;
    }
    this.userService.logIn(this.username.value.trim(), passwordToLogin, newPassword).then((user: any) => {
      if (this.language !== user.settings.language) {
        this.userSettingsService.changeLanguage(this.language);
      }
      this.userService.setDaysToExpire(user.daysLeftForPasswordToExpire, this.passwordExpired);
      this.router.navigateByUrl(this.userService.getLoginRedirectUrl());
    }).catch((err: HttpErrorResponse) => {
      this.logInError = err.error.err;
      if (!err.status) {
        this.logInError = 'Server is down';
      } else if (err.status === 401) {
        if (err.error && err.error.id && err.error.id.isFirstSignIn) {
          // set flag isFirstSignIn true on user's first sign-in
          this.isFirstSignIn = err.error.id.isFirstSignIn;
          this.isLoggingIn = false;
          if (this.isChangePassword) {
            this.logInError = 'Password does not meet criteria.';
          } else {
            this.logInError = null;
            this.passwordPolicy = new PasswordPolicy(err.error.id.passwordPolicy);
            this.isChangePassword = true;
          }
        } else if (err.error && err.error.err.indexOf('password has expired') !== -1) {
          this.passwordExpired = 'passwordExpired';
          this.password.setValidators([Validators.required, this.validateNewPassword()]);
          this.newPassword.setValidators([Validators.required, this.passwordMatchValidator('newPassword'),
          this.validateNewPassword()]);
          this.confirmPassword.setValidators([this.passwordMatchValidator('confirmPassword')]);
          this.formGroup.updateValueAndValidity();
          this.username.disable();
          this.password.disable();
        }
      }
      this.isLoggingIn = false;
    });
  }

  logIn() {
    if (this.isLoggingIn) {
      return;
    }
    this.logInError = null;
    if (this.isFirstSignIn) {
      // on first sign-in send default password to API
      const password = 'admin';
      const userName = 'admin';
      this.username.setValue(userName);
      this.loginService(password, this.confirmPassword.value);
    } else {
      this.isLoggingIn = true;
      if (this.passwordExpired !== 'passwordExpired') {
        this.loginService();
      } else {
        // set new password - change password
        this.userSettingsService.changePassword(this.password.value, this.newPassword.value, this.username.value).then(() => {
          this.loginService();
        }).catch((err: HttpErrorResponse) => {
          if (!err.status) {
            this.logInError = 'Server is down';
          } else if (err.status === 403) {
            this.logInError = 'The old password you entered was incorrect';
          } else {
            let msg = 'There was a problem updating your password';
            if (err.status === 400 && err.error.err) {
              msg = err.error.err;
            }
            this.logInError = msg;
          }
          this.isLoggingIn = false;
        });
      }
    }
  }

  toggleShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.password.value === '') {
        this.showPassword = false;
      }
      return;
    }
    this.showPassword = !this.showPassword;
  }

  toggleNewPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.newPassword.value === '') {
        this.showNewPassword = false;
      }
      return;
    }
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPassShow(flag: boolean = false) {
    if(flag) {
      if(this.formGroup.controls.confirmPassword.value === '') {
        this.showConfirmPassword = false;
      }
      return;
    }
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  /**
   * change admin password on first-sign
   */
  changePassword() {
    if(this.newPassword.value !== this.confirmPassword.value){
      this.logInError = `Passwords doesn't match`;
      return;
    } else {
      this.logIn();
    }
  }
}

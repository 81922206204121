/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2019, 2024. All Rights Reserved.
 *******************************************************************************/
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { InformixServer } from '../../informixServer';
import { TranslateService } from '@ngx-translate/core';
import { TablesIndexesList, Fragment } from './tables-indexes-page.model';
import { TablesIndexesService } from './tables-indexes-page.service';
import { SchemaService } from '../../schema/schema.service';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { InformixServerStorageService } from '../informixServerStorage.service';
import { DataTableRow, DataTableComponent } from '../../../../shared/data-table/data-table.component';
import { OptimizeSpaceModalComponent } from './optimize-space-modal.component';
import { InformixDatabase } from '../../schema/informix-database';
import { InformixServerService } from '../../informixServer.service';

@Component({
  selector: 'app-tables-indexes',
  templateUrl: 'tables-indexes.html',
  styleUrls: ['tables-indexes-page.scss']
})
export class TablesIndexesComponent implements OnInit {

  @Input() server: InformixServer = null;

  tablesIndexesList: TablesIndexesList[];
  filteredTablesIndexesList: TablesIndexesList[];
  previousDatabases: InformixDatabase[] = null;
  databases: InformixDatabase[] = null;
  selectedDB = '';
  dbspaces: Array<{ name: string; dbsnum: number }> = [];
  selectedDBspace = { name: '', dbsnum: 0 };
  isCatalog = false;
  tableFilter = 'all';
  optimizeStart: any;
  showSpinner: boolean;
  selectedCount = 0;
  rowExpandables = false;

  isVNextVersion: Boolean = false;
  apiError: string;
  isLoading: Boolean = false;
  searchValue = '';
  @ViewChild('optimizeSpaceModal') optimizeSpaceModal: OptimizeSpaceModalComponent;
  @ViewChild('tablesAndIndexesTable') tablesAndIndexesTable: DataTableComponent;

  constructor(private translate: TranslateService,
    private tablesIndexesService: TablesIndexesService,
    private notificationsService: NotificationsService,
    private restdb: SchemaService,
    private serverStorageService: InformixServerStorageService,
    private serverService: InformixServerService) { }

  ngOnInit() {
    this.getDbspaceList();
  }

  private getDbspaceList() {
    this.apiError = null;
    this.isLoading = true;
    this.serverStorageService.getServerDspaces(this.server.id).subscribe(dbspaces => {
      this.dbspaces.push({ name: 'All', dbsnum: 0 });
      this.dbspaces = this.dbspaces.concat(dbspaces.map(({ name, dbsnum }) => ({ name, dbsnum })));
      if (this.dbspaces.map(item => item.name).indexOf(this.selectedDBspace.name) === -1) {
        if (this.dbspaces.length > 1) {
          this.selectedDBspace.name = this.dbspaces[1].name;
          this.selectedDBspace.dbsnum = this.dbspaces[1].dbsnum;
        } else {
          this.selectedDBspace.name = this.dbspaces[0].name;
          this.selectedDBspace.dbsnum = this.dbspaces[0].dbsnum;
        }
      }
      this.getDatabaseList();
    }, err => {
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
      this.isLoading = false;
    });
  }

  private getDatabaseList() {
    const all = new InformixDatabase(this.server, { name: 'All' });
    this.restdb.getDatabases(this.server).subscribe(databases => {
      this.previousDatabases = this.databases = [all, ...databases];
      if (this.databases.length > 1) {
        this.selectedDB = this.databases[1].name;
      }
      this.getTablesIndexes();
    }, err => {
      if(err.error && err.error.err) {
        this.apiError = err.error.err;
      }
      this.isLoading = false;
    });
  }

  changeTableFilter(filter: string) {
    this.tableFilter = filter;
    this.updateTable();
  }

  changeDatabase(event: string) {
    this.selectedDB = event;
    this.updateTable();
  }

  changeDbspace(event: string) {
    let newSelected: any;
    if (event === 'All') {
      newSelected = { name: 'All', dbsnum: 0 };
      this.selectedDBspace = JSON.parse(JSON.stringify(newSelected));
      this.databases = this.previousDatabases;
    } else {
      newSelected = this.dbspaces.filter(i => i.name === event);
      this.selectedDBspace = JSON.parse(JSON.stringify(newSelected[0]));
    }
    this.updateTable();
  }

  updateTable() {
    this.filteredTablesIndexesList = null;
    this.getTablesIndexes();
  }

  getTablesIndexesRefreshed(data: any) {
    this.filteredTablesIndexesList = this.tablesIndexesList = data;
    this.showSpinner = false;
    this.rowExpandables = this.filteredTablesIndexesList.filter(row => row.fragments && row.fragments.length > 1).length > 0;
    this.filteredTablesIndexesList = this.filteredTablesIndexesList.filter(filterBy => {
      if (this.tableFilter === 'tables') {
        return filterBy.type === 'T';
      } else if (this.tableFilter === 'indexes') {
        return filterBy.type === 'I';
      } else if (this.tableFilter === 'compressed') {
        return filterBy.compressed === 'yes' || filterBy.compressed === 'some';
      } else if (this.tableFilter === 'noncompressed') {
        return filterBy.uncompressed === 'yes' || filterBy.uncompressed === 'some';
      } else {
        return true;
      }
    });
  }

  private getTablesIndexes() {
    this.showSpinner = true;
    this.selectedCount = 0;
    this.apiError = null;
    this.tablesIndexesService.getTablesIndexes(this.server, this.selectedDB, this.isCatalog, this.selectedDBspace.dbsnum)
      .subscribe((data: TablesIndexesList[]) => {
        this.calculateEstimatesForFragmentedTables(data);
        this.getTablesIndexesRefreshed(data);
        this.isLoading = false;
      }, err => {
        this.showSpinner = false;
        this.translate.get('tablesIndexes.errorMsg.getTableIndexesError', { suffix: err.error ? err.error.err : err })
          .subscribe((text: string) => {
            if(text) {
              this.apiError = text;
            }
          });
        this.isLoading = false;
      });
  }

  includeCatalog(isCatalog: boolean) {
    this.isCatalog = isCatalog;
    this.updateTable();
  }

  getSelectedRows(): any[] {
    const rowsSelected = [];

    this.filteredTablesIndexesList.forEach(table => {
      if (table.isChecked) {
        rowsSelected.push(table);
      } else {
        table.fragments.forEach(fragment => {
          if (fragment.isChecked) {
            rowsSelected.push(fragment);
          }
        });
      }
    });

    return rowsSelected;
  }

  refreshDataForSelectedRows() {
    this.refreshData(this.getSelectedRows());
  }

  refreshDataForRow(row: any) {
    // When updating information, preserve the current search filter if there is any text in the search input.
    if (this.tablesAndIndexesTable && this.tablesAndIndexesTable.searchText !== '') {
      this.searchValue = this.tablesAndIndexesTable.searchText;
    } else {
      this.searchValue = '';
    }
    this.refreshData([row]);
  }

  refreshData(data: any[]) {
    this.selectedCount = 0;
    this.tablesIndexesService.updateInfo(this.server, this.selectedDB, data, this.isCatalog).subscribe((res: TablesIndexesList[]) => {
      this.updateTable();
      this.translate.get('tablesIndexes.successMsg.updateInformation')
        .subscribe((text: string) => {
          this.notificationsService.pushSuccessNotification(text);
        });
    }, err => {
      this.translate.get('tablesIndexes.errorMsg.updateInfoError', { suffix: err.error ? err.error.err : err })
        .subscribe((text: string) => {
          if(text) {
            this.apiError = text;
          }
        });
    });
  }

  tablesIndexesDataMap(tablesIndexes: TablesIndexesList): DataTableRow {
    return {
      data: tablesIndexes,
      selected: tablesIndexes.isChecked,
      disabled: false,
      expanded: tablesIndexes.fragments.length > 1 ? false : null
    };
  }

  onTableSelected(event: DataTableRow) {
    const table = event.data as TablesIndexesList;
    this.selectedCount += event.selected ? 1 : -1;
    table.isChecked = event.selected;
    table.fragments.forEach(frag => {
      if (frag.isChecked !== event.selected) {
        frag.isChecked = event.selected;
      }
    });
    setTimeout(() => table.fragments = table.fragments.slice(), 0);
  }

  onFragmentsSelected(event: Fragment[], parentRow: DataTableRow) {
    const allFragmentsSelected = event.length >= parentRow.data.fragments.length;
    if (allFragmentsSelected !== parentRow.selected) {
      if (!allFragmentsSelected) {
        // Remember which fragments were checked
        const checkedFragments = parentRow.data.fragments.map(v => v.isChecked);

        // ... because deselecting the parent table row will deselect all fragments
        this.tablesAndIndexesTable.setRowSelected(parentRow, false);

        // Re-select the fragments
        parentRow.data.fragments.forEach((v, i) => {
          if (checkedFragments[i]) {
            v.isChecked = true;
            this.selectedCount++;
          }
        });

        // Refresh table data
        parentRow.data.fragments = parentRow.data.fragments.slice();
      } else {
        this.tablesAndIndexesTable.setRowSelected(parentRow, true);
      }
    }
  }

  onFragmentSelected(row: DataTableRow) {
    (row.data as Fragment).isChecked = row.selected;
    this.selectedCount += row.selected ? 1 : -1;
  }

  calculateEstimatesForFragmentedTables(tables: TablesIndexesList[]) {
    for (let i = 0; i < tables.length; i++) {
      if (tables[i].fragments && tables[i].fragments.length > 1) {
        let totalEstimatedPages = 0;
        let nullEstimates = 0;
        tables[i].fragments.forEach(frag => {
          if (frag.nrows >= 2000) {
            if (frag.estimate) {
              totalEstimatedPages += (frag.estimate.change / 100) * frag.npused;
            } else {
              nullEstimates++;
            }
          }
        });
        if (nullEstimates === 0) {
          // This full table estimate calculation is only valid if all fragments
          // with > 2000 rows have an estimate. If one or more fragments have a null
          // estimate, we won't show a full table estimate.
          tables[i].estimate = { change: (totalEstimatedPages / tables[i].npused) * 100 };
        }
      }
    }
  }

  optimizeSpacesModal(type: string, data?: any) {
    let selectedObjs = [];
    if (type === 'all') {
      selectedObjs = this.getSelectedRows();
    } else {
      selectedObjs.push(data);
    }
    this.optimizeSpaceModal.show(this.server, this.selectedDB, selectedObjs, this.isCatalog);
  }

  getServerInfo() {
    this.serverService.getServerStatus(this.server.id).subscribe(serverInfo => {
      // this.isVNextVersion = serverInfo && serverInfo.version.indexOf('15.') > -1;
    });
  }
}

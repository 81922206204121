<div class="container-fluid page-container">
  <div class="row">
    <div class="col">
      <h3>User Management</h3>
    </div>
  </div>

  <div class="row">
    <div class="col">
      Use this page to create new users, edit user permissions, lock and unlock users, and reset user passwords.
    </div>
    <div class="col-md-auto">
      <button routerLink="new" class="btn btn-primary"><i class="icon icon-plus"></i> Create User</button>
    </div>
  </div>

  <app-data-table *ngIf="users" [data]="users" sortBy="name">
    <app-data-table-expand [expandCondition]="userRowExpanded.bind(this)">
      <ng-template appTableExpand let-user="row">
        <div class="inline-edit">
          <form style="max-width: 400px">
            <app-change-password-form [formControl]="passwordControl"></app-change-password-form>
            <button type="submit" class="btn btn-primary mt-2" [disabled]="passwordControl.invalid" (click)="updateUserPassword(user)">
              Save
            </button>
          </form>
        </div>
      </ng-template>
    </app-data-table-expand>
    <app-data-table-column title="Name" property="name" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-user="row">
        {{user.name}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Account Status" property="status">
      <ng-template appTableCell let-user="row">
        <label class="switch" [tooltip]="'Click here to ' + ((user.locked)? 'unlock' : 'lock') + ' the account'">
          <input type="checkbox" (change)="updateLockStatus(user,$event)" [checked]="!user.locked">
          <span class="slider round">
            <i class="icon" [ngClass]="'icon-' + ( user.locked ? '' : 'un' ) + 'lock'"></i>
          </span>
        </label>
        <span *ngIf="user.locked" class="locked">Locked</span>
        <span *ngIf="!user.locked" class="unlocked">Unlocked</span>
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Expiration Date" property="role_expiry" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-user="row">
        {{(user.roleExpiry === 0)? 'Never' : user.roleExpiry | date:'y-MM-dd'}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column title="Permissions" property="name" [sortable]="true" [searchable]="true">
      <ng-template appTableCell let-user="row">
        {{user.roleExpiry === 0 ? 'NA' : (user.isExpired ? 'Permissions revoked due to expiration date' : 'Permissions are valid')}}
      </ng-template>
    </app-data-table-column>
    <app-data-table-column class="text-right">
      <ng-template appTableCell let-user="row">
        <button class="btn btn-sm btn-icon" routerLink="edit/{{user.id}}" tooltip="Edit"><i class="icon icon-edit"></i></button>
        <ng-template [ngIf]="isCurrentUser(user)">
          <button class="btn btn-sm btn-icon" tooltip="Reset Password" routerLink="../../user-settings/change-password" disabled><i
              class="icon icon-password-key"></i></button>
          <div tooltip="Cannot remove yourself" placement="left" style="display: inline-block">
            <button class="btn btn-sm btn-icon" disabled><i class="icon icon-trash"></i></button>
          </div>
        </ng-template>
        <ng-template [ngIf]="!isCurrentUser(user)">
          <button class="btn btn-sm btn-icon" (click)="resetUserPassword(user)" tooltip="Reset Password"><i class="icon icon-password-key"></i></button>
          <button *ngIf="!isCurrentUser(user)" class="btn btn-sm btn-icon" (click)="removeUser(user)" tooltip="Remove"><i
              class="icon icon-trash"></i></button>
        </ng-template>
      </ng-template>
    </app-data-table-column>
  </app-data-table>
</div>

<div bsModal #confirmModal="bs-modal" class="modal fade" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Confirm</h4>
        <button type="button" class="close" (click)="confirmModal.hide()">
          <i class="icon icon-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="d-flex align-items-center">
          <div class="p-2">
            <i class="icon icon-2x icon-warning text-warning"></i>
          </div>
          {{confirmModalMessage}}
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="resolveConfirmModal(false)">No</button>
        <button type="button" class="btn btn-primary" (click)="resolveConfirmModal(true)">Yes</button>
      </div>
    </div>
  </div>
</div>

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from '../../../shared/modal/confirmation-dialog.service';
import { NotificationsService } from '../../../shared/notifications/notifications.service';
import { User } from '../../../shared/user/user';
import { UserService } from '../../../shared/user/user.service';
import { UserPermission } from '../../../shared/user/userPermission';
import { GROUP_ROOT_ID, InformixServerGroup } from '../../groups/informixServerGroup';
import { InformixServerGroupService } from '../../groups/informixServerGroup.service';
import { informixServerPermissions, InformixTreeItem, InformixServerPermission } from '../../informixTreeItem';
import { UsersService } from './users.service';
import { TranslateService } from '@ngx-translate/core';


interface PermissionsRow {
  item: InformixTreeItem;
  formGroup: UntypedFormGroup;
  isGroup: boolean;
  expanded: boolean;
  children: PermissionsRow[];
  parent: PermissionsRow;
}

@Component({
  selector: 'app-user-form',
  templateUrl: 'userForm.html',
  styleUrls: ['userForm.scss']
})

export class UserFormComponent implements OnInit {

  user: User;
  isLastAdmin = false;

  permissions = [
    { name: 'System Administrator', permission: UserPermission.SUPERADMIN }
  ];

  serverPermissions = informixServerPermissions;
  currentUser = null;
  allUsers = null;
  isNewUser = true;
  isEditMode = false;
  isPermissionsDisabled = false;
  rootGroup: InformixServerGroup = null;
  formGroup: UntypedFormGroup = null;

  rootPermissionsRow: PermissionsRow = null;
  allPermissionsRows: PermissionsRow[] = null;
  serverPermissionsDisabled = false;
  currentDate: Date;
  tomorrowsDate: Date;

  constructor(
    private userService: UserService,
    private usersService: UsersService,
    private groupService: InformixServerGroupService,
    private confirmationDialogService: ConfirmationDialogService,
    private notifications: NotificationsService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.currentDate = new Date();
    this.tomorrowsDate = new Date();
    this.tomorrowsDate.setDate(this.currentDate.getDate() + 1);
    this.setTimeToZero(this.tomorrowsDate);

    this.user = this.route.snapshot.data.user || null;
    this.isNewUser = !this.user;

    this.userService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    this.groupService.getGroup(GROUP_ROOT_ID, -1, true, true).then(group => {
      this.rootGroup = group;
      this.allPermissionsRows = [];
      this.rootPermissionsRow = this.createPermissionsRows(this.rootGroup);
      this.rootPermissionsRow.expanded = true;

      if (this.user) {
        this.allPermissionsRows.forEach(row => {
          const permissionsMap = row.isGroup ? this.user.accessPermissions.groups : this.user.accessPermissions.servers;
          const itemPermissions = permissionsMap ? permissionsMap[row.item.id] : null;
          if (itemPermissions) {
            (row.formGroup.controls.permissions as UntypedFormArray).controls.forEach((control, i) => {
              if (control.enabled && ((itemPermissions & this.serverPermissions[i].value) > 0)) {
                this.isEditMode = true;
                control.setValue(true);
                // Disabling Read, SQL checkboxes when Adminster access is enabled
                if (itemPermissions === (InformixServerPermission.READ + InformixServerPermission.SQL + InformixServerPermission.ADMIN)
                  && i !== 2) {
                    control.disable();
                  }
                this.isEditMode = false;
              }
            });

            let currentRow = row.parent;
            while (currentRow) {
              currentRow.expanded = true;
              currentRow = currentRow.parent;
            }
          }
        });
      }
    }).catch(err => {
      console.log('Could not get groups and servers list');
      console.log(err);
    });


    this.usersService.getUsers().then(users => {
      this.allUsers = users;
      if (this.user && this.user.hasPermissions(UserPermission.SUPERADMIN)) {
        this.isLastAdmin = users.filter(user => user.hasPermissions(UserPermission.SUPERADMIN)).length === 1;
      }
    }).catch(err => {
      console.error('Failed to get all users', err);
    });


    this.serverPermissionsDisabled = this.user && this.user.hasPermissions(UserPermission.SUPERADMIN);

    this.createFormGroup();
  }

  private createPermissionsFormGroup(row: PermissionsRow): UntypedFormGroup {
    const formArray = new UntypedFormArray(this.serverPermissions.map((perm, index) => {
      const control = new UntypedFormControl(false);
      control.valueChanges.subscribe(checked => {
        if (checked) {
          //  Disabling all previous checkboxes when current checkbox is checked
          formArray.controls.forEach((c, i) => {
            if (i < index) {
              c.setValue(true);
              c.disable({ emitEvent: true });
            }
          });
        }
        // Enabling previous checkbox when current checkbox is unchecked
        if (!checked) {
          formArray.controls[index - 1]?.enable({ emitEvent: false });
        }
        const rowQueue = [row];
        while (rowQueue.length > 0) {
          rowQueue.shift().children.forEach(child => {
            const childControl = (child.formGroup.controls.permissions as UntypedFormArray).controls[index];
            if (checked) {
              childControl.setValue(checked, { emitEvent: false });
              childControl.disable({ emitEvent: false });
            } else {
              childControl.enable({ emitEvent: false });
              childControl.setValue(checked, { emitEvent: false });
            }
            if (child.children.length > 0) {
              rowQueue.push(child);
            }
          });
        }
      });
      return control;
    }));
    return new UntypedFormGroup({
      permissions: formArray
    });
  }

  private createPermissionsRows(item: InformixTreeItem): PermissionsRow {
    const row: PermissionsRow = {
      item,
      isGroup: item instanceof InformixServerGroup,
      formGroup: null,
      expanded: false,
      children: [],
      parent: null
    };
    row.formGroup = this.createPermissionsFormGroup(row);
    if (row.isGroup) {
      const group = item as InformixServerGroup;
      const groups = group.groups.map(v => this.createPermissionsRows(v)).sort();
      const servers = group.servers.map(v => this.createPermissionsRows(v)).sort();
      row.children = groups.concat(servers);
      row.children.forEach(child => child.parent = row);
    }
    this.allPermissionsRows.push(row);
    return row;
  }
/**
 * validating User Name already exist or not,
 * validating User Name should not allow the whitespace
 */
  duplicateUserNameCheck(): ValidatorFn {
    return (control: AbstractControl): { customError: string } | null => {
      let error: string;
      this.translate.get('userManagement.customError.duplicateUserName').subscribe((text: string) => {
        error = text;
      });
      const errors = { customError: error };
      if (control.value !== null && control.value.indexOf(' ') > -1) {
        return { customError: 'whitespaces are not allowed in username' };
      }
      if (control.value !== null) {
        if (this.allUsers.findIndex(user => user.name === control.value) === -1) {
          return null;
        } else {
          return errors;
        }
      }
    };
  }

  // Custom validator function for validate user name
  allowedCharactersValidator(): ValidatorFn {
    return (control: AbstractControl): { customError: string } | null => {
      const timeRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9._\-]*[a-zA-Z0-9])?$/;

      if (control.value && (control.value.length < 3 || control.value.length > 15)) {
        return { customError: 'Username must be between 3 and 15 characters long.' };
      }

      if (control.value && !timeRegex.test(control.value)) {
        if (!/^[a-zA-Z0-9._\-]+$/.test(control.value)) {
          return {
            customError: `Username contains invalid characters. 
            Allowed characters are a-z, A-Z, 0-9, period (.), dash (-), and underscore (_)` };
        }

        if (/^[._\-].*|.*[._\-]$/.test(control.value)) {
          return { customError: 'Username cannot start or end with an period (.), dash (-), or underscore (_).' };
        }
      }

      return null;
    };
  }

  private createFormGroup() {
    const permissionControls: UntypedFormControl[] = this.permissions.map(permission => {
      let control: UntypedFormControl;
      if (this.user) {
        control = new UntypedFormControl({
          value: this.user.hasPermissions(permission.permission),
          disabled: this.isLastAdmin && permission.permission === UserPermission.SUPERADMIN
        });
      } else {
        control = new UntypedFormControl(false);
      }

      if (permission.permission === UserPermission.SUPERADMIN) {
        control.valueChanges.subscribe(value => {
          this.serverPermissionsDisabled = !!value;
        });
      }

      return control;
    });

    const formControls: { [key: string]: AbstractControl } = {};

    formControls.name = new UntypedFormControl(this.user ? { value: this.user.name, disabled: true } : null,
      [this.duplicateUserNameCheck(), this.allowedCharactersValidator(), Validators.required]);
    if (this.isNewUser) {
      formControls.password = new UntypedFormControl(null, [Validators.required]);
      formControls.expirationDate = new UntypedFormControl(0);
      formControls.expirationDays = new UntypedFormControl(null);
    } else {
      formControls.expirationDate = new UntypedFormControl(this.user.roleExpiry ? new Date(this.user.roleExpiry) : 0);
      if (this.user.roleExpiry) {
        formControls.expirationDays = new UntypedFormControl(this.getDiffInDays(new Date(formControls.expirationDate.value)));
      } else {
        formControls.expirationDays = new UntypedFormControl(null);
      }
    }

    formControls.permissions = new UntypedFormArray(permissionControls);
    this.formGroup = new UntypedFormGroup(formControls);

    formControls.expirationDate.valueChanges.subscribe(value => {
      if (value && !(value !== value) && value !== 'Invalid Date') {
        const roleExpiry = new Date(value);
        this.setTimeToZero(roleExpiry);
        formControls.expirationDays.setValue((this.getDiffInDays(roleExpiry)), { emitEvent: false });
      }
    });

    formControls.expirationDays.valueChanges.subscribe(value => {
      if (value && value > 0) {
        const newDateTimetamp = (value * (1000 * 3600 * 24)) + this.currentDate.getTime();
        const roleExpiry = new Date(newDateTimetamp);
        this.setTimeToZero(roleExpiry);
        formControls.expirationDate.setValue(roleExpiry, { emitEvent: false });
      }
    });
  }

  onSaveClick() {
    if (!this.formGroup.valid) {
      return;
    }
    const permissionByte = (this.formGroup.controls.permissions as UntypedFormArray).controls.reduce(
      (prev, current, index) => current.value ? prev | this.permissions[index].permission : prev, 0
      );

    const accessPermissions: any = {
      groups: {},
      servers: {}
    };

    let hasAccessPermissions = false;

    this.allPermissionsRows.forEach(row => {
      let permissionValue = 0;
      (row.formGroup.controls.permissions as UntypedFormArray).controls.forEach((control, index) => {
        //checked control values too. Since we now disabling (Read, SQL ) for Administer access
        if (control.value) {
          permissionValue += this.serverPermissions[index].value;
        }
      });

      if (permissionValue > 0) {
        if (row.isGroup) {
          accessPermissions.groups[row.item.id] = permissionValue;
        } else {
          accessPermissions.servers[row.item.id] = permissionValue;
        }
        hasAccessPermissions = true;
      }
    });


    let roleExpiry;
    if (this.formGroup.value.expirationDate) {
      roleExpiry = new Date((this.formGroup.value.expirationDate));
      this.setTimeToZero(roleExpiry);
    } else {
      roleExpiry = 0;
    }
    if (!this.isNewUser) {
      const user = this.user;
      user.permissions = permissionByte;
      if (user.hasPermissions(UserPermission.SUPERADMIN)) {
        user.accessPermissions = undefined;
      } else {
        user.accessPermissions = accessPermissions;
      }
      user.roleExpiry = this.formGroup.value.expirationDate ? roleExpiry.getTime() : null;
      this.saveUser(user);
    } else {
      const f = this.formGroup.value;
      const user = new User(f.name, f.password, f.locked, permissionByte, accessPermissions,
        f.expirationDate ? roleExpiry.getTime() : null);
      if (!hasAccessPermissions && !user.hasPermissions(UserPermission.SUPERADMIN)) {
        this.confirmationDialogService.show('create user without access permissions?',
          () => this.saveUser(user));
      } else {
        this.saveUser(user);
      }
    }
  }

  private saveUser(user: User) {
    const promise = this.user ? this.usersService.updateUser(user) : this.usersService.createUser(user);
    promise.then(() => {
      this.notifications.pushSuccessNotification('User saved');
      this.router.navigate(this.user ? ['..', '..', ''] : ['..', ''], { relativeTo: this.route });
    }).catch((err: HttpErrorResponse) => {
      const errorMessage = 'Failed to save user. ' + (err.error.err || 'An unknown error occurred');
      this.notifications.pushErrorNotification(errorMessage);
      console.error(err);
    });
  }

  isLastAdminPermission(index: number) {
    return this.isLastAdmin && this.permissions[index].permission === UserPermission.SUPERADMIN;
  }

  getPermissionTooltip(index: number) {
    return this.isLastAdminPermission(index) ? 'Cannot remove this permission from the only unlocked admin in the system' : '';
  }

  getPermissionControls(): AbstractControl[] {
    return (this.formGroup.controls.permissions as UntypedFormArray).controls;
  }

  clearAllServerPermissions() {
    if (!this.allPermissionsRows) {
      return;
    }

    this.allPermissionsRows.forEach(row => {
      (row.formGroup.controls.permissions as UntypedFormArray).controls.forEach(control => {
        if (control.disabled) {
          control.enable({ onlySelf: true, emitEvent: false });
        }
        control.setValue(false, { emitEvent: false });
      });
    });
  }

  private setTimeToZero(date: Date) {
    date.setHours(0, 0, 0, 0);
  }

  private getDiffInDays(roleExpiry: Date) {
    this.setTimeToZero(this.currentDate);
    const diffInTime = roleExpiry.getTime() - this.currentDate.getTime();
    const diffInDays = (diffInTime / (1000 * 3600 * 24)).toFixed(0);
    return diffInDays === '-0' ? 0 : diffInDays;
  }
}

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/

/* eslint-disable max-len */

export interface OnconfigParamInfo {
  type?: string;
  description?: string;
  min?: number;
  max?: number;
  values?: any[];
}

export interface OnconfigParamCategoryJson {
  description: string;
  params: string[];
}

export const onconfigCategories: OnconfigParamCategoryJson[] = [
  {
    description: 'Auditing',
    params: ['ADTERR', 'ADTMODE', 'ADTPATH', 'ADTROWS', 'ADTSIZE', 'IFXGUARD']
  },
  {
    description: 'Auto-tuning',
    params: ['AUTO_TUNE', 'AUTO_LRU_TUNING', 'AUTO_AIOVPS', 'AUTO_CKPTS', 'AUTO_REPREPARE', 'AUTO_STAT_MODE', 'AUTO_READAHEAD', 'AUTO_TUNE_SERVER_SIZE', 'AUTOLOCATE']
  },
  {
    description: 'Backup and Restore',
    params: ['BAR_ACT_LOG', 'BAR_DEBUG_LOG', 'BAR_DEBUG', 'BAR_IXBAR_PATH', 'BAR_MAX_BACKUP', 'BAR_MAX_RESTORE', 'BAR_RETRY', 'BAR_NB_XPORT_COUNT',
      'BAR_XFER_BUF_SIZE', 'RESTARTABLE_RESTORE', 'BAR_PROGRESS_FREQ', 'BAR_BSALIB_PATH', 'BACKUP_FILTER', 'RESTORE_FILTER', 'RTO_SERVER_RESTART',
      'BAR_PERFORMANCE', 'BAR_SIZE_FACTOR', 'BAR_CKPTSEC_TIMEOUT', 'BAR_HISTORY', 'OFF_RECVRY_THREADS', 'ON_RECVRY_THREADS',
      'TAPEBLK', 'TAPEDEV', 'TAPESIZE', 'LTAPEDEV', 'LTAPEBLK', 'LTAPESIZE',
      'PSM_ACT_LOG', 'PSM_DEBUG_LOG', 'PSM_DEBUG', 'PSM_CATALOG_PATH', 'PSM_DBS_POOL', 'PSM_LOG_POOL',
      'ISM_DATA_POOL', 'ISM_LOG_POOL']
  },
  {
    description: 'Caches',
    params: ['DD_HASHSIZE', 'DD_HASHMAX', 'DS_HASHSIZE', 'DS_POOLSIZE', 'PC_HASHSIZE', 'PC_POOLSIZE',
      'STMT_CACHE', 'STMT_CACHE_HITS', 'STMT_CACHE_SIZE', 'STMT_CACHE_NOLIMIT', 'STMT_CACHE_NUMPOOL', 'SEQ_CACHE_SIZE']
  },
  {
    description: 'Enterprise Replication and Sharding',
    params: ['CDR_APPLY', 'CDR_EVALTHREADS', 'CDR_DSLOCKWAIT', 'CDR_NIFCOMPRESS', 'CDR_SERIAL', 'CDR_DBSPACE', 'CDR_QHDR_DBSPACE', 'CDR_QDATA_SBSPACE',
      'CDR_SUPPRESS_ATSRISWARN', 'CDR_DELAY_PURGE_DTC', 'CDR_LOG_LAG_ACTION', 'CDR_LOG_STAGING_MAXSIZE', 'CDR_MAX_DYNAMIC_LOGS',
      'GRIDCOPY_DIR', 'CDR_TSINSTANCEID', 'CDR_MAX_FLUSH_SIZE', 'CDR_AUTO_DISCOVER', 'CDR_MEM', 'CDR_QUEUEMEM',
      'ENCRYPT_SMX', 'ENCRYPT_CDR', 'ENCRYPT_CIPHERS', 'ENCRYPT_MAC', 'ENCRYPT_MACFILE', 'ENCRYPT_SWITCH',
      'SHARD_MEM', 'SHARD_ID']
  },
  {
    description: 'High Availability Cluster (HDR, SDS, RSS)',
    params: ['DRAUTO', 'DRINTERVAL', 'HDR_TXN_SCOPE', 'DRTIMEOUT', 'DRLOSTFOUND', 'DRIDXAUTO', 'HA_ALIAS', 'HA_FOC_ORDER', 'LOG_INDEX_BUILDS',
      'SDS_ENABLE', 'SDS_TIMEOUT', 'SDS_TEMPDBS', 'SDS_ALTERNATE', 'SDS_FLOW_CONTROL', 'SDS_PAGING', 'SDS_LOGCHECK', 'UPDATABLE_SECONDARY',
      'FAILOVER_CALLBACK', 'TEMPTAB_NOLOG', 'DELAY_APPLY', 'STOP_APPLY', 'LOG_STAGING_DIR', 'RSS_FLOW_CONTROL', 'FAILOVER_TX_TIMEOUT',
      'ENABLE_SNAPSHOT_COPY', 'SMX_NUMPIPES', 'SMX_COMPRESS', 'SMX_PING_INTERVAL', 'SMX_PING_RETRY', 'CLUSTER_TXN_SCOPE',
      'ENCRYPT_HDR', 'ENCRYPT_SMX', 'ENCRYPT_CDR', 'ENCRYPT_CIPHERS', 'ENCRYPT_MAC', 'ENCRYPT_MACFILE', 'ENCRYPT_SWITCH']
  },

  {
    description: 'Network',
    params: ['NETTYPE', 'LISTEN_TIMEOUT', 'MAX_INCOMPLETE_CONNECTIONS', 'FASTPOLL', 'NUMFDSERVERS', 'NS_CACHE', 'NET_IO_TIMEOUT_ALARM', 'DRDA_COMMBUFFSIZE']
  },
  {
    description: 'Optimizer',
    params: ['OPTCOMPIND', 'DIRECTIVES', 'EXT_DIRECTIVES', 'OPT_GOAL', 'IFX_FOLDVIEW', 'STATCHANGE', 'USTLOW_SAMPLE']
  },
  {
    description: 'Parallel Database Query (PDQ)',
    params: ['MAX_PDQPRIORITY', 'DS_MAX_QUERIES', 'DS_TOTAL_MEMORY', 'DS_MAX_SCANS', 'DS_NONPDQ_QUERY_MEM', 'DATASKIP']
  },
  {
    description: 'Physical and Logical Log',
    params: ['PHYSFILE', 'PLOG_OVERFLOW_PATH', 'PHYSBUFF', 'LOGFILES', 'LOGSIZE', 'DYNAMIC_LOGS', 'LOGBUFF', 'AUTO_LLOG']
  },
  {
    description: 'Storage Spaces',
    params: ['ROOTNAME', 'ROOTPATH', 'ROOTOFFSET', 'ROOTSIZE', 'MIRROR', 'MIRRORPATH', 'MIRROROFFSET', 'DBSPACETEMP', 'SBSPACETEMP', 'SBSPACENAME',
      'SYSSBSPACENAME', 'ONDBSPACEDOWN', 'DISK_ENCRYPTION', 'STORAGE_FULL_ALARM', 'SP_AUTOEXPAND', 'SP_THRESHOLD', 'SP_WAITTIME']
  },
  {
    description: 'SQL and Transactions',
    params: ['LTXHWM', 'LTXEHWM', 'SQL_LOGICAL_CHAR', 'EXPLAIN_STAT', 'SQLTRACE', 'TXTIMEOUT', 'USELASTCOMMITTED', 'ALLOW_NEWLINE', 'DEFAULTESCCHAR',
      'DISABLE_B162428_XA_FIX', 'EILSEQ_COMPAT_MODE', 'HETERO_COMMIT', 'IFX_XA_UNIQUEXID_IN_DATABASE', 'DATASKIP']
  },
  {
    description: 'System',
    params: ['SERVERNUM', 'DBSERVERNAME', 'DBSERVERALIASES', 'FULL_DISK_INIT', 'MSGPATH', 'MSG_DATE', 'CONSOLE',
      'DUMPSHMEM', 'DUMPCNT', 'DUMPCORE', 'DUMPDIR', 'DUMPGCORE', 'ALARMPROGRAM', 'ALRM_ALL_EVENTS', 'SYSALARMPROGRAM',
      'PRELOAD_DLL_FILE', 'JVMTHREAD', 'JVPARGS', 'JVPCLASSPATH', 'JVPDEBUG', 'JVPHOME', 'JVPJAVAHOME', 'JVPJAVALIB', 'JVPJAVAVM', 'JVPLOGFILE', 'JVPPROPFILE']
  },
  {
    description: 'System Resources',
    params: ['BUFFERPOOL', 'MULTIPROCESSOR', 'VPCLASS', 'VP_MEMORY_CACHE_KB', 'SINGLE_CPU_VP', 'LOCKS', 'CLEANERS', 'DIRECT_IO',
      'RESIDENT', 'SHMBASE', 'SHMVIRTSIZE', 'SHMADD', 'EXTSHMADD', 'SHMTOTAL', 'SHMVIRT_ALLOCSEG', 'SHMNOACCESS', 'DUMPSHMEM',
      'CKPTINTVL', 'RTO_SERVER_RESTART', 'BLOCKTIMEOUT',
      'USEOSTIME', 'STACKSIZE', 'LOW_MEMORY_MGR', 'LOW_MEMORY_RESERVE', 'DEADLOCK_TIMEOUT', 'RA_PAGES',
      'LIMITNUMSESSIONS', 'TENANT_LIMIT_CONNECTIONS', 'TENANT_LIMIT_MEMORY', 'TENANT_LIMIT_SPACE',
      'SESSION_LIMIT_LOCKS', 'SESSION_LIMIT_LOGSPACE', 'SESSION_LIMIT_MEMORY', 'SESSION_LIMIT_TEMPSPACE', 'SESSION_LIMIT_TXN_TIME']
  },
  {
    description: 'Tables and Indexes',
    params: ['TBLTBLFIRST', 'TBLTBLNEXT', 'TBLSPACE_STATS', 'FILLFACTOR', 'MAX_FILL_DATA_PAGES', 'DEF_TABLE_LOCKMODE', 'BTSCANNER',
      'ONLIDX_MAXMEM', 'BATCHEDREAD_TABLE', 'BATCHEDREAD_INDEX', 'LOG_INDEX_BUILDS', 'SB_CHECK_FOR_TEMP']
  },
  {
    description: 'Users and Security',
    params: ['DBCREATE_PERMISSION', 'DB_LIBRARY_PATH', 'IFX_EXTEND_ROLE', 'SECURITY_LOCALCONNECTION', 'UNSECURE_ONSTAT',
      'ADMIN_USER_MODE_WITH_DBSA', 'ADMIN_MODE_USERS', 'SSL_KEYSTORE_LABEL', 'TLS_VERSION', 'PLCY_POOLSIZE', 'PLCY_HASHSIZE', 'USRC_POOLSIZE', 'USRC_HASHSIZE',
      'DISK_ENCRYPTION', 'GSKIT_VERSION', 'PAM_STACKSIZE', 'CHECKALLDOMAINSFORUSER', 'USERMAPPING', 'REMOTE_USERS_CFG', 'REMOTE_SERVER_CFG', 'S6_USE_REMOTE_SERVER_CFG']
  }
];

export const onconfigData: { [key: string]: OnconfigParamInfo } = {
  ADMIN_MODE_USERS: {
    type: 'STRING',
    description: 'Specifies which users (in addition to user informix and DBSA group users) can access the database server in administration mode.'
  },
  ADMIN_USER_MODE_WITH_DBSA: {
    description: 'Specifies which users, besides the user informix, can connect to the database server in the administration mode. 0 = Only the user informix can connect in the administration mode. 1 = The user informix, the members of the DBSA group, and any users specified in the ADMIN_USER_MODE configuration parameter can connect in the administration mode.'
  },
  ADTERR: {
    description: 'Specifies how the database server behaves when it encounters an error while it writes an audit record. 0 = continue thread processing, 1 = suspend thread processing, 3 = shut down system.'
  },
  ADTMODE: {
    description: 'Controls the level of auditing. 0 = auditing disabled, 1 = auditing on for all sessions, 3 = auditing on for DBSSO actions, 5 = auditing on for database server administrator actions, 7 = auditing on for DBSSO and database server administrator actions.'
  },
  ADTPATH: {
    description: 'Specifies the directory in which the database server saves audit files.'
  },
  ADTROWS: {
    description: 'Controls selective row-level auditing of tables. 0 = audits row-level events on all tables, 1 = audits row-level events only on tables for which the AUDIT flag is set, 2 = selective row-level auditing that also includes the primary key in audit records'
  },
  ADTSIZE: {
    description: 'Specifies the maximum size of an audit file.'
  },
  ALARMPROGRAM: {
    type: 'STRING',
    description: 'Displays event alarms.'
  },
  ALLOW_NEWLINE: {
    type: 'BOOLEAN',
    description: 'Allows or disallows newline characters in quoted strings for all sessions.'
  },
  ALRM_ALL_EVENTS: {
    type: 'BOOLEAN',
    description: 'Specifies whether the ALARMPROGRAM configuration parameter runs for all events that are logged in the MSGPATH configuration parameter, or only for noteworthy events.'
  },
  AUTO_AIOVPS: {
    type: 'BOOLEAN',
    description: 'Enables the database server to automatically increase the number of AIO VPs and page cleaner threads when the database server detects that the I/O workload has outpaced the performance of the existing AIO VPs.'
  },
  AUTO_CKPTS: {
    type: 'BOOLEAN',
    description: 'Allows the database server to trigger checkpoints more frequently to avoid transaction blocking.'
  },
  AUTO_LLOG: {
    type: 'STRING',
    description: 'Enables the database server to automatically add logical logs in the specified dbspace to improve performance.'
  },
  AUTO_LRU_TUNING: {
    type: 'BOOLEAN',
    description: 'Enables automatic LRU tuning.'
  },
  AUTO_READAHEAD: {
    type: 'STRING',
    description: 'Changes the automatic read-ahead mode or disables automatic read ahead.  Automatic read-ahead operations help improve query performance by issuing asynchronous page requests when the database server detects that the query is encountering I/O.  0 = Disable automatic read-ahead requests.  1 = Enable automatic read-ahead requests in the standard mode.  2 = Enable automatic read-ahead requests in the aggressive mode.'
  },
  AUTO_REPREPARE: {
    type: 'BOOLEAN',
    description: 'Controls whether the database server automatically reoptimizes SPL routines and reprepares prepared objects after the schema of a table that is referenced by the SPL routine or by the prepared object was changed.'
  },
  AUTO_STAT_MODE: {
    type: 'BOOLEAN',
    description: 'Enables or disables automatically updating statistics for the database server.'
  },
  AUTO_TUNE: {
    type: 'BOOLEAN',
    description: 'Enables or disables all automatic tuning configuration parameters that are not present in the onconfig file.'
  },
  AUTO_TUNE_SERVER_SIZE: {
    type: 'STRING', values: ['SMALL', 'MEDIUM', 'LARGE', 'XLARGE'],
    description: 'Set the sizes of memory and storage spaces to allocate based on the number of expected concurrent users. SMALL = 1 - 100 users, MEDIUM = 101 - 500 users, LARGE = 501 - 1000 users, XLARGE = more than 1000 users.'
  },
  AUTOLOCATE: {
    type: 'INTEGER', min: 0, max: 32,
    description: 'Enables the automatic location of databases, indexes, and tables, and the automatic fragmentation of tables. '
  },
  BACKUP_FILTER: {
    description: 'Specifies the path name and any options for an external filter program that you use with the ON-Bar or ontape utility.'
  },
  BAR_ACT_LOG: {
    type: 'STRING',
    description: 'Specifies the location of the ON-Bar activity log file.'
  },
  BAR_BSALIB_PATH: {
    type: 'STRING',
    description: 'Specifies the pathname and filename of the XBSA shared library for the storage manager.'
  },
  BAR_CKPTSEC_TIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the amount of time, in seconds, that an RS secondary server should wait for a checkpoint to arrive from the primary server while performing an external backup. If a backup fails because the checkpoint from the primary has timed out, you can use theBAR_CKPTSEC_TIMEOUT configuration parameter to increase the amount of time that an RS secondary server should wait for a checkpoint to arrive from the primary server.'
  },
  BAR_DEBUG: {
    type: 'INTEGER', min: 0, max: 9,
    description: 'Specifies the level of debugging messages in the ON-Bar activity log.'
  },
  BAR_DEBUG_LOG: {
    type: 'STRING',
    description: 'Specifies the location of the ON-Bar debug log.'
  },
  BAR_DECRYPTION: {
    type: 'STRING',
    description: 'Specifies a set of credentials that can be used to access the AWS-KMS service, for use when decrypting backups.'
  },
  BAR_ENCRYPTION: {
    type: 'STRING',
    description: 'Used to encrypt the Informix server backups.'
  },
  BAR_HISTORY: {
    type: 'BOOLEAN',
    description: 'Specifies whether the sysutils database maintains a backup history.'
  },
  BAR_IXBAR_PATH: {
    type: 'STRING',
    description: 'Overrides the name and path of the ON-Bar boot file.  By default, the ON-Bar boot file is created in $INFORMIXDIR/etc and has the name "ixbar.<SERVER_NUM>".'
  },
  BAR_MAX_BACKUP: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the maximum number of parallel backup processes that are allowed for each ON-Bar command.'
  },
  BAR_MAX_RESTORE: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the maximum number of parallel restore processes that are allowed during an ON-Bar restore operation.'
  },
  BAR_NB_XPORT_COUNT: {
    type: 'INTEGER', min: 3,
    description: 'Specifies the number of shared-memory data buffers for each backup or restore process.'
  },
  BAR_PERFORMANCE: {
    type: 'INTEGER', min: 0, max: 3,
    description: 'Specifies the level of performance statistics to print to the ON-Bar activity log.'
  },
  BAR_PROGRESS_FREQ: {
    type: 'INTEGER', min: 0,
    description: 'Specifies in minutes how frequently the backup or restore progress messages display in the activity log.'
  },
  BAR_RETRY: {
    type: 'INTEGER', min: 0, max: 32766,
    description: 'Specifies how many times ON-Bar should retry a data backup, logical-log backup, or restore operation if the first attempt fails.'
  },
  BAR_SIZE_FACTOR: {
    type: 'INTEGER', min: 0,
    description: 'Augments the estimate for the size of a backup object, prior to the backup. The value of the BAR_SIZE_FACTOR parameter is computed as a percentage of the original backup object size, and then added to the estimate, before the estimate is communicated to the storage manager.'
  },
  BAR_XFER_BUF_SIZE: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the size of each transfer buffer that the database server passes to ON-Bar and the storage manager.  The maximum buffer size is 64 kilobytes, so BAR_XFER_BUF_SIZE * pagesize <= 64 kilobytes.'
  },
  BATCHEDREAD_INDEX: {
    type: 'BOOLEAN',
    description: 'Enables the optimizer to automatically fetch a set of keys from an index buffer. This reduces the number of times a buffer is read, thus improving performance.'
  },
  BATCHEDREAD_TABLE: {
    type: 'BOOLEAN',
    description: 'Enables or disables light scans on compressed tables, tables with rows that are larger than a page, and tables with VARCHAR, LVARCHAR, and NVARCHAR data.'
  },
  BLOCKTIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the number of seconds that a thread or database server will hang.'
  },
  BTSCANNER: {
    description: 'Configures the b-tree scanner.'
  },
  BUFFERPOOL: {
    description: 'Configures how many data pages are cached in shared memory and how often those pages are flushed to disk between checkpoints.'
  },
  CDR_APPLY: {
    type: 'STRING',
    description: 'Specifies the minimum and maximum number of data sync threads.'
  },
  CDR_AUTO_DISCOVER: {
    type: 'BOOLEAN',
    description: 'Enables or disables connectivity autoconfiguration for a high-availability cluster or Enterprise Replication domain.'
  },
  CDR_DBSPACE: {
    description: 'Specifies the dbspace where the syscdr database is created.'
  },
  CDR_DELAY_PURGE_DTC: {
    type: 'STRING',
    description: 'Specifies how long to retain the delete tables to support the delete wins conflict resolution rule.'
  },
  CDR_DSLOCKWAIT: {
    description: 'Specifies the number of seconds the data sync component waits for the database locks to be released.'
  },
  CDR_EVALTHREADS: {
    description: 'Specifies the number of group evaluator threads to create when Enterprise Replication starts, and enables parallelism.'
  },
  CDR_LOG_LAG_ACTION: {
    type: 'STRING',
    description: 'Specifies how Enterprise Replication responds to a potential log wrap situation. Combine multiple values with +. logstage = Enable compressed logical log staging; dlog = Enable the dynamic addition of logical logs; ignore = Ignore the potential for log wrapping; ddrblock = Block client applications update activity; shutdown = Shut down Enterprise Replication on the affected server.'
  },
  CDR_LOG_STAGING_MAXSIZE: {
    type: 'STRING',
    description: 'Specifies the maximum amount of space that Enterprise Replication uses to stage compressed log files.'
  },
  CDR_MAX_DYNAMIC_LOGS: {
    description: 'Specifies the number of dynamic log file requests that Enterprise Replication can make in one server session.'
  },
  CDR_MAX_FLUSH_SIZE: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the maximum number of replicated transactions that are applied before the logs are flushed.'
  },
  CDR_MEM: {
    description: 'Specify the method for Enterprise Replication memory-pool allocation.'
  },
  CDR_NIFCOMPRESS: {
    description: 'Specifies the level of compression the database server uses before sending data from the source database server to the target database server.'
  },
  CDR_QHDR_DBSPACE: {
    description: 'Speicifies the name of the dbspace that Enterprise Replication uses to store transaction records.'
  },
  CDR_QDATA_SBSPACE: {
    description: 'Specifies the list of up to 32 names of sbspaces that Enterprise Replication uses to store spooled transaction row data.'
  },
  CDR_QUEUEMEM: {
    description: 'Specifies the maximum amount of memory that is used for the send and receive queues.'
  },
  CDR_SERIAL: {
    description: 'Enables control over generating values for serial columns in tables that are defined for replication.'
  },
  CDR_SUPPRESS_ATSRISWARN: {
    description: 'Specifies the data sync error and warning code numbers to be suppressed in ATS and RIS files.'
  },
  CDR_TSINSTANCEID: {
    type: 'INTEGER', min: 0, max: 32768,
    description: 'Specifies how to generate unique identifiers for time series instances across replication servers.'
  },
  CHECKALLDOMAINSFORUSER: {
    type: 'BOOLEAN',
    description: 'Enables or disables the check of all of the domains for all users.'
  },
  CKPTINTVL: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the frequency, in seconds, at which the database server checks to determine whether a checkpoint is needed.'
  },
  CLEANERS: {
    description: 'Specifies the number of page-cleaner threads available during the database server operation.'
  },
  CLUSTER_TXN_SCOPE: {
    type: 'STRING', values: ['SESSION', 'SERVER', 'CLUSTER'],
    description: 'Configures the high-availability cluster so that when a client session issues a commit, the server blocks the session until the transaction is applied in that session, on a secondary server, or across the cluster.'
  },
  CONSOLE: {
    description: 'Specifies the path and name for console-message file.'
  },
  CONVERSION_GUARD: {
    type: 'INTEGER',
    description: 'Specifies whether Informix stops or continues an upgrade to a new version of the server if an error occurs during the upgrade process.'
  },
  DATASKIP: {
    description: 'Controls whether the database server skips a dbspace that is unavailable during the processing of a transaction.'
  },
  DBCREATE_PERMISSION: {
    type: 'STRING',
    description: 'Restricts the permission to create databases to the user that you specify.'
  },
  DBSERVERALIASES: {
    description: 'Specifies an alias name or a list of unique alias names for the database server'
  },
  DBSERVERNAME: {
    description: 'Specifies a unique name for the database server.'
  },
  DBSPACETEMP: {
    type: 'STRING',
    description: 'Specifies a list of dbspaces that the database server uses to globally manage the storage of temporary tables.'
  },
  DB_LIBRARY_PATH: {
    description: 'Specifies a comma-separated list of directory prefix locations from which the database server can load external modules, such as DataBlade modules.'
  },
  DD_HASHMAX: {
    description: 'Specifies the maximum number of tables in each hash bucket in the data-dictionary cache.'
  },
  DD_HASHSIZE: {
    description: 'Specifies the number of hash buckets or lists that are in the data-dictionary cache.'
  },
  DEADLOCK_TIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the maximum number of seconds that a database server thread can wait to acquire a lock.  Use this parameter only for distributed queries that involve a remote database server.'
  },
  DEFAULTESCCHAR: {
    description: 'Specifies the default escape character that is used for LIKE and MATCHES conditions.'
  },
  DEF_TABLE_LOCKMODE: {
    type: 'STRING', values: ['PAGE', 'ROW'],
    description: 'Specifies the lock mode at the page or row level for new tables.'
  },
  DELAY_APPLY: {
    type: 'STRING',
    description: 'Specifies how long RS secondary servers should wait before applying logs.'
  },
  DIRECTIVES: {
    type: 'BOOLEAN',
    description: 'Enables or disables the use of SQL directives.'
  },
  DIRECT_IO: {
    description: 'Controls the use of direct I/O for cooked files used for dbspace chunks. This parameter enables direct I/O (bypassing file system buffering) on UNIX platforms or concurrent IO (bypassing both file system buffering and unnecessary write serialization) on AIX operating systems. 0 = Neither direct I/O or concurrent I/O is used. 1 = Direct I/O, which bypasses file system buffering, is used if available. 2 = Concurrent I/O is enabled on AIX operating systems (The concurrent I/O option includes direct I/O and concurrent I/O.)'
  },
  DISABLE_B162428_XA_FIX: {
    description: 'Specifies when XA transactions are freed. 0 = Frees transactions only when an xa_rollback is called. 1 = Frees transactions if transaction rollback for other than an xa_rollback.'
  },
  DISK_ENCRYPTION: {
    description: 'Controls the encryption of storage spaces.'
  },
  DRAUTO: {
    description: 'Specifies a HDR-failover method for HDR high-availability systems.'
  },
  DRDA_COMMBUFFSIZE: {
    description: 'Specifies the size of the DRDA communications buffer.'
  },
  DRIDXAUTO: {
    description: 'Specifies whether the primary High-Availability Data Replication (HDR) server automatically starts index replication if the secondary HDR server detects a corrupted index.'
  },
  DRINTERVAL: {
    type: 'INTEGER', min: -1,
    description: 'Specifies the maximum interval in seconds between flushing of the high-availability data-replication buffer. To update synchronously, set the parameter to -1.'
  },
  DRLOSTFOUND: {
    description: 'Specifies the path name to the HDR lost-and-found file. This file indicates that some transactions were committed on the HDR primary database server that were not committed on the secondary database server when the primary database server experienced a failure.'
  },
  DRTIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'For high-availability data-replication pairs, specifies the length of time, in seconds, that a database server in a high-availability data-replication pair waits for a transfer acknowledgment from the other database server in the pair.'
  },
  DS_HASHSIZE: {
    description: 'Specifies the number of hash buckets in the data-distribution cache and other caches. The database server stores and accesses column statistics that the UPDATE STATISTICS statement generates in the MEDIUM or HIGH mode in the data-distribution cache.'
  },
  DS_MAX_QUERIES: {
    type: 'INTEGER', min: 1, max: 8388608,
    description: 'Specifies the maximum number of parallel database queries (PDQ) queries that can run concurrently.'
  },
  DS_MAX_SCANS: {
    type: 'INTEGER', min: 10, max: 1048576,
    description: 'Limits the number of Parallel Database Query (PDQ)  scan threads that the database server can execute concurrently.'
  },
  DS_NONPDQ_QUERY_MEM: {
    type: 'INTEGER', min: 128,
    description: 'Increases the amount of memory that is a available for a query that is not a Parallel Database Query (PDQ).'
  },
  DS_POOLSIZE: {
    type: 'INTEGER', min: 127,
    description: 'Specifies the maximum number of entries in each hash bucket in the data-distribution cache that the database server uses to store and access column statistics that the UPDATE STATISTICS statement generates in the MEDIUM or HIGH mode.'
  },
  DS_TOTAL_MEMORY: {
    type: 'INTEGER',
    description: 'Specifies the amount of memory, in kilobytes, available for PDQ queries. It should be smaller than the computer physical memory, minus fixed overhead such as operating-system size and buffer-pool size.'
  },
  DUMPCNT: {
    type: 'INTEGER', min: 1,
    description: '(UNIX) Specifies the number of assertion failures for which one database server thread dumps shared memory or generates a core file by calling gcore. An assertion is a test of some condition or expression with the expectation that the outcome is true.'
  },
  DUMPCORE: {
    type: 'BOOLEAN',
    description: '(UNIX) Controls whether assertion failures cause a virtual processor to dump a core image. The core file is left in the directory from which the database server was last invoked. 0 = Do not dump core image; 1 = Dump core image.'
  },
  DUMPDIR: {
    description: 'Specifies a directory in which the database server dumps shared memory, gcore files, or messages from a failed assertion.'
  },
  DUMPGCORE: {
    type: 'BOOLEAN',
    description: '(UNIX) Used with operating systems that support gcore. When set, the database server calls gcore whenever a virtual processor encounters an assertion failure. The gcore utility directs the virtual processor to dump a core image to the core.pid.cnt file in the directory that DUMPDIR specifies and continue processing. 0 = Do not dump gcore; 1 = Dump gcore.'
  },
  DUMPSHMEM: {
    type: 'INTEGER', min: 0, max: 6,
    description: '(UNIX) Indicates that shared memory should be dumped on an assertion failure. All the shared memory that the database server uses is dumped; it is probably quite large. The shared-memory dump is placed in the shmem.pid.cnt file in the directory that DUMPDIR specifies. 0 = Do not dump shared memory; 1 = Dump shared memory; 2 = Create a shared memory dump that excludes the buffer pools; For Informix Version 14.10 and above we support below values also: 5 = Enables the managed shared memory dump feature. When permitted, create a shared memory dump of all the shared memory that the database uses; 6 = Enables the managed shared memory dump feature. When permitted, create a shared memory dump that excludes the buffer pools.'
  },
  DYNAMIC_LOGS: {
    type: 'INTEGER', min: 0, max: 2,
    description: 'Specifies the level of dynamic log allocation for the database server.  0 = No dynamic log allocation; 1 = Alerts when a log is required and pauses to allow manual allocattion of a logical log; 2 = Dynamic log allocation is on, with alerts when each log is dynamically added.'
  },
  EILSEQ_COMPAT_MODE: {
    type: 'BOOLEAN',
    description: 'Controls if the database server checks whether character data inserted by a client application contains code point sequences not recognized by the locale of the current database.     0 = Validate incoming character sequences with the current locale and returns error -202 if any characters are not valid. 1 = Do not validate incoming character sequences.'
  },
  ENABLE_SNAPSHOT_COPY: {
    type: 'BOOLEAN',
    description: 'Enables or disables the ability to clone a server by using the ifxclone utility'
  },
  ENCRYPT_CDR: {
    type: 'INTEGER',
    description: 'Set the level of encryption for Enterprise Replication. 0 = Do not encrypt. 1 = Encrypt when possible. Encryption is used for Enterprise Replication transactions only when the database server being connected to also supports encryption. 2 = Always encrypt. Only connections to encrypted database servers are allowed.'
  },
  ENCRYPT_CIPHERS: {
    description: 'Define all ciphers and modes that can be used by the current database session. ENCRYPT_CIPHERS is used for Enterprise Replication and High-Availability Data Replication only.'
  },
  ENCRYPT_HDR: {
    type: 'BOOLEAN',
    description: 'Enable or disable HDR encryption.'
  },
  ENCRYPT_MAC: {
    description: 'Controls the level of message authentication code (MAC) generation. This configuration parameter is used only for Enterprise Replication and High-Availability Data Replication.'
  },
  ENCRYPT_MACFILE: {
    description: 'Specifies a list of the full path names of MAC key files.'
  },
  ENCRYPT_SMX: {
    description: 'Sets the level of encryption for high-availability configurations on secondary servers. 0 = Do not encrypt. 1 = Encrypt where possible. Encrypt SMX transactions when the database server being connected to also supports encryption. 2 = Always encrypt. Only connections to encrypted database servers are allowed.'
  },
  ENCRYPT_SWITCH: {
    description: 'Defines the frequency at which ciphers or secret keys are renegotiated. This configuration parameter is used only for Enterprise Replication and High-Availability Data Replication.'
  },
  EXPLAIN_STAT: {
    type: 'BOOLEAN',
    description: 'Enables or disables the inclusion of a Query Statistics section in the explain output file.'
  },
  EXTSHMADD: {
    description: 'Specifies the size of virtual-extension segments that are added when a user-defined routine or a DataBlade routine is run in a user-defined virtual processor.'
  },
  EXT_DIRECTIVES: {
    description: 'Enables or disables the use of external query optimizer directives. 0 = Off. The directive cannot be enabled even if IFX_EXTDIRECTIVES is on. 1 = On. The directive can be enabled for a session if IFX_EXTDIRECTIVES is on. 2 = On. The directive can be used even if IFX_EXTDIRECTIVES is not set.'
  },
  FAILOVER_CALLBACK: {
    description: 'Specifies the script executed by the database server when a database server transitions from a secondary server to a primary or standard server.'
  },
  FAILOVER_TX_TIMEOUT: {
    type: 'INTEGER', min: 0, max: 2147483647,
    description: 'In high-availability cluster environments, indicates the maximum number of seconds after failover that the server waits before rolling back transactions. This can enable transactions to be completed.'
  },
  FASTPOLL: {
    type: 'BOOLEAN',
    description: 'Enables or disables fast polling of your network.'
  },
  FILLFACTOR: {
    type: 'INTEGER', min: 1, max: 100,
    description: 'Specifies the degree of index-page fullness from 1-100. A low value provides room for growth in the index. A high value compacts the index. If an index is full (100 percent), any new inserts result in splitting nodes.'
  },
  FULL_DISK_INIT: {
    description: 'Prevents an accidental disk reinitialization of an existing database server instance. Specifies whether or not the disk initialization command (oninit -i) can run on your Informix instance when a page zero exists at the root path location, which is at the first page of the first chunk location.'
  },
  GSKIT_VERSION: {
    description: 'Specifies the major version of Global Security Kit (GSKit) that the database server uses for encryption and SSL communication.'
  },
  GRIDCOPY_DIR: {
    type: 'STRING',
    description: 'Specifies the default directory used by the ifx_grid_copy procedure.'
  },
  HA_ALIAS: {
    type: 'STRING',
    description: 'When a secondary server connects to a primary server, specifies the name of a network alias to use if a failover occurs.'
  },
  HA_FOC_ORDER: {
    type: 'STRING',
    description: 'Use this configuration parameter to define a single connection-management failover rule for a high-availability cluster of servers.'
  },
  HDR_TXN_SCOPE: {
    type: 'STRING', values: ['FULL_SYNC', 'NEAR_SYNC', 'ASYNC'],
    description: 'The HDR_TXN_SCOPE configuration parameter is used with the DRINTERVAL configuration parameter to specify the synchronization mode for HDR replication in a high-availability cluster.'
  },
  HETERO_COMMIT: {
    type: 'BOOLEAN',
    description: 'Controls whether the database server participates in heterogeneous commit transactions.'
  },
  IFX_EXTEND_ROLE: {
    type: 'BOOLEAN',
    description: 'Enables the database server administrator (DBSA), by default user informix, to implement security measures that establish which users can register DataBlade&reg; modules or user-defined routines (UDRs); 1 or On (default) = Enables the EXTEND role so that administrators can grant privileges to a user to create or drop a UDR that has the EXTERNAL clause; 0 or Off = Disables the EXTEND role so that any user can register an external routine.'
  },
  IFX_FOLDVIEW: {
    type: 'BOOLEAN',
    description: 'Enables or disables view folding; 0 or Off (default) = Disables view folding, 1 or On = Enables view folding.'
  },
  IFX_XA_UNIQUEXID_IN_DATABASE: {
    type: 'BOOLEAN',
    description: 'Enables or disables the transaction manager to use the same XID to represent global transactions on different databases in the same database server instance. '
  },
  IFXGUARD: {
    type: 'STRING',
    description: 'Enables auditing with Security Guardium and sets the actions of the database server if the  Security Guardium server does not respond in the timeout period.'
  },
  INFORMIXCONRETRY: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the maximum number of connection attempts that can be made to each database server after the initial connection attempt fails. These attempts are made within the time limit that the INFORMIXCONTIME configuration parameter specifies.'
  },
  INFORMIXCONTIME: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the number of seconds that the CONNECT statement attempts to establish a connection to a database server.'
  },
  ISM_DATA_POOL: {
    description: 'Specifies where the Informix Storage Manager stores backed-up data.'
  },
  ISM_LOG_POOL: {
    description: 'Specifies where the Informix Storage Manager stores backed-up logical logs.'
  },
  JVMTHREAD: {
    description: 'Thread package (green or native) to use for the JVM.'
  },
  JVPARGS: {
    description: 'Configures the Java VM.'
  },
  JVPCLASSPATH: {
    description: 'Configures the Java classpath.'
  },
  JVPDEBUG: {
    description: 'When set to 1, writes tracing messages to the JVPLOG file.'
  },
  JVPHOME: {
    description: 'Specifies the directory where the classes for the Informix JDBC Driver are installed.'
  },
  JVPJAVAHOME: {
    description: 'Specifies the directory where the JRE for the database server is installed'
  },
  JVPJAVALIB: {
    description: 'Specifies the path from $JVPJAVAHOME to the location of the JVM libraries.'
  },
  JVPJAVAVM: {
    description: 'Lists the JVM libraries that the database server loads. The names in this list exclude the lib prefix and .so or .dll suffix.'
  },
  JVPLOGFILE: {
    description: 'Specifies the path to the Java VP log file.'
  },
  JVPPROPFILE: {
    description: 'Specifies the path to the Java VP properties file, if any.'
  },
  LICENSE_SERVER: {
    type: 'STRING',
    description: 'Specifies a URL to FlexNet License Server used to obtain the necessary licenses to run Informix.'
  },
  LIMITNUMSESSIONS: {
    type: 'STRING',
    description: 'Defines the maximum number of sessions to connect to the Informix database server, and whether the database server should print warning messages to the online.log file when the number of sessions approaches the maximum number.'
  },
  LISTEN_TIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the number of seconds the server waits for a connection.'
  },
  LOCKS: {
    type: 'INTEGER',
    description: 'Specifies the initial size of the lock table.'
  },
  LOGBUFF: {
    type: 'INTEGER',
    description: 'Specifies the size in kilobytes for the three logical-log buffers in shared memory.'
  },
  LOGBUF_INTVL: {
    type: 'INTEGER', min: 0,
    description: 'Enables the automatic flushing of the log buffer based time interval. When set to a value greater than or equal to 10, this parameter specifies the time interval in seconds between log buffer flushes. When set to zero, this behavior is disabled and the log buffer flushes on a session exit.'
  },
  LOGFILES: {
    type: 'INTEGER',
    description: 'Specifies the number of logical-log files that the database server creates during disk initialization.'
  },
  LOG_INDEX_BUILDS: {
    type: 'BOOLEAN',
    description: 'Enables or disables index page logging. Required for RSS. Optional for HDR and SDS.'
  },
  LOG_STAGING_DIR: {
    type: 'STRING',
    description: 'Specifies the directory where the log files from the primary server are stored when delayed application of log files on RS secondary servers is enabled.'
  },
  LOGSIZE: {
    type: 'INTEGER', min: 200,
    description: 'Specifies, in kilobytes, the size that is used when logical-log files are created. It does not change the size of existing logical-log files. The total logical-log size is LOGSIZE * LOGFILES.'
  },
  LOW_MEMORY_MGR: {
    type: 'BOOLEAN',
    description: 'Enables automatic low memory management, which you can use to change the default behavior of a primary or standard server when it reaches its memory limit.'
  },
  LOW_MEMORY_RESERVE: {
    type: 'INTEGER', min: 0,
    description: 'Reserves a specific amount of memory, in kilobytes, for use when critical activities (such as rollback activities) are needed and the server has limited free memory.'
  },
  LTAPEBLK: {
    type: 'INTEGER',
    description: 'When using ontape, specifies the block size of the device to which the logical logs are backed up. LTAPEBLK also specifies the block size for the device to which data is loaded or unloaded when using the -l option of onload or onunload. For onload or onunload, specify a different block size at the command line.'
  },
  LTAPEDEV: {
    type: 'STRING',
    description: 'When using ontape, specifies the device or directory file system to which the logical logs are backed up. It also specifies the device to which data is loaded or unloaded when using the -l option of onload or onunload.'
  },
  LTAPESIZE: {
    type: 'INTEGER', min: 0,
    description: 'For ontape, specifies the maximum tape size of the device to which the logical logs are backed up. LTAPESIZE also specifies the maximum tape size of the device to which data is loaded or unloaded when using the -l option of onload or onunload. When using onload or onunload, specify a different tape size on the command line. To use the full capacity of a tape, set LTAPESIZE to 0.'
  },
  LTXHWM: {
    type: 'INTEGER', min: 0, max: 100,
    description: 'Specifies the long-transaction, exclusive-access, high-watermark. A transaction is long if it is not committed or rolled back when it reaches the long-transaction high-watermark. When the logical-log space reaches the value of the LTXEHWM threshold, the long transaction currently being rolled back is given exclusive access to the logical log.'
  },
  LTXEHWM: {
    type: 'INTEGER', min: 0, max: 100,
    description: 'Specifies the long-transaction high-watermark. The long-transaction high-watermark is the percentage of available log space that, when filled, triggers the database server to check for a long transaction.'
  },
  MAX_FILL_DATA_PAGES: {
    type: 'BOOLEAN',
    description: 'Enables or disables ability to add more rows to be inserted per page in tables that have variable-length rows; 0 = Disabled, 1 = Enabled.'
  },
  MAX_INCOMPLETE_CONNECTIONS: {
    type: 'INTEGER', min: 0,
    description: 'pecifies the maximum number of incomplete connections in a session. After this number is reached, an error message is written in the online message log stating that the server might be under a Denial of Service attack. Default is 1024.'
  },
  MAX_PDQPRIORITY: {
    type: 'INTEGER', min: 1, max: 100,
    description: 'Limits the PDQ resources that the database server can allocate to any one DSS query.'
  },
  MIRROR: {
    type: 'BOOLEAN',
    description: 'Enables or disables mirroring for the database server.'
  },
  MIRROROFFSET: {
    description: 'Specifies the offset into the disk partition or into the device to reach the chunk that serves as the mirror for the initial chunk of the root dbspace.'
  },
  MIRRORPATH: {
    description: 'Specifies the full path name of the mirrored chunk for the initial chunk of the root dbspace.'
  },
  MQCHLLIB: {
    description: 'For MQ messaging, specifies the path to the directory containing the client channel definition table.'
  },
  MQCHLTAB: {
    description: 'For MQ messaging, specifies the name of the client channel definition table.'
  },
  MQSERVER: {
    description: 'For MQ messaging, defines a channels, specifies the location of WebSphere MQ, and specifies the communication method to be used.'
  },
  MSG_DATE: {
    type: 'BOOLEAN',
    description: 'When enabled, adds a date to the beginning of each message in the online log.'
  },
  MSGPATH: {
    type: 'STRING',
    description: 'Specifies the full pathname of the message-log file. The database server writes status messages and diagnostic messages to this file during operation.'
  },
  MULTIPROCESSOR: {
    type: 'BOOLEAN',
    description: 'Specifies whether the database server performs locking in a manner that is suitable for a single-processor computer or a multiprocessor computer.'
  },
  NET_IO_TIMEOUT_ALARM: {
    type: 'INTEGER',
    description: 'Controls whether to be notified if network write operations have been blocked for 30 minutes or more.'
  },
  NETTYPE: {
    description: 'Tunes the network protocols that are defined in the sqlhosts information.'
  },
  NS_CACHE: {
    type: 'STRING',
    description: 'Specifies the maximum retention time for entries in the Informix® name service caches: the host name/IP address cache, the service cache, the user cache, and the group cache.'
  },
  NUMFDSERVERS: {
    description: 'Specifies the maximum number of poll threads to handle network connections migrating between Informix virtual processors (VPs). '
  },
  OFF_RECVRY_THREADS: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the number of recovery threads used in logical recovery when the database server is offline (during a cold restore). This number of threads is also used to roll forward logical-log records in fast recovery.'
  },
  ON_RECVRY_THREADS: {
    description: 'Specifies the maximum number of recovery threads that the database server uses for logical recovery when the database server is online (during a warm restore).'
  },
  ONDBSPACEDOWN: {
    type: 'INTEGER', min: 0, max: 2,
    description: 'Defines the action that the database server takes when any disabling event occurs on a primary chunk within a noncritical dbspace. 0 = The database server marks the dbspace as offline and continues. 1 = The database server aborts. 2 = The database server writes the status of the chunk to the logs and waits for user input.'
  },
  ONLIDX_MAXMEM: {
    type: 'INTEGER', min: 16, max: 4294967295,
    description: 'Limits the amount of memory allocated to a single preimage pool and a single updator log pool. The preimage and updator log pools, pimage_partnum and ulog_partnum, are shared memory pools that are created when a CREATE INDEX ONLINE statement is executed. The pools are freed when the execution of the statement is completed.'
  },
  OPTCOMPIND: {
    type: 'INTEGER', min: 0, max: 2,
    description: 'Helps the optimizer choose an appropriate query plan for the application. 0 = When appropriate indexes exist for each ordered pair of tables, the optimizer chooses index scans, over table scans. This value ensures compatibility with previous versions of the database server. 1 = The optimizer uses costs to determine an execution path if the isolation level is not Repeatable Read. This setting is recommended for optimal performance. 2 = The optimizer uses cost to determine an execution path for any isolation level. Index scans are not given preference over table scans. This value is the default if the variable is not set.'
  },
  OPT_GOAL: {
    description: 'Specifies an optimization goal for queries. 0 = sets the optimization goal to FIRST_ROWS. -1 = set the optimization goal to ALL_ROWS.'
  },
  PAM_STACKSIZE: {
    description: 'Specifies the stack size for PAM modules.'
  },
  PC_HASHSIZE: {
    description: 'Specifies the number of hash buckets in the caches that the database server uses. PC_HASHSIZE applies to UDR cache only.'
  },
  PC_POOLSIZE: {
    type: 'INTEGER', min: 127,
    description: 'Specifies the maximum number of user-defined routines that are stored in the UDR cache.'
  },
  PHYSBUFF: {
    description: 'Specifies the size in kilobytes of the two physical-log buffers in shared memory.'
  },
  PHYSFILE: {
    description: 'Specifies the size of the physical log file when you first initialize the disk space and bring the database server online.'
  },
  PLCY_HASHSIZE: {
    description: 'Specifies the number of hash buckets in the security policy information cache.'
  },
  PLCY_POOLSIZE: {
    type: 'INTEGER', min: 127,
    description: 'Specifies the maximum number of entries in each hash bucket of the security policy information cache.'
  },
  PLOG_OVERFLOW_PATH: {
    description: 'Specifies the location of the file that is used during fast recovery if the physical log file overflows.'
  },
  PN_STAGEBLOB_THRESHOLD: {
    type: 'INTEGER', min: 0, max: 1000000,
    description: 'Reserves space for BYTE and TEXT data in round-robin fragments.'
  },
  PRELOAD_DLL_FILE: {
    description: 'Specifies the path name for a shared library file that is preloaded when the database server is started.'
  },
  PSM_ACT_LOG: {
    type: 'STRING',
    description: 'Specifies the location of the Informix Primary Storage Manager activity log if you do not want the log information included in the ON-Bar activity log.'
  },
  PSM_CATALOG_PATH: {
    type: 'STRING',
    description: 'Specifies the location of the Informix Primary Storage Manager catalog tables for your environment, for example, for a single session.'
  },
  PSM_DBS_POOL: {
    type: 'STRING',
    description: 'Sets the name of the pool in which the Informix Primary Storage Manager places backup and restore dbspace data.'
  },
  PSM_DEBUG: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the amount of debugging information that prints in the Informix® Primary Storage Manager debug log if you want to use a debug level that is different from the one used by ON-Bar.'
  },
  PSM_DEBUG_LOG: {
    type: 'STRING',
    description: 'Specifies the location of the debug log to which the Informix Primary Storage Manager writes debugging messages if you do not want the log information included in the ON-Bar debug log.'
  },
  PSM_LOG_POOL: {
    type: 'STRING',
    description: 'Sets the name of the pool in which the Informix Primary Storage Manager places backup and restore log data.'
  },
  RAS_LLOG_SPEED: {
    description: 'Reserved for technical support.'
  },
  RAS_PLOG_SPEED: {
    description: 'Reserved for technical support.'
  },
  QSTATS: {
    type: 'BOOLEAN',
    description: 'Specifies the ability of onstat -g qst to print queue statistics. 0 = Disable queue statistics. 1 = Enable queue statistics.'
  },
  RA_PAGES: {
    type: 'INTEGER',
    description: 'If you disable the AUTO_READAHEAD configuration parameter, use the RA_PAGES configuration parameter to define the number of disk pages to attempt to read ahead. When the AUTO_READAHEAD configuration parameter is enabled and read ahead occurs automatically, the server ignores information specified in the RA_PAGES configuration parameter.'
  },
  REMOTE_SERVER_CFG: {
    type: 'STRING',
    description: 'Specifies the name of a file that lists the remote hosts that are trusted by the computer on which the database server resides.'
  },
  REMOTE_USERS_CFG: {
    type: 'STRING',
    description: 'Specifies the name of a file that lists names of trusted users that exist on remote hosts.'
  },
  RESIDENT: {
    type: 'INTEGER', min: -1, max: 99,
    description: 'Specifies whether resident and virtual segments of shared memory remain resident in operating-system physical memory. 0 = off. 1 = lock the resident segment only. n = lock the resident segment and the next n-1 virtual segments. -1 = lock all resident and virtual segments.'
  },
  RESTARTABLE_RESTORE: {
    type: 'STRING', values: ['ON', 'OFF'],
    description: 'Controls whether the database server performs restartable restores.'
  },
  RESTORE_FILTER: {
    description: 'Specifies the path name of a filter program, and any options. This filter transforms data that was transformed during backup to its original format prior to a restore.'
  },
  RESTORE_POINT_DIR: {
    description: 'Specifies path name of the directory where restore point files will be placed during a failed upgrade to a new version of the server. Informix will store restore point files in a subdirectory of the specified directory, with the server number as the subdirectory name, only if the CONVERSION_GUARD configuration parameter is enabled.'
  },
  ROOTNAME: {
    description: 'Specifies the name of the root dbspace for this database server.'
  },
  ROOTOFFSET: {
    description: 'Specifies the offset into an allocation of disk space (file, disk partition, or device) at which the initial chunk of the root dbspace begins.'
  },
  ROOTPATH: {
    description: 'Specifies the full path name, including the device or file name, of the initial chunk of the root dbspace.'
  },
  ROOTSIZE: {
    description: 'Specifies the size in kilobytes of the initial chunk of the root dbspace.'
  },
  RSS_FLOW_CONTROL: {
    type: 'STRING',
    description: 'Specifies when flow control occurs in a high-availability cluster that contains at least one remote standalone (RS) secondary MutationObserver.'
  },
  RSS_NONBLOCKING_CKPT: {
    type: 'BOOLEAN',
    description: 'Enables non-blocking checkpoints at RS secondary server.'
  },
  RTO_SERVER_RESTART: {
    type: 'INTEGER', min: 0, max: 1800,
    description: 'Enables you to use recovery time objective (RTO) standards to set the amount of time, in seconds, that Dynamic Server has to recover from a problem after you restart Dynamic Server and bring the server into online or quiescent mode.'
  },
  S6_USE_REMOTE_SERVER_CFG: {
    type: 'BOOLEAN',
    description: 'Used with the REMOTE_SERVER_CFG configuration parameter, this parameter specifies which file is used to authenticate secure server connections in a untrusted network environment. 1 = The servers listed in the file specified by the REMOTE_SERVER_CFG configuration parameter are trusted connections. 2 = The $INFORMIXDIR/etc/host.equiv file is used to authenticate servers connecting through a secure port as defined by setting s=6 in the sqlhost information.'
  },
  SBSPACENAME: {
    type: 'STRING',
    description: 'Specifies the name of the default sbspace. If your database tables include smart-large-object columns that do not explicitly specify a storage space, that data is stored in the sbspace that SBSPACENAME specifies.'
  },
  SBSPACETEMP: {
    type: 'STRING',
    description: 'Specifies the name of the default temporary sbspace for storing temporary smart large objects without metadata or user-data logging.'
  },
  SB_CHECK_FOR_TEMP: {
    description: 'Prevents the copying of a temporary smart large object into a permanent table. 0 = Permit the copying of temporary smart large objects into permanent tables. 1 = Prevent the copying of temporary smart large objects into permanent tables.'
  },
  SDS_ALTERNATE: {
    description: 'Defines an alternate means of communication between the primary server and SD secondary servers in a high-availability cluster.'
  },
  SDS_ENABLE: {
    type: 'BOOLEAN',
    description: 'Enables SD secondary server functionality. You must set SDS_ENABLE to 1 (enable) on both the primary server and the SD secondary server to enable SD secondary server functionality. 0 = disable SD secondary server functionality. 1 = enable SD secondary server functionality.'
  },
  SDS_FLOW_CONTROL: {
    type: 'STRING',
    description: 'Specifies when flow control occurs in a high-availability cluster that contains at least one shared-disk (SD) secondary server.'
  },
  SDS_LOGCHECK: {
    description: 'Sets the number of seconds to delay the secondary server from taking over the role of the primary server. If the secondary server detects that the primary server is generating log records during the delay period, then the failover is prevented. The delay can prevent an unnecessary failover if network communication between the primary and secondary servers is temporarily unavailable.'
  },
  SDS_PAGING: {
    type: 'STRING',
    description: 'Specifies the location of two files that act as buffer paging files. If SDS_PAGING is not set, the SD secondary server may fail to start.'
  },
  SDS_TEMPDBS: {
    type: 'STRING',
    description: 'Specifies information that the SD secondary server uses to dynamically create temporary dbspaces.'
  },
  SDS_TIMEOUT: {
    type: 'INTEGER', min: 2, max: 2147483647,
    description: 'Specifies the amount of time in seconds that the primary server will wait for a log position acknowledgement to be sent from the SD secondary server.'
  },
  SEC_APPLY_POLLTIME: {
    type: 'INTEGER', min: 0, max: 5000,
    description: 'Controls how long, in micro seconds, log replay thread should poll for new work before yielding. Use this parameter to reduce thread context switch overhead while replaying log records.'
  },
  SEC_DR_BUFS: {
    type: 'INTEGER', min: 12, max: 128,
    description: 'Controls the number of replication buffers to be used for replicating log records to secondary server.'
  },
  SEC_LOGREC_MAXBUFS: {
    type: 'INTEGER', min: 5, max: 2000,
    description: 'Controlx the number of log buffers to be used for replaying log records at secondary server. Each log buffer is of size 16KB.'
  },
  SECURITY_LOCALCONNECTION: {
    description: 'Verifies security on local connections by verifying that the ID of the local user who is running a program is the same ID of the user who is trying to access the database.'
  },
  SESSION_LIMIT_LOCKS: {
    type: 'INTEGER', min: 500, max: 2147483647,
    description: 'Specifies the maximum number of locks available in a session. This limit does not apply to a user who holds administrative privileges, such as user informix or a DBSA user.'
  },
  SESSION_LIMIT_LOGSPACE: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of log space that a session can use for individual transactions. This limit does not apply to a user who holds administrative privileges, such as user informix or a DBSA user.'
  },
  SESSION_LIMIT_MEMORY: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of memory that a session can allocate. This limit does not apply to a user who holds administrative privileges, such as user informix or a DBSA user.'
  },
  SESSION_LIMIT_TEMPSPACE: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of temporary table space that a session can allocate. This limit does not apply to a user who holds administrative privileges, such as user informix or a DBSA user.'
  },
  SESSION_LIMIT_TXN_TIME: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of time that a transaction can run in a session. This limit does not apply to a user who holds administrative privileges, such as user informix or a DBSA user.'
  },
  SEQ_CACHE_SIZE: {
    description: 'Specifies the maximum number of sequence objects that are cached in memory.'
  },
  SERVERNUM: {
    description: 'Specifies a relative location in shared memory. The value that you choose must be unique for each database server on your local computer.'
  },
  SHARD_ID: {
    type: 'INTEGER', min: 0, max: 65535,
    description: 'Sets the unique ID for a shard server in a shard cluster.'
  },
  SHARD_MEM: {
    type: 'INTEGER', min: 0, max: 2,
    description: 'Specifies how to allocate shared memory for sharded queries on a shard server.'
  },
  SHMADD: {
    type: 'INTEGER',
    description: 'Specifies the size of a segment that is dynamically added to the virtual portion of shared memory.'
  },
  SHMBASE: {
    description: 'Specifies the base address where shared memory is attached to the memory space of a virtual processor.'
  },
  SHMNOACCESS: {
    description: 'Specifies a virtual memory address range to not use to attach shared memory.'
  },
  SHMTOTAL: {
    description: 'Specifies the total amount of shared memory (resident, virtual, communications, and virtual extension portions) to be used by the database server for all memory allocations.'
  },
  SHMVIRTSIZE: {
    description: 'Specifies the initial size of a virtual shared-memory segment.'
  },
  SHMVIRT_ALLOCSEG: {
    description: 'Specifies a threshold at which Informix should allocate a new shared memory segment and the level of the event alarm activated if the server cannot allocate the new memory segment.'
  },
  SINGLE_CPU_VP: {
    description: 'Specifies whether or not the database server is running with only one CPU virtual processor.'
  },
  SMX_COMPRESS: {
    type: 'INTEGER', min: -1, max: 9,
    description: 'Specifies the level of compression that the database server uses before sending data from the source database server to the target database server.'
  },
  SMX_NUMPIPES: {
    description: 'Sets the number of pipes for server multiplexer group (SMX) connections.'
  },
  SMX_PING_INTERVAL: {
    type: 'INTEGER', min: 0, max: 60,
    description: 'Specifies the number of seconds in a timeout interval, where a secondary server waits for activity from the primary server in a Server Multiplexer Group (SMX) connection.'
  },
  SMX_PING_RETRY: {
    type: 'INTEGER', min: 1,
    description: 'Specifies the maximum number of times that a secondary server repeats the timeout interval that is specified by the SMX_PING_INTERVAL configuration parameter if a response from the primary server is not received. If the maximum number is reached without a response, the secondary server prints an error message in the online.log and closes the Server Multiplexer Group (SMX) connection.'
  },
  SP_AUTOEXPAND: {
    type: 'BOOLEAN',
    description: 'Enables or disables the automatic creation or extension of chunks in a storage space.'
  },
  SP_THRESHOLD: {
    type: 'INTEGER', min: 0, max: 4294967294,
    description: 'Defines the minimum amount of space in the storage pool required for the database server to automatically expand a space. A value of 50 or below, indicates a percentage. A value of 1000 or higher, indicates kilobytes.'
  },
  SP_WAITTIME: {
    type: 'INTEGER', min: 0, max: 2147483647,
    description: 'Specifies the maximum number of seconds that a thread waits for a dbspace, temporary dbspace, sbspace, temporary sbspace, or blobspace to expand before returning an out-of-space error.'
  },
  SQLTRACE: {
    description: 'Controls the startup environment of SQL tracing.'
  },
  SQL_LOGICAL_CHAR: {
    type: 'STRING',
    description: 'Enables or disables expanding the size specifications in declarations of built-in character data types.'
  },
  SSL_KEYSTORE_LABEL: {
    description: 'Specifies the label of the server digital certificate used in the keystore database, a protected database that stores SSL keys and digital certificates.'
  },
  STACKSIZE: {
    type: 'INTEGER', min: 32,
    description: 'Specifies the stack size for the database server user threads.'
  },
  STATCHANGE: {
    type: 'INTEGER', min: 0, max: 100,
    description: 'Specifies the minimum percentage of change required in a table or fragment for the distribution statistics to qualify for an update when the automatic mode for UPDATE STATISTICS operations is enabled.'
  },
  STMT_CACHE: {
    type: 'INTEGER', min: 0, max: 2,
    description: 'Determines whether the database server uses the SQL statement cache. 0 = SQL statement cache not used. 1 = SQL statement cache enabled, but user sessions do not use the cache, unless they set the environment variable STMT_CACHE to 1 or execute the SQL statement SET STATEMENT CACHE ON for their session. 2 = SQL statement cache turned on. All statements are cached.]'
  },
  STMT_CACHE_HITS: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the number of hits (references) to a statement before it is fully inserted in the SQL statement cache. 0 = Fully inserts all qualified statements in the SQL statement cache. n>0 = Statements are given key-only entries until number of statement hits is reached and all fully qualified statements are inserted into the cache. Set hits to 1 or more to exclude ad hoc queries from entering the cache.'
  },
  STMT_CACHE_NOLIMIT: {
    type: 'BOOLEAN',
    description: 'Controls whether to insert qualified statements into the SQL statement cache. 0 = Prevents statements from being inserted in the cache to avoid allocating a large amount of memory for the cache. 1 = Always insert statements in the SQL statement cache regardless of the cache size.'
  },
  STMT_CACHE_NUMPOOL: {
    description: 'Specifies the number of memory pools for the SQL statement cache.'
  },
  STMT_CACHE_SIZE: {
    description: 'Specifies the size of the SQL statement caches in kilobytes.'
  },
  STOP_APPLY: {
    type: 'STRING',
    description: 'Stops an RS secondary server from applying log files received from the primary server.'
  },
  STORAGE_FULL_ALARM: {
    description: 'Configures the frequency and severity of messages and alarms when storage spaces become full.'
  },
  SYSALARMPROGRAM: {
    type: 'STRING',
    description: 'Specifies the full pathname of the evidence.sh script. The database server executes evidence.sh when a database server failure occurs. Technical Support uses the output from the evidence.sh script to diagnose the cause of a database server failure. On Windows, you must enable command extensions for evidence.bat to successfully complete.'
  },
  SYSSBSPACENAME: {
    type: 'STRING',
    description: 'Specifies the name of the sbspace in which the database server stores statistics that the UPDATE STATISTICS statement collects for certain user-defined data types.'
  },
  TAPEBLK: {
    type: 'INTEGER',
    description: 'Specifies the block size of the device to which ontape writes during a storage-space backup. TAPEBLK also specifies the default block size of the device to which data is loaded or unloaded when you use the onload or onunload utilities.'
  },
  TAPEDEV: {
    type: 'STRING',
    description: 'Specifies the device or directory file system to which ontape backs up storage spaces. TAPEDEV also specifies the default device to which data is loaded or unloaded when you use the onload or onunload utilities.'
  },
  TAPESIZE: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the size of the device to which ontape backs up storage spaces. Also specifies the size of the default device to which data is loaded or unloaded when you use onload or onunload. If you want to use the full physical capacity of a tape, set TAPESIZE to 0.'
  },
  TBLSPACE_STATS: {
    type: 'BOOLEAN',
    description: 'Enables or disables the collection of tblspace statistics.'
  },
  TBLTBLFIRST: {
    description: 'Specifies the first extent size of tblspace tblspace in the root dbspace. Set this parameter if you do not want the database server to automatically manage the extent size.'
  },
  TBLTBLNEXT: {
    type: 'INTEGER',
    description: 'Specifies the next extent size of tblspace tblspace in the root dbspace.'
  },
  TEMPTAB_NOLOG: {
    type: 'BOOLEAN',
    description: 'Disables logging on temporary tables. 0 = Enable logical logging on temporary table operations. 1 = Disable logical logging on temporary table operations'
  },
  TENANT_LIMIT_CONNECTIONS: {
    type: 'INTEGER', min: 0, max: 65536,
    description: 'Specifies the maximum number of connections to a tenant database.'
  },
  TENANT_LIMIT_MEMORY: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of shared memory for all sessions that are connected to the tenant database.'
  },
  TENANT_LIMIT_SPACE: {
    type: 'INTEGER',
    description: 'Specifies the maximum amount of storage space available to a tenant database. Storage space includes all permanent dbspaces, BLOB spaces, and sbspaces.'
  },
  TLS_VERSION: {
    description: 'Specifies the Transport Layer Security (TLS) version that the database server uses for network connections.'
  },
  TXTIMEOUT: {
    type: 'INTEGER', min: 0,
    description: 'Specifies the amount of time that a participant in a two-phase commit waits before it initiates participant recovery. This parameter is used only for distributed queries that involve a remote database server.'
  },
  UNSECURE_ONSTAT: {
    description: 'Removes the database system administrator (DBSA) user access restriction for onstat commands.'
  },
  UPDATABLE_SECONDARY: {
    description: 'Sets the number of connections to establish between the primary and secondary servers. Setting this configuration parameter to a non-zero value enables client applications to perform update, insert, and delete operations on a high-availability secondary server.'
  },
  USELASTCOMMITTED: {
    type: 'STRING', values: ['NONE', 'COMMITTED READ', 'DIRTY READ', 'ALL'],
    description: 'Specifies the isolation level for which the LAST COMMITTED feature of the COMMITTED READ isolation level is implicitly in effect.'
  },
  USERMAPPING: {
    type: 'STRING', values: ['OFF', 'BASIC', 'ADMIN'],
    description: 'Set whether or not the database server accepts connections from mapped users.'
  },
  USEOSTIME: {
    type: 'BOOLEAN',
    description: 'Controls whether the database server uses subsecond precision when obtaining the current time from the operating system.'
  },
  USRC_HASHSIZE: {
    description: 'Specifies the number of hash buckets in the LBAC credential memory cache. This memory cache holds information about the LBAC credentials of users.'
  },
  USRC_POOLSIZE: {
    type: 'INTEGER',
    description: 'Specifies the maximum number of entries in each hash bucket of the user LBAC credential information cache.'
  },
  USTLOW_SAMPLE: {
    type: 'BOOLEAN',
    description: 'Enables sampling during the gathering of statistics for UPDATE STATISTICS LOW operations. For an index with more than 100K leaf pages, the collection of statistics from sampling can increase the speed of UPDATE STATISTICS LOW operations.'
  },
  VPCLASS: {
    description: 'Creates and configures virtual processors.'
  },
  VP_MEMORY_CACHE_KB: {
    type: 'INTEGER', min: 0,
    description: 'Enables the database server to access the private memory blocks of your CPU VP.'
  },
  WSTATS: {
    type: 'BOOLEAN',
    description: 'Enables the onstat -g wst command to print wait statistics for threads within the system.'
  }
};

/*******************************************************************************
 * Licensed Materials - Property of IBM and/or HCL
 *
 * Copyright IBM Corporation. 2015, 2017.
 * Copyright HCL Technologies Ltd. 2017, 2024. All Rights Reserved.
 *******************************************************************************/
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { InformixServer } from '../informixServer';
import { InformixDatabase } from './informix-database';
import { InformixSQLSession, InformixSQLSessionType } from './informix-sql-session';
import { InformixTable, InformixTableDetails, InformixIndex } from './informix-table';
import { BasicIndexDetails } from './create-index/create-index.model';
import { Dblocale } from './create-database/create-database.model';
import { ReferenceKeys } from './create-table/create-table.model';

@Injectable()
export class SchemaService {
  constructor(
    private http: HttpClient
  ) { }

  private queryAutoCommitSubject = new BehaviorSubject<Boolean>(true);
  private lastQueryExecutedSubject = new BehaviorSubject<boolean>(false);
  private rollbackSubject = new BehaviorSubject<boolean>(false);
  private commitSubject = new BehaviorSubject<boolean>(false);
  private onShowQueryHistoryStatus = new BehaviorSubject<Boolean>(null);
  private onSelectedQueryHistorySubject = new BehaviorSubject<string>(null);
  private onResetSqlEditorSubject = new BehaviorSubject<Boolean>(true);

  queryAutoCommit$ = this.queryAutoCommitSubject.asObservable();
  lastQueryExecuted$ = this.lastQueryExecutedSubject.asObservable();
  onShowQueryHistory$ = this.onShowQueryHistoryStatus.asObservable();
  onSelectedQueryHistory$ = this.onSelectedQueryHistorySubject.asObservable();
  onResetSqlEditor$ = this.onResetSqlEditorSubject.asObservable();
  rollback$ = this.rollbackSubject.asObservable();
  commit$ = this.commitSubject.asObservable();

  getDatabases(server: InformixServer): Observable<InformixDatabase[]> {
    return this.http.get(server.getDatabasesUrl()).pipe(
      map((response: any[]) => response.map(v => new InformixDatabase(server, v))));
  }

  getDatabaseNames(server: InformixServer): Observable<string[]> {
    return this.getDatabases(server).pipe(map(databases => databases.map(db => db.name)));
  }

  createSession(database: InformixDatabase, type: InformixSQLSessionType, username?: string,
    password?: string): Observable<InformixSQLSession> {
    const body: any = { type };
    if (username && password) {
      body.username = username;
      body.password = password;
    }
    return this.http.post(database.getSQLSessionsUrl(), body).pipe(
      map(response => new InformixSQLSession(database, response, type, username, password)));
  }

  closeSession(session: InformixSQLSession): Observable<any> {
    return this.http.delete(session.getUrl()).pipe(catchError(() => []));
  }

  updateSession(session: InformixSQLSession): Observable<InformixSQLSession> {
    return this.createSession(session.database, session.type, session.username, session.password).pipe(map(newSession => {
      session.update(newSession);
      return session;
    }));
  }

  getTables(session: InformixSQLSession): Observable<InformixTable[]> {
    return this.wrapSessionRetry(session,
      () => this.http.get(session.getTablesUrl()).pipe(
        map((response: any[]) => response.map(table => new InformixTable(session, table)))));
  }

  getTableDetails(table: InformixTable): Observable<InformixTableDetails> {
    return this.wrapSessionRetry(table.session,
      () => this.http.get(table.getDetailsUrl()).pipe(map(response => new InformixTableDetails(response))));
  }

  saveStatisticsData(table: InformixTable, dataObj: any): Observable<{ ok: boolean }> {
    return this.wrapSessionRetry(table.session, () => this.http.put(
      table.session.getTablesUrl() + '/' + table.name + '/statistics', dataObj));
  }

  runQuery(session: InformixSQLSession, query: string, params?: any[]): Observable<any> {
    const body: any = {
      sql: query,
      ...(params ?? {}),
    };

    return this.wrapSessionRetry(session, () => this.http.post(session.getSQLUrl(), body));
  }

  cancelQuery(session: InformixSQLSession): Observable<any> {
    return this.wrapSessionRetry(session, () => this.http.post(session.getSQLUrl() + '/cancel', {}));
  }

  getMore(session: InformixSQLSession): Observable<any> {
    return this.http.get(session.getSQLUrl() + '/more');
  }

  getMoreData(session: InformixSQLSession, resultSetId: number): Observable<any> {
    return this.http.get(session.getSQLUrl() + '/more/' + resultSetId );
  }

  private wrapSessionRetry(session: InformixSQLSession, request: () => Observable<any>): Observable<any> {
    return request().pipe(catchError(err => {
      if (err instanceof HttpErrorResponse && err.error.err === 'Session not found' && err.status === 404) {
        return this.updateSession(session).pipe(switchMap(() => request()));
      }
      throw err;
    }));
  }

  createIndex(table: InformixTable, dataObj: BasicIndexDetails): Observable<any> {
    return this.wrapSessionRetry(table.session,
      () => this.http.post<any>(table.session.getTablesUrl() + '/' + table.name + '/index', dataObj));
  }

  public getDblocales(server: InformixServer): Observable<Dblocale[]> {
    const url = 'informix/' + server.id + '/locales';
    return this.http.get<Dblocale[]>(url);
  }

  public createDatabase(server: InformixServer, data: any): Observable<{ return_code: number; result_message: string }> {
    const url = 'informix/' + server.id + '/databases';
    return this.http.post<{ return_code: number; result_message: string }>(url, data);
  }

  public dropDatabase(server: InformixServer, database: string): Observable<{ return_code: number; result_message: string }> {
    const url = 'informix/' + server.id + '/databases/' + database;
    return this.http.delete<{ return_code: number; result_message: string }>(url);
  }

  getDatabaseInfo(server: InformixServer, databaseName: string): Observable<any> {
    const url = 'informix/' + server.id + '/databases/' + databaseName;
    return this.http.get<any>(url);
  }

  getDatabaseTabData(session: InformixSQLSession, server: InformixServer, tabName: string): Observable<any> {
    return this.wrapSessionRetry(session, () => this.http.get<any>('informix/' + server.id + '/databases/'
      + session.database.name + '/sql-sessions/' + session.id + '/' + tabName));
  }

  deleteTableConstraints(session: InformixSQLSession, server: InformixServer, tabName: string, body: any): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders(),
      body
    };
    return this.wrapSessionRetry(session, () => this.http.delete<{ ok: boolean }>('informix/' + server.id + '/databases/'
      + session.database.name + '/sql-sessions/' + session.id + '/tables/' + tabName + '/constraints', httpOptions));
  }

  getSqlHistory(session: InformixSQLSession, server: InformixServer){
    return this.wrapSessionRetry(session, () => this.http.get<any>(session.getUrl() + '/sql/history'));
  }

  updateSqlHistory(session: InformixSQLSession, server: InformixServer, body) {
    return this.wrapSessionRetry(session, () => this.http.put<{ ok: boolean}>(session.getUrl() + '/sql/history', body));
  }

  removeSqlHistory(session: InformixSQLSession, server: InformixServer , body){
    const httpOptions = {
      headers: new HttpHeaders(),
      body
    };
    return this.wrapSessionRetry(session, () => this.http.delete<{ ok: boolean}>(session.getUrl() + '/sql/history', httpOptions));
  }

  saveConfiguration(session: InformixSQLSession, server: InformixServer , body) {
    const url = 'informix/' + server.id + '/databases/' + session.database.name + '/sql-sessions/' + session.id + '/configurations';
    return this.wrapSessionRetry(session, () => this.http.put<any>(session.getUrl() + '/configurations', body));

  }

  getDatabasePrivileges(server: InformixServer, db: string): Observable<any> {
    const url = 'informix/' + server.id + '/privileges/' + db + '/privilegedUsers';
    return this.http.get<any>(url);
  }

  getProcedureByID(server: InformixServer, databaseName: string, sessionId: any, tabName: string, procId: number): Observable<any> {
    const url = 'informix/' + server.id + '/databases/' + databaseName + '/sql-sessions/' + sessionId + '/' + tabName + '/' + procId;
    return this.http.get<any>(url);
  }

  indexDuplicateCheck(table: InformixTable, indexName: string): Observable<any> {
    return this.wrapSessionRetry(table.session,
      () => this.http.get(table.session.getUrl() + '/tables/' + table.name + '/index?indexName=' + indexName));
  }

  enableDisableIndex(table: InformixTable, type: string, row: InformixIndex): Observable<{ ok: boolean }> {
    return this.wrapSessionRetry(table.session,
      () => this.http.put(table.session.getUrl() + '/tables/' + table.name + '/index/' + type + '?indexName=' + row.name, null));
  }

  deleteIndexes(table: InformixTable, row: Array<string>): Observable<{ ok: boolean }> {
    const httpOptions = {
      headers: new HttpHeaders(), body: row
    };
    return this.wrapSessionRetry(table.session,
      () => this.http.delete(table.session.getUrl() + '/tables/' + table.name + '/index/drop', httpOptions));
  }

  createTable(session: InformixSQLSession, dataObj: any): Observable<any> {
    return this.wrapSessionRetry(session, () => this.http.post(session.getTablesUrl(), dataObj));
  }

  getNamedRowType(session: InformixSQLSession) {
    return this.wrapSessionRetry(session, () => this.http.get(session.getTablesUrl() + '/namedrowtype'));
  }

  getReferenceKeys(session: InformixSQLSession) {
    return this.wrapSessionRetry(session, () => this.http.get(session.getTablesUrl() + '/referenceKeys')
      .pipe(map(response => new ReferenceKeys(response))));
  }

  dropTable(session: InformixSQLSession, tableName: string) {
    return this.wrapSessionRetry(session, () => this.http.delete(session.getTablesUrl() + '/' + tableName));
  }

  setAutoCommit(value: Boolean) {
    this.queryAutoCommitSubject.next(value);
  }

  setLastQueryExecuted(value: boolean) {
    this.lastQueryExecutedSubject.next(value);
  }

  setRollackSubject(value: boolean) {
    this.rollbackSubject.next(value);
  }

  // commit uncommitted transaction
  setCommitSubject(value: boolean) {
    this.commitSubject.next(value);
  }

  // hide show query history page on event
  setShowQueryHistroy(isShowQueryHistory) {
    this.onShowQueryHistoryStatus.next(isShowQueryHistory);
  }

  // load selected query to the sql editor
  setSelectedQueryHistory(query) {
    this.onSelectedQueryHistorySubject.next(query);
  }

  // reset SQL editor
  resetSqlEditor(value: Boolean){
    this.onResetSqlEditorSubject.next(value);
  }
}

/*******************************************************************************
 * Licensed Materials - Property of HCL
 *
 * Copyright HCL Technologies Ltd. 2020, 2024. All Rights Reserved
 *******************************************************************************/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InformixConstraint, InformixTable, InformixTableDetails } from '../../informix-table';
import { SchemaService } from '../../schema.service';
import { InformixSQLSession } from '../../informix-sql-session';
import { InformixServer } from '../../../informixServer';
import { NotificationsService } from '../../../../../shared/notifications/notifications.service';

@Component({
  selector: 'app-table-constraints-info',
  templateUrl: './table-constraints-info.component.html'
})
export class TableConstraintsInfoComponent {

  @Input() tableDetails: InformixTableDetails;
  @Input() session: InformixSQLSession;
  @Input() server: InformixServer;
  @Input() table: InformixTable;

  @Output() refreshData = new EventEmitter<any>();
  selectedConstraints: InformixConstraint[] = [];

  constructor(private schemaService: SchemaService, private notificationsService: NotificationsService) { }

  removeMultipleConstraints(){
    const payload = {
      tableConstraint: this.selectedConstraints.map((row: InformixConstraint) => row.name)
    };

    this.schemaService.deleteTableConstraints(this.session, this.server, this.table.name, payload).subscribe((res: any) => {
      if (res.ok) {
        this.notificationsService.pushSuccessNotification('Table constraints are successfully dropped.');
        this.refreshData.emit();
      }
    }, err => {
      this.notificationsService.pushErrorNotification('Error while dropping the table constraints. '+ err.error.err);
    });
  }

  getSelectedConstraints(event: []) {
    this.selectedConstraints = event;
  }
}
